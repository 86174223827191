import React from "react";

function IonEarthSharp(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path
        fill="currentColor"
        d="m267 474l-.8-.13a.85.85 0 0 0 .8.13m181.9-286.22a5.51 5.51 0 0 0-10.67-.63A5.52 5.52 0 0 1 433 191h-15.47a5.5 5.5 0 0 1-2.84-.79l-22.38-13.42a5.5 5.5 0 0 0-2.84-.79h-35.8a5.5 5.5 0 0 0-3.06.93l-44.15 29.43A5.52 5.52 0 0 0 304 211v41.74a5.51 5.51 0 0 0 2.92 4.87l57.89 30.9a5.55 5.55 0 0 1 2.92 4.8l.27 23.49a5.53 5.53 0 0 0 2.85 4.75l23.26 12.87a5.54 5.54 0 0 1 2.85 4.83v48.6a5.52 5.52 0 0 0 9.17 4.14c9.38-8.26 22.83-20.32 24.62-23.08q4.44-6.87 8.33-14.07a207.4 207.4 0 0 0 13.6-31c12.68-36.71 2.66-102.7-3.78-136.06M286.4 302.8l-61.33-46a4 4 0 0 0-2.4-.8h-29.1a3.78 3.78 0 0 1-2.68-1.11l-13.72-13.72a4 4 0 0 0-2.83-1.17h-53.19a3.79 3.79 0 0 1-2.68-6.47l8.42-8.42a3.78 3.78 0 0 1 2.68-1.11h32.37a8 8 0 0 0 7.7-5.83l6.89-24.5a4 4 0 0 1 2-2.47L206 177.06a3.79 3.79 0 0 0 2.05-3.37v-12.5a3.8 3.8 0 0 1 .68-2.17l14.6-21.02a3.75 3.75 0 0 1 1.78-1.38l20.43-7.67a3.79 3.79 0 0 0 2.46-3.55V114a3.8 3.8 0 0 0-1.69-3.16l-20.48-13.62A3.83 3.83 0 0 0 222 97l-27.88 13.94a3.78 3.78 0 0 1-4-.41l-13.22-10.45a3.8 3.8 0 0 1 .1-6l10.74-7.91a3.78 3.78 0 0 0-.09-6.16l-16.73-11.67a3.78 3.78 0 0 0-4-.22c-6.05 3.31-23.8 13.11-30.1 17.52a209.5 209.5 0 0 0-68.16 80c-1.82 3.76-4.07 7.59-4.29 11.72s-3.46 13.35-4.81 17.08a3.78 3.78 0 0 0 .24 3.1l35.69 65.58a3.74 3.74 0 0 0 1.38 1.44l37.55 22.54a3.78 3.78 0 0 1 1.81 2.73l7.52 54.54a3.82 3.82 0 0 0 1.61 2.61l29.3 20.14a4 4 0 0 1 1.65 2.48l15.54 73.8a3.6 3.6 0 0 0 .49 1.22c1.46 2.36 7.28 11 14.3 12.28c-.65.18-1.23.59-1.88.78a48 48 0 0 1 5 1.16c2 .54 4 1 6 1.43c3.13.62 3.44 1.1 4.94-1.68c2-3.72 4.29-5 6-5.46a3.85 3.85 0 0 0 2.89-2.9l10.07-46.68a4 4 0 0 1 1.6-2.42l45-31.9a4 4 0 0 0 1.69-3.27V306a4 4 0 0 0-1.55-3.2"
      ></path>
      <path
        fill="currentColor"
        d="M262 48s-3.65.21-4.39.23q-8.13.24-16.22 1.12A207.5 207.5 0 0 0 184.21 64c2.43 1.68-1.75 3.22-1.75 3.22L189 80h35l24 12l21-12Zm92.23 72.06l16.11-14a4 4 0 0 0-.94-6.65l-18.81-8.73a4 4 0 0 0-5.3 1.9l-7.75 16.21a4 4 0 0 0 1.49 5.11l10.46 6.54a4 4 0 0 0 4.74-.38m75.41 20.61l-5.83-9c-.09-.14-.17-.28-.25-.43c-1.05-2.15-9.74-19.7-17-26.51c-5.45-5.15-7-3.67-7.43-2.53a3.77 3.77 0 0 1-1.19 1.6l-28.84 23.31a4 4 0 0 1-2.51.89h-14.93a4 4 0 0 0-2.83 1.17l-12 12a4 4 0 0 0 0 5.66l12 12a4 4 0 0 0 2.83 1.17h75.17a4 4 0 0 0 4-4.17l-.55-13.15a4 4 0 0 0-.64-2.01"
      ></path>
      <path
        fill="currentColor"
        d="M256 72a184 184 0 1 1-130.1 53.9A182.77 182.77 0 0 1 256 72m0-40C132.3 32 32 132.3 32 256s100.3 224 224 224s224-100.3 224-224S379.7 32 256 32"
      ></path>
    </svg>
  );
}
export default IonEarthSharp;
