import React from "react";
import { withTranslation } from "react-i18next";
import { Row, Spinner } from "reactstrap";
import PlusOutlined from "../../assets/images/icons/PlusOutlined";
import AlertTriangeMono from "../../assets/images/icons/AlertTriangeMono";
import IonEarthSharp from "../../assets/images/icons/IonEarthSharp";

function UrlRessources(props) {
  return (
    <React.Fragment>
      {/* {client_data ? ( */}
      <div
        className="file-ressources"
        style={{
          borderRadius: "15px",
          background: "#FFF",
          boxShadow: "0px 6px 24px 1px rgba(53, 22, 252, 0.10)",
          padding: "41px 80px 60px 65px"
        }}
      >
        <Row className="mb-3">
          <div className="d-flex align-items-center flex-wrap gap-2 mb-1">
            <IonEarthSharp
              width="2rem"
              height="auto"
              style={{ color: "#563bff" }}
            />
            <div className="stepTitle font-primary">
              {props.t("Urls de site web")}
            </div>
          </div>
          <div>{props.t("Importez vos données à partir d'une URL")}</div>
        </Row>

        {false && (
          <>
            <div className="mb-3 d-flex align-items-center">
              <button
                type="button"
                className="view-button"
                style={{
                  backgroundColor: "#563BFF",
                  width: "fit-content"
                }}
                onClick={() => {}}
              >
                {props.t("URL à indexer")}
              </button>
              <div
                className="d-flex ms-3"
                style={{
                  flexDirection: "column"
                }}
              >
                Exemple : https://www.wikipedia.org
              </div>
            </div>
            <div className="mb-3 d-flex">
              <button
                type="button"
                className="view-button"
                style={{
                  backgroundColor: "#563BFF",
                  width: "fit-content"
                }}
                onClick={() => {}}
              >
                {props.t("Enregistrer")}
              </button>
            </div>
          </>
        )}

        <div className="mb-3 d-flex align-items-center justify-content-center gap-2">
          {props.t("Bientôt disponible")}
          <AlertTriangeMono width={"30px"} height={"30px"} className="" />
        </div>
      </div>
      {/*    ) : (
       <div style={{ textAlign: "center", fontSize: "14px" }}>
         <Spinner
           className="me-1"
           style={{ height: "15px", width: "15px" }}
           animation="border"
         />
         {props.t("Chargement") + "..."}
       </div>
     )} */}
    </React.Fragment>
  );
}

export default withTranslation()(UrlRessources);
