import axios from "axios";
import config from "../config";
import {
  Client,
  Account,
  ID,
  Databases,
  Query,
  Functions,
  ExecutionMethod
} from "appwrite";
import ReactGA from "react-ga4";

// export const SMARTAS = {
//   STARTER_WORLD: "price_1Q4cg2B6CkBqvsv3UUwllCnS",
//   STARTER_US: "price_1Q4ctOJcj0cebeM8NqTvnp0r",
//   GROWTH_WORLD: "price_1Q4cgiB6CkBqvsv3JJGN0ngf",
//   GROWTH_US: "price_1Q4cuSJcj0cebeM8uVQThqaU",
//   BUSINESS_WORLD: "price_1Q4chjB6CkBqvsv3tNjlvUL1",
//   BUSINESS_US: "price_1Q4cuyJcj0cebeM8wZ7wEHpP",
//   PRO_WORLD: "price_1Q4ci9B6CkBqvsv361LguvWL",
//   PRO_US: "price_1Q4cvQJcj0cebeM8ADEvG65U",
//   PAUSE_US: "price_1Q8Me8Jcj0cebeM8IQoGZdCD",
//   PAUSE_WORLD: "price_1Q8McuB6CkBqvsv3VebMlrSX",
//   PLAN_NAME: {
//     price_1Q4cg2B6CkBqvsv3UUwllCnS: "MAAIA_PLAN_STARTER",
//     price_1Q4ctOJcj0cebeM8NqTvnp0r: "MAAIA_PLAN_STARTER",
//     price_1Q4cgiB6CkBqvsv3JJGN0ngf: "MAAIA_PLAN_GROWTH",
//     price_1Q4cuSJcj0cebeM8uVQThqaU: "MAAIA_PLAN_GROWTH",
//     price_1Q4chjB6CkBqvsv3tNjlvUL1: "MAAIA_PLAN_BUSINESS",
//     price_1Q4cuyJcj0cebeM8wZ7wEHpP: "MAAIA_PLAN_BUSINESS",
//     price_1Q4ci9B6CkBqvsv361LguvWL: "MAAIA_PLAN_PRO",
//     price_1Q4cvQJcj0cebeM8ADEvG65U: "MAAIA_PLAN_PRO",
//     price_1Q8Me8Jcj0cebeM8IQoGZdCD: "MAAIA_PLAN_PAUSE",
//     price_1Q8McuB6CkBqvsv3VebMlrSX: "MAAIA_PLAN_PAUSE"
//   },
//   CREDITS: {
//     US: {
//       MAAIA_PLAN_STARTER: "price_1Q4cwZJcj0cebeM8P3i8C44W",
//       MAAIA_PLAN_GROWTH: "price_1Q4cxWJcj0cebeM8dzFeCRfY",
//       MAAIA_PLAN_BUSINESS: "price_1Q4cyLJcj0cebeM8YCwCFR6w",
//       MAAIA_PLAN_PRO: "price_1Q4cyxJcj0cebeM8CATC4OJe"
//     },
//     WOLRD: {
//       MAAIA_PLAN_STARTER: "price_1Q4cmvB6CkBqvsv3Wj71ODXy",
//       MAAIA_PLAN_GROWTH: "price_1Q4co6B6CkBqvsv3MjaEdaHv",
//       MAAIA_PLAN_BUSINESS: "price_1Q4cp7B6CkBqvsv36hfIai8y",
//       MAAIA_PLAN_PRO: "price_1Q4cqAB6CkBqvsv356GR8Qqj"
//     }
//   },
//   CREDITS_PACK_PRICE: {
//     MAAIA_PLAN_STARTER: 14,
//     MAAIA_PLAN_GROWTH: 13,
//     MAAIA_PLAN_BUSINESS: 12,
//     MAAIA_PLAN_PRO: 10
//   },
//   MAIL_COST: {
//     MAAIA_PLAN_STARTER: 30,
//     MAAIA_PLAN_GROWTH: 25,
//     MAAIA_PLAN_BUSINESS: 20,
//     MAAIA_PLAN_PRO: 15
//   },
//   LANGUAGE_SUPPORT: {
//     price_1Q4cg2B6CkBqvsv3UUwllCnS: 1,
//     price_1Q4ctOJcj0cebeM8NqTvnp0r: 1,
//     price_1Q4cgiB6CkBqvsv3JJGN0ngf: 3,
//     price_1Q4cuSJcj0cebeM8uVQThqaU: 3,
//     price_1Q4chjB6CkBqvsv3tNjlvUL1: 5,
//     price_1Q4cuyJcj0cebeM8wZ7wEHpP: 5,
//     price_1Q8Me8Jcj0cebeM8IQoGZdCD: 10,
//     price_1Q8McuB6CkBqvsv3VebMlrSX: 10
//   },
//   EMAIL_ACCOUNTS: {
//     price_1Q4cg2B6CkBqvsv3UUwllCnS: 1,
//     price_1Q4ctOJcj0cebeM8NqTvnp0r: 1,
//     price_1Q4cgiB6CkBqvsv3JJGN0ngf: 2,
//     price_1Q4cuSJcj0cebeM8uVQThqaU: 2,
//     price_1Q4chjB6CkBqvsv3tNjlvUL1: 5,
//     price_1Q4cuyJcj0cebeM8wZ7wEHpP: 5,
//     price_1Q8Me8Jcj0cebeM8IQoGZdCD: 10,
//     price_1Q8McuB6CkBqvsv3VebMlrSX: 10
//   }
// };

export function getBrowserLanguage() {
  const language = navigator.language || navigator.userLanguage || "en"; // Default to 'en' if undefined

  return language.slice(0, 2); // Return only the first two characters
}

export const SMARTAS =
  process.env.REACT_APP_MAAIA_ENV === "production"
    ? {
        STARTER_WORLD: "price_1QB0jZB6CkBqvsv32JRP6SO3",
        STARTER_US: "price_1QB0hJJcj0cebeM8cn0MSa0v",
        GROWTH_WORLD: "price_1QB0jWB6CkBqvsv3zHhNQvYV",
        GROWTH_US: "price_1QB0hEJcj0cebeM8bpxEq8PP",
        BUSINESS_WORLD: "price_1QB0jRB6CkBqvsv37JnGVkbK",
        BUSINESS_US: "price_1QB0hAJcj0cebeM8DRw67bBT",
        PRO_WORLD: "price_1QB0jLB6CkBqvsv3VhImB3kG",
        PRO_US: "price_1QB0h7Jcj0cebeM8lSIywlZx",
        PAUSE_US: "price_1QB0gkJcj0cebeM8wXKcQ0Jh",
        PAUSE_WORLD: "price_1QB0ioB6CkBqvsv31aRJdkoB",
        DIRECT_WORLD: "direct_world",
        PLAN_NAME: {
          price_1QB0jZB6CkBqvsv32JRP6SO3: "MAAIA_PLAN_STARTER",
          price_1QB0hJJcj0cebeM8cn0MSa0v: "MAAIA_PLAN_STARTER",
          price_1QB0jWB6CkBqvsv3zHhNQvYV: "MAAIA_PLAN_GROWTH",
          price_1QB0hEJcj0cebeM8bpxEq8PP: "MAAIA_PLAN_GROWTH",
          price_1QB0jRB6CkBqvsv37JnGVkbK: "MAAIA_PLAN_BUSINESS",
          price_1QB0hAJcj0cebeM8DRw67bBT: "MAAIA_PLAN_BUSINESS",
          price_1QB0jLB6CkBqvsv3VhImB3kG: "MAAIA_PLAN_PRO",
          price_1QB0h7Jcj0cebeM8lSIywlZx: "MAAIA_PLAN_PRO",
          price_1QB0gkJcj0cebeM8wXKcQ0Jh: "MAAIA_PLAN_PAUSE",
          price_1QB0ioB6CkBqvsv31aRJdkoB: "MAAIA_PLAN_PAUSE",
          direct_world: "MAAIA_PLAN_DIRECT_WORLD"
        },
        CREDITS: {
          US: {
            MAAIA_PLAN_STARTER: "price_1QB0h1Jcj0cebeM8lSFEso0m",
            MAAIA_PLAN_GROWTH: "price_1QB0gyJcj0cebeM8JKPWvgsn",
            MAAIA_PLAN_BUSINESS: "price_1QB0gvJcj0cebeM88O2pDZe0",
            MAAIA_PLAN_PRO: "price_1QB0gsJcj0cebeM8l2ecU9pt"
          },
          WOLRD: {
            MAAIA_PLAN_STARTER: "price_1QB0k1B6CkBqvsv3DsVv8Sui",
            MAAIA_PLAN_GROWTH: "price_1QB0j0B6CkBqvsv3BIzfH6wj",
            MAAIA_PLAN_BUSINESS: "price_1QB0ixB6CkBqvsv3ZJ6M2EVM",
            MAAIA_PLAN_PRO: "price_1QB0itB6CkBqvsv3tGYytd4E"
          }
        },
        CREDITS_PACK_PRICE: {
          MAAIA_PLAN_STARTER: 28,
          MAAIA_PLAN_GROWTH: 23,
          MAAIA_PLAN_BUSINESS: 18,
          MAAIA_PLAN_PRO: 15
        },
        MAIL_COST: {
          MAAIA_PLAN_STARTER: 30,
          MAAIA_PLAN_GROWTH: 25,
          MAAIA_PLAN_BUSINESS: 20,
          MAAIA_PLAN_PRO: 15
        },
        LANGUAGE_SUPPORT: {
          price_1QB0jZB6CkBqvsv32JRP6SO3: 1,
          price_1QB0hJJcj0cebeM8cn0MSa0v: 1,
          price_1QB0jWB6CkBqvsv3zHhNQvYV: 3,
          price_1QB0hEJcj0cebeM8bpxEq8PP: 3,
          price_1QB0jRB6CkBqvsv37JnGVkbK: 5,
          price_1QB0hAJcj0cebeM8DRw67bBT: 5,
          price_1QB0gkJcj0cebeM8wXKcQ0Jh: 10,
          price_1QB0ioB6CkBqvsv31aRJdkoB: 10,
          direct_world: 10
        },
        EMAIL_ACCOUNTS: {
          price_1QB0jZB6CkBqvsv32JRP6SO3: 1,
          price_1QB0hJJcj0cebeM8cn0MSa0v: 1,
          price_1QB0jWB6CkBqvsv3zHhNQvYV: 2,
          price_1QB0hEJcj0cebeM8bpxEq8PP: 2,
          price_1QB0jRB6CkBqvsv37JnGVkbK: 5,
          price_1QB0hAJcj0cebeM8DRw67bBT: 5,
          price_1QB0gkJcj0cebeM8wXKcQ0Jh: 10,
          price_1QB0ioB6CkBqvsv31aRJdkoB: 10,
          direct_world: 10
        }
      }
    : {
        STARTER_WORLD: "price_1Q4cg2B6CkBqvsv3UUwllCnS",
        STARTER_US: "price_1Q4ctOJcj0cebeM8NqTvnp0r",
        GROWTH_WORLD: "price_1Q4cgiB6CkBqvsv3JJGN0ngf",
        GROWTH_US: "price_1Q4cuSJcj0cebeM8uVQThqaU",
        BUSINESS_WORLD: "price_1Q4chjB6CkBqvsv3tNjlvUL1",
        BUSINESS_US: "price_1Q4cuyJcj0cebeM8wZ7wEHpP",
        PRO_WORLD: "price_1Q4ci9B6CkBqvsv361LguvWL",
        PRO_US: "price_1Q4cvQJcj0cebeM8ADEvG65U",
        PAUSE_US: "price_1Q8Me8Jcj0cebeM8IQoGZdCD",
        PAUSE_WORLD: "price_1Q8McuB6CkBqvsv3VebMlrSX",
        DIRECT_WORLD: "direct_world",
        PLAN_NAME: {
          price_1Q4cg2B6CkBqvsv3UUwllCnS: "MAAIA_PLAN_STARTER",
          price_1Q4ctOJcj0cebeM8NqTvnp0r: "MAAIA_PLAN_STARTER",
          price_1Q4cgiB6CkBqvsv3JJGN0ngf: "MAAIA_PLAN_GROWTH",
          price_1Q4cuSJcj0cebeM8uVQThqaU: "MAAIA_PLAN_GROWTH",
          price_1Q4chjB6CkBqvsv3tNjlvUL1: "MAAIA_PLAN_BUSINESS",
          price_1Q4cuyJcj0cebeM8wZ7wEHpP: "MAAIA_PLAN_BUSINESS",
          price_1Q4ci9B6CkBqvsv361LguvWL: "MAAIA_PLAN_PRO",
          price_1Q4cvQJcj0cebeM8ADEvG65U: "MAAIA_PLAN_PRO",
          price_1Q8Me8Jcj0cebeM8IQoGZdCD: "MAAIA_PLAN_PAUSE",
          price_1Q8McuB6CkBqvsv3VebMlrSX: "MAAIA_PLAN_PAUSE",
          direct_world: "MAAIA_PLAN_DIRECT_WORLD"
        },
        CREDITS: {
          US: {
            MAAIA_PLAN_STARTER: "price_1Q4cwZJcj0cebeM8P3i8C44W",
            MAAIA_PLAN_GROWTH: "price_1Q4cxWJcj0cebeM8dzFeCRfY",
            MAAIA_PLAN_BUSINESS: "price_1Q4cyLJcj0cebeM8YCwCFR6w",
            MAAIA_PLAN_PRO: "price_1Q4cyxJcj0cebeM8CATC4OJe"
          },
          WOLRD: {
            MAAIA_PLAN_STARTER: "price_1Q4cmvB6CkBqvsv3Wj71ODXy",
            MAAIA_PLAN_GROWTH: "price_1Q4co6B6CkBqvsv3MjaEdaHv",
            MAAIA_PLAN_BUSINESS: "price_1Q4cp7B6CkBqvsv36hfIai8y",
            MAAIA_PLAN_PRO: "price_1Q4cqAB6CkBqvsv356GR8Qqj"
          }
        },
        CREDITS_PACK_PRICE: {
          MAAIA_PLAN_STARTER: 28,
          MAAIA_PLAN_GROWTH: 23,
          MAAIA_PLAN_BUSINESS: 18,
          MAAIA_PLAN_PRO: 15
        },
        MAIL_COST: {
          MAAIA_PLAN_STARTER: 30,
          MAAIA_PLAN_GROWTH: 25,
          MAAIA_PLAN_BUSINESS: 20,
          MAAIA_PLAN_PRO: 15
        },
        LANGUAGE_SUPPORT: {
          price_1Q4cg2B6CkBqvsv3UUwllCnS: 1,
          price_1Q4ctOJcj0cebeM8NqTvnp0r: 1,
          price_1Q4cgiB6CkBqvsv3JJGN0ngf: 3,
          price_1Q4cuSJcj0cebeM8uVQThqaU: 3,
          price_1Q4chjB6CkBqvsv3tNjlvUL1: 5,
          price_1Q4cuyJcj0cebeM8wZ7wEHpP: 5,
          price_1Q8Me8Jcj0cebeM8IQoGZdCD: 10,
          price_1Q8McuB6CkBqvsv3VebMlrSX: 10,
          direct_world: 10
        },
        EMAIL_ACCOUNTS: {
          price_1Q4cg2B6CkBqvsv3UUwllCnS: 1,
          price_1Q4ctOJcj0cebeM8NqTvnp0r: 1,
          price_1Q4cgiB6CkBqvsv3JJGN0ngf: 2,
          price_1Q4cuSJcj0cebeM8uVQThqaU: 2,
          price_1Q4chjB6CkBqvsv3tNjlvUL1: 5,
          price_1Q4cuyJcj0cebeM8wZ7wEHpP: 5,
          price_1Q8Me8Jcj0cebeM8IQoGZdCD: 10,
          price_1Q8McuB6CkBqvsv3VebMlrSX: 10,
          direct_world: 10
        }
      };

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getLanguageVersion(failure_reason) {
  let lang = localStorage.getItem("I18N_LANGUAGE") ?? "en";
  if (isJson(failure_reason)) {
    return lang === "fr"
      ? JSON.parse(failure_reason).french_version
      : JSON.parse(failure_reason).english_version;
  } else {
    return failure_reason;
  }
}

export function getDedicatedDate(dedicated) {
  if (isJson(dedicated)) {
    return JSON.parse(dedicated).date;
  } else {
    return dedicated;
  }
}
// default
axios.defaults.baseURL = config.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export const synibox_dashboard_url = "https://app.synibox.io";
// export const synibox_dashboard_url = "http://localhost:5000";
export const calculator_id = "qYpjy2MJq9BKJRdQ1E4z";
export const calculator_id_upgrade = "BxqgWRVDXAldJdLlEjp3";
export const calculator_id_downgrade = "dQWg2NRJK0lVm5PEjOa0";
// export const calculator_id = "zP2VwELmdr2vD0X6YonM"; // localhost
export const stripe_payment_return_url = `${synibox_dashboard_url}/dashboard`;
export const prestashop_payment_return_url = `${synibox_dashboard_url}/prestashop-oauth`;

const appwriteClient = new Client();
appwriteClient
  .setEndpoint(process.env.REACT_APP_DATABASEURL)
  .setProject(process.env.REACT_APP_PROJECTID);
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const getLoggedinUser = async () => {
  const user = localStorage.getItem("authUser");
  // const user = await checkSession();
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

async function getClientData() {
  const client = new Client();
  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const databases = new Databases(client, "default");
  // const user_data = localStorage.getItem("authUser");
  const company_id = localStorage.getItem("companyId");
  if (!company_id) return undefined;

  const promise = databases.getDocument("default", "client_infos", company_id);

  return await promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      return undefined;
    }
  );
}
async function getCompanyInfosPromise() {
  const client = new Client();
  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const databases = new Databases(client, "default");
  // const user_data = localStorage.getItem("authUser");
  const company_id = localStorage.getItem("companyId");
  console.log({ company_id });
  if (!company_id) return undefined;

  return databases.getDocument("default", "client_infos", company_id);
}
export function getMailboxErrorMessage(error) {
  if (error.includes("Name does not resolve")) {
    return "mailbox_error_server_name";
  } else if (
    error.includes("Authentication failed") ||
    error.includes("AUTHENTICATIONFAILED")
  ) {
    return "mailbox_error_auth";
  } else if (error.includes("whois error")) {
    return "whois_error";
  } else {
    return "system_error";
  }
}

export function getWhoisErrorMessage(error) {
  if (error.includes("Aucun résultat n'a été trouvé")) {
    return "Nous n'avons pas pu récupérer vos configurations IMAP/SMTP. Veuillez procéder au remplissage manuel";
  } else {
    return "system_error";
  }
}

async function updateCompanyTokenUsage(
  the_token_usage_in,
  the_token_usage_out
) {
  const client_data = await getClientData();

  if (!client_data) {
    console.log("Couldn't get company infos for token_usage update");
    return undefined;
  }
  const client = new Client();
  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);
  const databases = new Databases(client, "default");

  try {
    const response = await databases.updateDocument(
      "default",
      "client_infos",
      client_data.$id,
      {
        token_usage_in: the_token_usage_in + client_data.token_usage_in,
        token_usage_out: the_token_usage_out + client_data.token_usage_out
      }
    );
    if (response) {
      console.log("Company token_usage updated");
    }
    return response;
  } catch (error) {
    console.log("Failled fetching Company token_usage: ", error);
    return undefined;
  }
}

async function getUser() {
  const client = new Client();

  const account = new Account(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const promise = account.get();

  return promise
    .then(
      function (response) {
        // console.log("user is", response);
        localStorage.setItem("user", JSON.stringify(response));
        return response;
      }
      // function (error) {
      //   console.log('error on session check', error);
      //   return undefined;
      // }
    )
    .catch(function (error) {
      console.log("error on session check", error);
      return undefined;
    });
}
async function getUserPromise() {
  const client = new Client();

  const account = new Account(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return account.get();
}
export async function getEmailSession() {
  const client = new Client();

  const account = new Account(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return account.getSession("current");
}

export async function klaviyoSubscribeEmail(
  company_id,
  email,
  first_name,
  last_name
) {
  console.log("klaviyo start registration for ", email);
  // const options = {
  //   method: "POST",
  //   url: "https://a.klaviyo.com/client/subscriptions?company_id=UQiuyF",
  //   headers: { revision: "2024-10-15", "content-type": "application/vnd.api+json" },
  //   data: JSON.stringify({
  //     data: {
  //       type: "subscription",
  //       attributes: {
  //         profile: {
  //           data: {
  //             type: "profile",
  //             attributes: { email: email, external_id: company_id, first_name: first_name, last_name: last_name }
  //             //  meta: { patch_properties: { append: { newKey: "New Value" }, unappend: { newKey: "New Value" } } }
  //           }
  //         }
  //       },
  //       relationships: { list: { data: { type: "list", id: "UD5PzP" } } }
  //     }
  //   })
  // };

  // axios
  //   .request(options)
  //   .then(function (response) {
  //     console.log("klaviyo success", response);
  //   })
  //   .catch(function (error) {
  //     console.log("klaviyo error", error);
  //   });
}

export async function klaviyoUpdateProfile(company_id, updates) {
  /*
  const client = new Client();

  const functions = new Functions(client);

  client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

  // language: customer_language,  // Langue du client
  // plan: plan,            // Plan choisi
  // plan_activation_date: plan_activation_date, // Date d'achat du plan
  // ai_active: ai_active, // IA encore non activée (boolean)
  // on_pause: on_pause,           // En pause (plan pause) (boolean)
  // unsubscribed: unsubscribed,   // Supprimé son compte/désabonné + date
  // trial_period: trial_period,   // En période d'essai (boolean)
  // category_percentage: category_percentage // % d'emails que Synibox traite

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      company_id,
      updates
    }),
    false,
    "/klaviyo-update-profile",
    ExecutionMethod.PATCH
  );

  console.log({ klaviyoUpdateProfile: response });

  if (response && response.responseBody && response.responseStatusCode === 200) {
    console.log("klaviyoUpdateProfile success", { klaviyoUpdateProfile: response.responseBody });
  } else {
    console.log("Error klaviyoUpdateProfile", { klaviyoUpdateProfile: response });
  }
  */
}

export async function klaviyoSearchProfile(company_id) {
  console.log("klaviyo start get for ", company_id);

  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      company_id
    }),
    false,
    "/klaviyo-search-profile",
    ExecutionMethod.GET
  );

  console.log({ klaviyoSearchProfile: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error klaviyoSearchProfile", {
      klaviyoSearchProfile: response
    });
  }
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

async function createEmailAccount(email, password, name) {
  const client = new Client();

  const account = new Account(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return account.create(ID.unique(), email, password, name);

  /*
  await promise.then(
    function (response) {
      console.log('Success email session created');
      console.log(response);
      localStorage.setItem('authUser', JSON.stringify(response));
      return response;
    },
    function (error) {
      console.log('error on email session create', error);

      let message;
      switch (error.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        case 404:
          message = 'Sorry! the data you are looking for could not be found';
          break;
        default:
          message = error.message || error;
      }

      return message;
    }
  );
  */
}
export async function triggerBillingWebhookFunction(data) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return await functions.createExecution(
    "668fd7d8000df7699237",
    JSON.stringify({ ...data }),
    false
  );
}

async function createStatisticsFunction(company_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return await functions.createExecution(
    "maaia_statistic_function_manager",
    JSON.stringify({ company_id: company_id }),
    true,
    "/create-function",
    ExecutionMethod.POST
  );
}

async function createAiTextImprovementFunctionExecution(data) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "ai_email_reply",
    JSON.stringify(data),
    false,
    "/text-generation",
    ExecutionMethod.POST
  );

  console.log("str_response /text-generation", response);
  try {
    if (
      response &&
      response.responseBody &&
      response.responseBody !== "" &&
      response.responseStatusCode === 200
    ) {
      let str_response = response.responseBody;
      str_response = JSON.parse(str_response);
      return str_response;
    } else {
      return response.responseBody;
    }
  } catch (e) {
    console.log("");

    return undefined;
  }
}

// export async function createAIResponseExecution2(data) {
//   const client = new Client();

//   const functions = new Functions(client);

//   client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

//   const response = await functions.createExecution(
//     "ai_email_reply",
//     {
//       emails: [
//         {
//           email_id: "1@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         },
//         {
//           email_id: "2@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         },
//         {
//           email_id: "3@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         },
//         {
//           email_id: "4@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         },
//         {
//           email_id: "5@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         },
//         {
//           email_id: "6@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         },
//         {
//           email_id: "7@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         },
//         {
//           email_id: "8@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         },
//         {
//           email_id: "9@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         },
//         {
//           email_id: "10@deded.com",
//           // "email_subject": "",
//           email_body:
//             "Hi,\n\nI ordered a some products from your store a few days ago and I've only just realized that I've put in the wrong quantities.\n\nI would like to know if it is still possible to correct the quantities and if you can help me with this.\n\nHere is my order number: #test-1105\n\nThank you,\n\nBillie Baird"
//         }
//         /*
//         ,
//         {
//             "email_id": "sssss@ddd.com",
//             "email_body": "Bonjour,\n\nJuste une salutation\nHilaire"
//         }
//         */
//       ],
//       company_id: "659ba10c41af471c9517",
//       agent_id: "1729681119733"
//     },
//     false,
//     "/email-reply",
//     ExecutionMethod.POST
//   );

//   console.log("str_response /email-reply", response);

//   try {
//     if (response && response.responseBody && response.responseBody !== "" && response.responseStatusCode === 200) {
//       let str_response = response.responseBody;
//       str_response = JSON.parse(str_response);
//       return str_response;
//     } else {
//       return response.responseBody;
//     }
//   } catch (e) {
//     console.log("");

//     return undefined;
//   }
// }

async function createAIResponseExecution(messages, company_id, origin) {
  console.log(
    `Fetching Synibox response...=> ${new Date().toLocaleString("fr", {
      timeZone: "Europe/Paris"
    })}`
  );

  const apiUrl = "https://ai.semecloud.tech/email-reply";
  //  'http://localhost:50000/email-response',
  return axios
    .post(apiUrl, {
      emails: messages,
      company_id,
      turn_id: undefined,
      agent_id: undefined
    })
    .then((response) => {
      const resp = response;
      if (resp && resp.result) {
        return resp;
      }

      // eslint-disable-next-line prefer-destructuring
      const status = response.status;
      const the_error = response.data;
      console.log("Failed to get AI response", response);
      console.log({ code: status, error: the_error });

      return undefined;
    })
    .catch((e) => {
      // eslint-disable-next-line prefer-destructuring
      const status = e.status;
      const the_error = e.data;
      console.log("Failed to get AI response", e);
      console.log({ code: status, error: the_error });

      return undefined;
    });
}

async function updateAgentFunctionExecution(
  theCompanyId,
  agentId,
  theInterval
) {
  console.log({ asss: { theCompanyId, agentId, theInterval } });

  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return await functions.createExecution(
    "ai_agent_manager",
    JSON.stringify({
      company_id: theCompanyId,
      agent_id: agentId,
      interval: theInterval
    }),
    false,
    "/update-function-cron",
    ExecutionMethod.POST
  );
}

async function createAgentFunctionExecution(company_id, agent_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  console.log("createAgentFunctionExecution /create-function");

  return await functions.createExecution(
    "ai_agent_manager",
    JSON.stringify({ company_id, agent_id }),
    false,
    "/create-function",
    ExecutionMethod.POST
  );
}

async function deleteMailerAgentFunction(company_id, agent_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return await functions.createExecution(
    "ai_agent_manager",
    JSON.stringify({ company_id, agent_id }),
    false,
    "/delete-function",
    ExecutionMethod.POST
  );
}

async function createEmployeeFunction(employee) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return functions.createExecution(
    "6697978e00002df0812c",
    JSON.stringify({ employee: employee }),
    false
  );
}

async function createEmailSessionPromise(email, password) {
  const client = new Client();

  const account = new Account(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return account.createEmailPasswordSession(email, password);
}

async function createEmailSession(email, password) {
  const client = new Client();

  const account = new Account(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const promise = account.createEmailPasswordSession(email, password);

  await promise.then(
    function (response) {
      // console.log("Success email session created");
      // console.log(response);
      // localStorage.setItem('authUser', JSON.stringify(response));
      return response;
    },
    function (error) {
      // console.log("error on email session create", error);
      return null;
    }
  );
}

async function updateUserPrefs(thePrefs) {
  const client = new Client();

  const account = new Account(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const promise = account.updatePrefs(thePrefs);

  await promise.then(
    function (response) {
      // console.log("Success user prefs updated");
      // console.log(response);
      return response;
    },
    function (error) {
      //  console.log("error on user prefs update", error);
      return null;
    }
  );
}

async function updateUserPrefsPromise(thePrefs) {
  const client = new Client();

  const account = new Account(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return account.updatePrefs(thePrefs);
}

async function fetchSearchedMails(
  search,
  company_id,
  currentBox,
  currentPage,
  pageSize
) {
  const apiUrl = "https://mailbox.semecloud.tech/search-mails";
  // const apiUrl = "http://localhost:55000/search-mails";
  return axios.get(apiUrl, {
    params: {
      email_id: localStorage.getItem("selected_mail_box"),
      search: search,
      company_id: company_id,
      folder: currentBox,
      page: currentPage ?? 1,
      limit: pageSize ?? 50,
      day_since: 300
    }
  });
}

async function fetchSearchedMails_old(
  search,
  company_id,
  currentBox,
  currentPage,
  pageSize
) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      email_id: localStorage.getItem("selected_mail_box"),
      search: search,
      company_id: company_id,
      folder: currentBox,
      page: currentPage ?? 1,
      limit: pageSize ?? 50,
      day_since: 300
    }),
    false,
    "/search-mails",
    ExecutionMethod.GET
  );
  console.log("str_response /search-mails", response);

  try {
    if (
      response &&
      response.responseBody &&
      response.responseBody !== "" &&
      response.responseStatusCode === 200
    ) {
      let str_response = response.responseBody;
      str_response = JSON.parse(str_response);
      return str_response;
    } else {
      return response.responseBody;
    }
  } catch (e) {
    return undefined;
  }
}

async function fetchMailsList(company_id, box, currentPage, pageSize) {
  console.log("fetchMailsList", company_id, box, currentPage, pageSize);

  const apiUrl = "https://mailbox.semecloud.tech/get-all-mails";
  // const apiUrl = "http://localhost:55000/get-threads";
  return axios.get(apiUrl, {
    params: {
      email_id: localStorage.getItem("selected_mail_box"),
      company_id: company_id,
      folder: box
        ? box === "INBOX.SMARTAS.Sent"
          ? "SMARTAS.Sent"
          : box
        : "INBOX",
      page: currentPage ?? 1,
      limit: pageSize ?? 20,
      day_since: box !== "INBOX" ? 30 : 30
    }
  });
}

export async function getSentMessages(company_id, folder, message_id) {
  console.log("getSentMessages", company_id, folder, message_id);

  const apiUrl = "https://mailbox.semecloud.tech/get-sent-mails";
  // const apiUrl = "http://localhost:55000/get-threads";
  return axios.get(apiUrl, {
    params: {
      email_id: localStorage.getItem("selected_mail_box"),
      company_id: company_id,
      folder,
      message_id
    }
  });
}

async function fetchMailsList_old(company_id, box, currentPage, pageSize) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  console.log("body /get-threads", {
    email_id: localStorage.getItem("selected_mail_box"),
    company_id: company_id,
    folder: box
      ? box === "INBOX.SMARTAS.Sent"
        ? "SMARTAS.Sent"
        : box
      : "INBOX",
    page: currentPage ?? 1,
    limit: pageSize ?? 20,
    day_since: box !== "INBOX" ? 30 : 30
  });

  try {
    const response = await functions.createExecution(
      "function_box_manager_api_py",
      JSON.stringify({
        email_id: localStorage.getItem("selected_mail_box"),
        company_id: company_id,
        folder: box
          ? box === "INBOX.SMARTAS.Sent"
            ? "SMARTAS.Sent"
            : box
          : "INBOX",
        page: currentPage ?? 1,
        limit: pageSize ?? 20,
        day_since: box !== "INBOX" ? 30 : 30
      }),
      false,
      "/get-threads",
      ExecutionMethod.GET
    );
    console.log("str_response /get-threads", response);

    if (
      response &&
      response.responseBody &&
      response.responseBody !== "" &&
      response.responseStatusCode === 200 &&
      !response.responseBody.includes("error")
    ) {
      let str_response = response.responseBody;
      str_response = JSON.parse(str_response);
      return str_response;
    } else {
      return response.responseBody;
    }
  } catch (e) {
    return undefined;
  }
}

// async function fetchSingleMail(message_id, company_id, box) {
//   const apiUrl = "https://mailbox.semecloud.tech/get-single-mail";

//   return axios.get(apiUrl, {
//     params: {
//       message_id: message_id,
//       company_id: company_id,
//       folder: box ? box : "INBOX",
//     },
//   });
// }

async function fetchSingleMail(message_id, company_id, box, cancelToken) {
  const apiUrl = "https://mailbox.semecloud.tech/get-single-mail";
  // const apiUrl = "http://localhost:55000/get-single-mail";
  console.log("fetchSingleMail for ", message_id);

  return axios.get(apiUrl, {
    params: {
      email_id: localStorage.getItem("selected_mail_box"),
      message_id: message_id,
      company_id: company_id,
      folder: box ? box : "INBOX"
    },
    cancelToken
  });
}

async function fetchSingleMail_old(message_id, company_id, box) {
  console.log("fetchSingleMail for ", message_id);

  if (!message_id || message_id.length === 0) {
    console.log("fetchSingleMail Empty Message ID ", message_id);
    return undefined;
  }
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  try {
    const str_response = await functions.createExecution(
      "function_box_manager_api_py",
      JSON.stringify({
        email_id: localStorage.getItem("selected_mail_box"),
        message_id: message_id,
        company_id: company_id,
        folder: box ? box : "INBOX"
      }),
      false,
      "/get-single-mail",
      ExecutionMethod.GET
    );
    console.log("str_response /get-single-mail", str_response);

    if (
      str_response &&
      str_response.responseBody &&
      str_response.responseStatusCode === 200 &&
      !str_response.responseBody.includes("error")
    ) {
      return JSON.parse(str_response.responseBody);
    } else {
      console.log("Error fetchSingleMail", {
        searchShopifyOrder: str_response
      });
      return undefined;
    }
  } catch (error) {
    console.log("Error fetchSingleMail", error);
    return undefined;
  }
}

async function shopifyOauth(shop) {
  const apiUrl = "https://mailer-ai.semecloud.tech/shopify/shopify-oauth";
  // return await axios.get(apiUrl, {
  //   mode: "no-cors",
  //   params: {
  //     shop,
  //   },
  // });

  try {
    const response = await axios.get(apiUrl, {
      // headers: {
      //   "X-Shopify-Access-Token": shopify_api_key,
      // },
      params: {
        shop
      }
    });

    if (
      response.status === 304 ||
      response.status === 303 ||
      response.status === 200
    ) {
      const the_data = response.data;
      // console.log("response is", response);
      // console.log("the_data is", the_data);
      // console.log("redirectUrl is", the_data.auth_url);
      const redirectUrl = the_data.auth_url;
      // Effectuer la redirection si une URL de redirection est présente
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        // console.log("no redirectUrl");
      }
    } else {
      // console.log("Error is", response);
      window.location.href = response.auth_url;
    }
  } catch (error) {
    // console.log("Error", error);
  }
}

export async function fetchAgentLatestVersion(company_id, agent_id) {
  const apiUrl = "https://email-sync.semecloud.tech/mailer-version";
  const response = await axios.get(apiUrl, {
    params: {
      company_id,
      agent_id
    }
  });
  return response;
}

export async function fetchAgentInfos(company_id, agent_id) {
  const apiUrl = "https://email-sync.semecloud.tech/inspect-agent";
  const response = await axios.get(apiUrl, {
    params: {
      company_id,
      agent_id
    }
  });
  return response;
}

async function launchServer(agent_id) {
  const companyId = localStorage.getItem("companyId");
  axios
    .post(
      "https://email-sync.semecloud.tech/launch-agent",
      // "http://localhost:30000/launch-agent",
      {
        id: companyId,
        company_id: companyId,
        agent_id: agent_id
      }
    )
    .then(async (response) => {
      console.log(
        `Server launched on agent ${agent_id}. Launch response is`,
        response
      );

      return true;
    })
    .catch(async (error) => {
      console.log(
        `Server launch Error on agent ${agent_id}. Launch error is`,
        error
      );
      return false;
    });
}

export async function UpgradeAgent(company_id, agent_id) {
  const apiUrl = "https://email-sync.semecloud.tech/upgrade-agent";
  const response = await axios.post(apiUrl, {
    company_id,
    agent_id
  });
  return response;
}

export async function startAgent(agent_name) {
  try {
    const companyId = localStorage.getItem("companyId");

    const response = await axios.post(
      "https://email-sync.semecloud.tech/start-agent",
      // 'http://localhost:35000/start-agent',
      {
        id: companyId,
        company_id: companyId,
        agent_id: agent_name
      }
    );

    console.log(`Successfully started agent ${agent_name}`, response);

    // client_data.server_active = true;
    return true;
  } catch (error) {
    console.log(`Failed starting Agent ${agent_name}:`, error);
    return false;
  }
}

export async function stopAgent(agent_name) {
  try {
    const companyId = localStorage.getItem("companyId");

    const response = await axios.post(
      "https://email-sync.semecloud.tech/stop-agent",
      // 'http://localhost:35000/stop-agent',
      {
        id: companyId,
        company_id: companyId,
        agent_id: agent_name
      }
    );

    console.log(`Successfully stopped agent ${agent_name}`, response);

    // client_data.server_active = true;
    return true;
  } catch (error) {
    console.log(`Failed stopping Agent ${agent_name}:`, error);
    return false;
  }
}

export async function deleteAgent(companyId, agentId) {
  try {
    const response = await axios.post(
      "https://email-sync.semecloud.tech/delete-agent",
      {
        company_id: companyId,
        agent_id: agentId
      }
    );
    console.log({ response });

    return { applyed: true };
  } catch (error) {
    console.log("Failed stopping Agent", error.response);
    // Gérer les erreurs ici
    if (error.response) {
      // La requête a été effectuée et le serveur a répondu avec un code d'erreur
      console.log("Code d'erreur :", error.response.status);
      console.log("Texte de réponse :", error.response.data);
    } else if (error.request) {
      // La requête a été effectuée, mais aucune réponse n'a été reçue
      console.log("Aucune réponse reçue");
    } else {
      // Une erreur s'est produite lors de la configuration de la requête
      console.log(
        "Erreur de configuration de la requête :",
        JSON.stringify(error)
      );
    }
    return { applyed: error.includes(404) ? true : false };
  }
}

export async function getClientByEmail(email) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "function_maaia_admin",
    JSON.stringify({
      email,
      limit: 1
    }),
    false,
    "/search-users",
    ExecutionMethod.GET
  );

  console.log({ getClientByEmail: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error getClientByEmail", { getClientByEmail: response });
  }
}

export async function createStripeCheckoutSession(
  region,
  return_url,
  line_items
) {
  const apiUrl = "http://localhost:4242/stripe-create-checkout-session";
  try {
    const response = await axios.post(
      apiUrl,
      JSON.stringify({
        stripe_region: region,
        return_url,
        line_items
      })
    );
    return response;
  } catch (error) {
    console.log("Error createStripeCheckoutSession" + error);

    return undefined;
  }
}

export async function createStripeCustomer(stripe_region, email, name, tid) {
  // const apiUrl = "http://localhost:4242/stripe-create-customer";
  // try {
  //   const response = await axios.post(
  //     apiUrl,
  //     JSON.stringify({
  //       stripe_region,
  //       email,
  //       name,
  //     }),
  //   );
  //   console.log({ createStripeCustomer: response });

  //   return response;
  // } catch (error) {
  //   console.log("Error createStripeCheckoutSession" + error);

  //   return undefined;
  // }

  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      email,
      name,
      tid,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-create-customer",
    ExecutionMethod.POST
  );

  console.log({ createStripeCustomer: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error createStripeCustomer", {
      createStripeCustomer: response
    });
  }
}

export async function updateStripeCustomer(
  stripe_region,
  customer_id,
  customer_update_params
) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      customer_id,
      customer_update_params,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-update-customer",
    ExecutionMethod.POST
  );

  console.log({ updateStripeCustomer: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error updateStripeCustomer", {
      updateStripeCustomer: response
    });
  }
}

export async function createStripeSetupIntent(stripe_region, customer_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      customer_id,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-create-intent",
    ExecutionMethod.POST
  );

  console.log({ createStripeSetupIntent: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error createStripeSetupIntent", {
      createStripeSetupIntent: response
    });
  }
}

export async function finalizePayment(stripe_region, payment_intent_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      payment_intent_id,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-capture-payment",
    ExecutionMethod.POST
  );

  console.log({ finalizePayment: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error finalizePayment", { finalizePayment: response });
  }
}

export async function createOnShotPreview(
  stripe_region,
  price_id,
  number_of_packs,
  customer_id,
  company_id
) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      price_id,
      number_of_packs,
      customer_id,
      company_id,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-priview-one-shot",
    ExecutionMethod.GET
  );

  console.log({ createOnShotPreview: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error createOnShotPreview", { createOnShotPreview: response });
    return undefined;
  }
}

export async function createRetryPaymentPreview(
  stripe_region,
  subscription_id
) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      subscription_id,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-invoice-payment-intent",
    ExecutionMethod.GET
  );

  console.log({ createRetryPaymentPreview: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error createRetryPaymentPreview", {
      createRetryPaymentPreview: response
    });
    return undefined;
  }
}

export async function createStripeSubscripion(
  stripe_region,
  return_url,
  items,
  customerId,
  metadata,
  coupon_id,
  trial_period_days,
  client_secret
) {
  // const apiUrl = "http://localhost:4242/stripe-create-subscription";
  // try {
  //   const response = await axios.post(
  //     apiUrl,
  //     JSON.stringify({
  //       stripe_region,
  //       return_url,
  //       items,
  //       customerId,
  //     }),
  //   );
  //   return response;
  // } catch (error) {
  //   console.log("Error createStripeCheckoutSession" + error);

  //   return undefined;
  // }

  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      return_url,
      items,
      customerId,
      metadata,
      coupon_id,
      trial_period_days,
      client_secret,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-create-subscription",
    ExecutionMethod.POST
  );

  console.log({ createStripeSubscripion: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error createStripeSubscripion", {
      createStripeSubscripion: response
    });
    return undefined;
  }
}

export async function updateStripeSubscripion(
  stripe_region,
  subscription_id,
  items,
  metadata,
  proration_behavior,
  coupon
) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      subscription_id,
      coupon,
      items,
      metadata,
      proration_behavior,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-update-subscription",
    ExecutionMethod.POST
  );

  console.log({ updateStripeSubscripion: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error updateStripeSubscripion", {
      updateStripeSubscripion: response
    });
    return undefined;
  }
}

export async function retrieveStripeUpComingInvoice(
  stripe_region,
  customer_id,
  subscription_id,
  items,
  proration_behavior,
  coupon
) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      subscription_id,
      items,
      coupon,
      customer_id,
      proration_behavior,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-upcoming-invoice-retrieve",
    ExecutionMethod.GET
  );

  console.log({ retrieveStripeUpComingInvoice: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error retrieveStripeUpComingInvoice", {
      retrieveStripeUpComingInvoice: response
    });
    return undefined;
  }
}

export async function createStripePaymentEntent(region, plan_id) {
  const apiUrl = "http://localhost:4242/stripe-create-payment-intent";
  try {
    const response = await axios.post(
      apiUrl,
      JSON.stringify({
        items: [{ id: "synibox_infinity" }],
        stripe_region: region
      })
    );
    console.log({ createStripePaymentEntent: response });

    return response;
  } catch (error) {
    console.log("Error createStripeCheckoutSession" + error);

    return undefined;
  }
}

// async function shopifyOauthCallback(code, hmac, host, shop, state, timestamp) {
//   const apiUrl = `http://localhost:50001/auth/callback?code=${code}&hmac=${hmac}&host=${host}&shop=${shop}&state=${state}&timestamp=${timestamp}`;

//   try {
//     const response = await fetch(apiUrl, {
//       method: "GET",
//       mode: "no-cors",
//     });
//     const redirectUrl = response.url;
//     // Effectuer la redirection si une URL de redirection est présente
//     if (redirectUrl) {
//       window.location.href = redirectUrl;
//     } else {
//       // console.log("no redirectUrl");
//     }
//   } catch (error) {
//     // console.error("Error fetching data:", error);
//     throw error;
//   }
// }

async function fetchFoldersList(id) {
  const apiUrl = "https://mailbox.semecloud.tech/get-boxes";
  // const apiUrl = "http://localhost:55000/get-boxes";
  return axios.get(apiUrl, {
    params: {
      email_id: localStorage.getItem("selected_mail_box"),
      company_id: id
    }
  });
}

async function fetchFoldersList_old(id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);
  console.log({
    getBoxes: JSON.stringify({
      email_id: localStorage.getItem("selected_mail_box"),
      company_id: id
    })
  });
  try {
    const str_response = await functions.createExecution(
      "function_box_manager_api_py",
      JSON.stringify({
        email_id: localStorage.getItem("selected_mail_box"),
        company_id: id
      }),
      false,
      "/get-boxes",
      ExecutionMethod.GET
    );
    console.log("str_response /get-boxes", str_response);

    if (
      str_response &&
      str_response.responseBody &&
      str_response.responseStatusCode === 200 &&
      !str_response.responseBody.includes("error")
    ) {
      return JSON.parse(str_response.responseBody);
    } else {
      console.log("Error fetchFoldersList", {
        searchShopifyOrder: str_response
      });
      return undefined;
    }
  } catch (error) {
    console.log("Error fetchFoldersList", error);
    return undefined;
  }
}

async function copyMailPromise(message_id, the_folder, destination_folder) {
  const apiUrl = "https://mailbox.semecloud.tech/copy-mail";
  // const apiUrl = "http://localhost:55000/copy-mail";

  const company_id = localStorage.getItem("companyId");

  return axios.post(apiUrl, {
    email_id: localStorage.getItem("selected_mail_box"),
    company_id,
    destination_folder: destination_folder,
    folder: the_folder,
    messageId: message_id
  });
}

async function copyMailPromise_old(message_id, the_folder, destination_folder) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      email_id: localStorage.getItem("selected_mail_box"),
      company_id,
      destination_folder: destination_folder,
      folder: the_folder,
      messageId: message_id
    }),
    false,
    "/copy-mail",
    ExecutionMethod.POST
  );
  console.log("str_response /copy-mail", str_response);
  return str_response.responseBody;
}

async function moveMailPromise(message_id, folder, destination_folder) {
  const apiUrl = "https://mailbox.semecloud.tech/move-mail";
  // const apiUrl = "http://localhost:55000/move-mail";

  const company_id = localStorage.getItem("companyId");

  return axios.post(apiUrl, {
    email_id: localStorage.getItem("selected_mail_box"),
    company_id: company_id,
    destination_folder: destination_folder,
    folder: folder,
    messageId: message_id
  });
}

async function moveMailPromise_old(message_id, folder, destination_folder) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      email_id: localStorage.getItem("selected_mail_box"),
      company_id: company_id,
      destination_folder: destination_folder,
      folder: folder,
      messageId: message_id
    }),
    false,
    "/move-mail",
    ExecutionMethod.POST
  );
  console.log("str_response /move-mail", str_response);
  return str_response.responseBody;
}

async function deleteMailPromise(message_id, destination_box) {
  const apiUrl = "https://mailbox.semecloud.tech/delete-mail";
  // const apiUrl = "http://localhost:55000/delete-mail";

  const company_id = localStorage.getItem("companyId");

  return axios.post(apiUrl, {
    email_id: localStorage.getItem("selected_mail_box"),
    company_id: company_id,
    folder: destination_box,
    messageId: message_id
  });
}

async function deleteMailPromise_old(message_id, destination_box) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      email_id: localStorage.getItem("selected_mail_box"),
      company_id: company_id,
      folder: destination_box,
      messageId: message_id
    }),
    false,
    "/delete-mail",
    ExecutionMethod.POST
  );
  console.log("str_response /delete-mail", str_response);
  return str_response.responseBody;
}

async function sendMailPromise(messageToReply, text, message_to_reply_id) {
  const apiUrl = "https://mailbox.semecloud.tech/send-mail";
  // const apiUrl = "http://localhost:55000/send-mail";

  const company_id = localStorage.getItem("companyId");

  return axios.post(apiUrl, {
    email_id: localStorage.getItem("selected_mail_box"),
    to: messageToReply.user_email,
    to_name: messageToReply.from,
    subject: messageToReply.message_subject,
    text: text,
    company_id: company_id,
    message_to_reply_references: messageToReply.headers.references,
    message_to_reply_id,
    message_to_reply: messageToReply.body.html
      ? messageToReply.body.html
      : messageToReply.body.textAsHtml
  });
}

async function sendMailPromise_old(messageToReply, text, message_to_reply_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      email_id: localStorage.getItem("selected_mail_box"),
      to: messageToReply.user_email,
      to_name: messageToReply.from,
      subject: messageToReply.message_subject,
      text: text,
      company_id: company_id,
      message_to_reply_references: messageToReply.headers.references,
      message_to_reply_id,
      message_to_reply: messageToReply.body.html
        ? messageToReply.body.html
        : messageToReply.body.textAsHtml
    }),
    false,
    "/send-mail",
    ExecutionMethod.POST
  );
  console.log("str_response /send-mail", str_response);
  return str_response.responseBody;
}

async function sendMailDirect(data) {
  const apiUrl = "https://mailbox.semecloud.tech/send-mail-direct";
  // const apiUrl = "http://localhost:55000/send-mail-direct";

  return axios.post(apiUrl, data);
}

async function sendMailDirect_old(data) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify(data),
    false,
    "/send-mail-direct",
    ExecutionMethod.POST
  );
  console.log("str_response /send-mail-direct", str_response);
  return str_response.responseBody;
}

async function createBoxPromise(folder) {
  const apiUrl = "https://mailbox.semecloud.tech/create-boxe";
  // const apiUrl = "http://localhost:55000/create-boxe";

  const company_id = localStorage.getItem("companyId");

  return axios.post(apiUrl, {
    company_id,
    email_id: localStorage.getItem("selected_mail_box"),
    folder
  });
}

async function createBoxPromise_old(folder) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      company_id,
      email_id: localStorage.getItem("selected_mail_box"),
      folder
    }),
    false,
    "/create-boxe",
    ExecutionMethod.PUT
  );
  console.log("str_response /create-boxe", str_response);
  return str_response.responseBody;
}

async function createAllMaaiaBoxes() {
  const apiUrl = "https://mailbox.semecloud.tech/create-maaia-boxes";
  // const apiUrl = "http://localhost:55000/create-maaia-boxes";

  const company_id = localStorage.getItem("companyId");

  return axios.post(apiUrl, {
    company_id: company_id,
    email_id: localStorage.getItem("selected_mail_box")
  });
}

async function createAllMaaiaBoxes_old() {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const company_id = localStorage.getItem("companyId");

  const str_response = await functions.createExecution(
    "function_box_manager_api_py",
    JSON.stringify({
      company_id: company_id,
      email_id: localStorage.getItem("selected_mail_box")
    }),
    false,
    "/create-maaia-boxes",
    ExecutionMethod.PUT
  );
  console.log("str_response /create-maaia-boxes", str_response);
  return str_response;
}

async function createAllMaaiaBoxesDirect(data) {
  const apiUrl = "https://mailbox.semecloud.tech/create-maaia-boxes-direct";
  // const apiUrl = "http://localhost:55000/create-maaia-boxes-direct";
  return axios.post(apiUrl, data);
}

async function createAllMaaiaBoxesDirect_old(data) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  try {
    const str_response = await functions.createExecution(
      "function_box_manager_api_py",
      JSON.stringify(data),
      false,
      "/create-maaia-boxes-direct",
      ExecutionMethod.PUT
    );
    console.log("str_response /create-maaia-boxes-direct", str_response);
    return str_response;
    // if (str_response && str_response.responseBody && str_response.responseStatusCode === 200 && !str_response.responseBody.includes("error")) {

    // } else {
    //   console.log("Error createAllMaaiaBoxesDirect", { createAllMaaiaBoxesDirect: str_response });
    //   return str_response.responseBody;
    // }
  } catch (error) {
    console.log("Error createAllMaaiaBoxesDirect", { error: error });
    return undefined;
  }
}

async function translateWithDeepLPromise(text, target_lang) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const str_response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      text,
      target_lang
    }),
    false,
    "/deepl/v2/translate",
    ExecutionMethod.GET
  );
  console.log("str_response /deepl/v2/translate", str_response);
  return str_response.responseBody;
}

async function logoutUserSession() {
  const client = new Client();

  const account = new Account(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  return account.deleteSessions();
}

async function createLog(data, track) {
  if (track) {
    track(data.event);
  }

  ReactGA.event({
    category: data.event,
    action: data.event
    //label: "your label", // optional
    //  value: 99, // optional, must be a number
  });

  try {
    const client = new Client();
    const databases = new Databases(client, "default");
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);
    const response = await databases.createDocument(
      "default",
      "logs",
      ID.unique(),
      data
    );

    // console.log("Log created ", response); // Success
    return response;
  } catch (error) {
    // console.log("Error creating Log ", error); // Gestion de l'erreur
    return null;
  }
}

export async function createClientInfo(user_id, data) {
  try {
    const client = new Client();
    const databases = new Databases(client, "default");
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);
    const response = await databases.createDocument(
      "default",
      "client_infos",
      user_id,
      data
    );
    console.log("ClientInfo created ", response); // Success
    return response;
  } catch (error) {
    console.log("Error creating ClientInfo ", error); // Gestion de l'erreur
    return null;
  }
}

export async function updateUserData(id, data) {
  try {
    const client = new Client();
    const databases = new Databases(client, "default");
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);
    const response = await databases.updateDocument(
      "default",
      "client_infos",
      id,
      data
    );
    return response;
  } catch (error) {
    return null;
  }
}

async function createFeedBack(data) {
  try {
    const client = new Client();
    const databases = new Databases(client, "default");
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);
    const response = await databases.createDocument(
      "default",
      "feedback",
      ID.unique(),
      data
    );

    // console.log("Feedback created ", response); // Success
    return response;
  } catch (error) {
    // console.log("Error creating Feedback ", error); // Gestion de l'erreur
    return null;
  }
}

async function getEmployeeLink(userId) {
  const client = new Client();
  const databases = new Databases(client, "default");

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const promise = databases.listDocuments("default", "employee_link", [
    Query.equal("employee_id", userId)
  ]);

  return promise.then(
    async function (response) {
      // console.log("Sidebar.js: Employee link is ", response.documents);
      return response.documents;
    },
    function (error) {
      // console.log("Error creating Employee link", error); // Failure
      return null;
    }
  );
}

async function getStripeSubscriptionPromise(subscription_id, stripe_region) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const data = {
    subscription_id,
    stripe_region,
    env: process.env.REACT_APP_MAAIA_ENV
  };
  return functions.createExecution(
    "third_party_api",
    JSON.stringify(data),
    false,
    "/stripe-subscriptions-get",
    ExecutionMethod.GET
  );
}
async function getStripeProductPromise(product_id, stripe_region) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const data = {
    product_id,
    stripe_region,
    env: process.env.REACT_APP_MAAIA_ENV
  };
  return functions.createExecution(
    "third_party_api",
    JSON.stringify(data),
    false,
    "/stripe-products-get",
    ExecutionMethod.GET
  );
}
// async function getStripePaymentMethodPromise(payment_method_id, stripe_region) {
//   const client = new Client();

//   const functions = new Functions(client);

//   client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

//   const data = {
//     payment_method_id,
//     stripe_region
//   };
//   return functions.createExecution("third_party_api", JSON.stringify(data), false, "/stripe-payment-method-get", ExecutionMethod.GET);
// }
async function getStripePaymentMethodPromise(customer_id, stripe_region) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const data = {
    customer_id,
    stripe_region,
    env: process.env.REACT_APP_MAAIA_ENV
  };
  return functions.createExecution(
    "third_party_api",
    JSON.stringify(data),
    false,
    "/stripe-payment-methods-get",
    ExecutionMethod.GET
  );
}
async function getStripeInvoicesListPromise(customer_id, limit, stripe_region) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const data = {
    customer_id,
    stripe_region,
    limit,
    env: process.env.REACT_APP_MAAIA_ENV
  };
  return functions.createExecution(
    "third_party_api",
    JSON.stringify(data),
    false,
    "/stripe-invoices-list",
    ExecutionMethod.GET
  );
}
async function getStripeProductsListPromise(limit, stripe_region) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const data = {
    stripe_region,
    limit,
    env: process.env.REACT_APP_MAAIA_ENV
  };
  return functions.createExecution(
    "third_party_api",
    JSON.stringify(data),
    false,
    "/stripe-products-list",
    ExecutionMethod.GET
  );
}

export function checkSubcriptionUpdateType(new_price_id, old_price_id) {
  const getPriority = (price_id) => {
    switch (price_id) {
      case SMARTAS.DIRECT_WORLD:
        return 0;
      case "MAAIA_PLAN_DIRECT_WORLD":
        return 0;
      case SMARTAS.STARTER_WORLD:
        return 1;
      case SMARTAS.STARTER_US:
        return 1;
      case "MAAIA_PLAN_STARTER":
        return 1;
      case SMARTAS.GROWTH_WORLD:
        return 2;
      case SMARTAS.GROWTH_US:
        return 2;
      case "MAAIA_PLAN_GROWTH":
        return 2;
      case SMARTAS.BUSINESS_WORLD:
        return 3;
      case SMARTAS.BUSINESS_US:
        return 3;
      case "MAAIA_PLAN_BUSINESS":
        return 3;
      case SMARTAS.PRO_WORLD:
        return 4;
      case SMARTAS.PRO_US:
        return 4;
      case "MAAIA_PLAN_PRO":
        return 4;
      default:
        return -1;
    }
  };
  return getPriority(new_price_id) > getPriority(old_price_id)
    ? "upgrade"
    : "downgrade";
}

export async function getStripeSubscription(
  subscription_id,
  stripe_region,
  need_product,
  minimal
) {
  const client_data = await getCompanyInfos();
  console.log(`getCompanyInfosPromise is`, client_data);

  if (client_data) {
    if (client_data.direct_plan) {
      return null;
    }
  }

  try {
    const exec_response = await getStripeSubscriptionPromise(
      subscription_id,
      stripe_region
    );
    console.log(
      "exec_response Stripe subscription get from getStripeSubscription",
      exec_response
    );
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log(
        "Execution succeeded:",
        JSON.parse(exec_response.responseBody)
      );
      const responseBody = JSON.parse(exec_response.responseBody).subscription;
      const item = responseBody.items.data.find(
        (item) =>
          item.price.id === SMARTAS.STARTER_WORLD ||
          item.price.id === SMARTAS.STARTER_US ||
          item.price.id === SMARTAS.GROWTH_WORLD ||
          item.price.id === SMARTAS.GROWTH_US ||
          item.price.id === SMARTAS.BUSINESS_WORLD ||
          item.price.id === SMARTAS.BUSINESS_US ||
          item.price.id === SMARTAS.PRO_WORLD ||
          item.price.id === SMARTAS.PRO_US ||
          item.price.id === SMARTAS.PAUSE_US ||
          item.price.id === SMARTAS.PAUSE_WORLD
      );

      const product_id = item.price.product;
      const sub_item_id = item.id;
      const price_id = item.price.id;

      let subscription_product;
      if (need_product) {
        subscription_product = await getStripeProduct(
          product_id,
          stripe_region
        );
      }

      if (minimal) {
        return {
          id: responseBody.id,
          amount: responseBody.plan.amount / 100,
          name: subscription_product ? subscription_product.name : "",
          sub_item_id,
          price_id
        };
      }
      return {
        id: responseBody.id,
        amount: responseBody.plan.amount / 100,
        trialing: responseBody.status === "trialing",
        name: subscription_product ? subscription_product.name : "",
        plan_active_id: product_id
      };
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function getStripeProduct(product_id, stripe_region) {
  try {
    const exec_response = await getStripeProductPromise(
      product_id,
      stripe_region
    );
    console.log("exec_response Stripe product get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log(
        "Execution succeeded:",
        JSON.parse(exec_response.responseBody)
      );
      return JSON.parse(exec_response.responseBody).product;
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

// export async function getStripePaymentMethod(payment_method_id, stripe_region) {
//   try {
//     const exec_response = await getStripePaymentMethodPromise(payment_method_id, stripe_region);
//     console.log("exec_response Stripe Payment Method get", exec_response);
//     if (exec_response.status === "failed") {
//       console.error(`Error: ${exec_response.responseBody}`);
//       return null;
//     } else {
//       // L'exécution a réussi, traiter la réponse
//       console.log("Execution succeeded:", JSON.parse(exec_response.responseBody));
//       return JSON.parse(exec_response.responseBody).payment_method;
//     }
//   } catch (error) {
//     console.error("Execution failed to start: ", error);
//     return null;
//   }
// }

export async function getStripePaymentMethod(customer_id, stripe_region) {
  try {
    const exec_response = await getStripePaymentMethodPromise(
      customer_id,
      stripe_region
    );
    console.log("exec_response Stripe Payment Method get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log(
        "Execution succeeded:",
        JSON.parse(exec_response.responseBody)
      );
      return JSON.parse(exec_response.responseBody).payment_methods.data[0];
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function getStripeInvoicesList(customer_id, limit, stripe_region) {
  try {
    const exec_response = await getStripeInvoicesListPromise(
      customer_id,
      limit,
      stripe_region
    );
    console.log("exec_response Stripe Invoices List get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log(
        "Execution succeeded:",
        JSON.parse(exec_response.responseBody)
      );

      const products_map = await getStripeProductsList(
        undefined,
        stripe_region
      );
      // console.log({ products_map });

      if (!products_map) {
        return null;
      }
      let invoiceList = JSON.parse(
        exec_response.responseBody
      ).invoices.data.map((invoice) => {
        const product_id = invoice.lines.data.find(
          (data) => data.quantity === 1
        ).plan.product;
        return {
          id: invoice.id,
          amount_due: invoice.amount_due / 100,
          date: invoice.created,
          name: products_map[product_id],
          invoice_pdf_url: invoice.invoice_pdf,
          product_id
        };
      });

      // console.log({ invoiceList });

      return invoiceList;
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function getStripeProductsList(limit, stripe_region) {
  try {
    const exec_response = await getStripeProductsListPromise(
      limit,
      stripe_region
    );
    console.log("exec_response Stripe Product List get", exec_response);
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log(
        "Execution succeeded:",
        JSON.parse(exec_response.responseBody)
      );
      const products_array = JSON.parse(exec_response.responseBody).products
        .data;
      let products_map = {};
      for (let index = 0; index < products_array.length; index++) {
        products_map = {
          ...products_map,
          [products_array[index].id]: products_array[index].name
        };
      }
      return products_map;
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function getStripeSubscriptionPlanPriceId(
  subscription_id,
  stripe_region
) {
  try {
    const exec_response = await getStripeSubscriptionPromise(
      subscription_id,
      stripe_region
    );
    console.log(
      "exec_response Stripe subscription get from getStripeSubscriptionPlanPriceId",
      exec_response
    );
    if (exec_response.status === "failed") {
      console.error(`Error: ${exec_response.responseBody}`);
      return null;
    } else {
      // L'exécution a réussi, traiter la réponse
      console.log(
        "Execution succeeded:",
        JSON.parse(exec_response.responseBody)
      );
      const responseBody = JSON.parse(exec_response.responseBody).subscription;
      const price_id = responseBody.items.data.find(
        (item) => item.quantity === 1
      ).price.id;

      return price_id;
    }
  } catch (error) {
    console.error("Execution failed to start: ", error);
    return null;
  }
}

export async function applyStripeDiscountCode(stripe_region, promo_code) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      promo_code,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-apply-discount-code",
    ExecutionMethod.POST
  );

  console.log({ applyStripeDiscountCode: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error applyStripeDiscountCode", {
      applyStripeDiscountCode: response
    });
  }
}

export async function checkAIAgentFunction(agent_id, company_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  try {
    const response = await functions.listExecutions(
      `${company_id}_${agent_id}`
    );

    console.log({ checkAIAgentFunction: response });

    if (response) {
      return false;
    }
  } catch (e) {
    console.log("Catch Error checkAIAgentFunction", {
      checkAIAgentFunction: e
    });
    return { error_code: e.code };
  }
}

export async function checkStatisticsFunction(company_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  try {
    const response = await functions.listExecutions(company_id);

    console.log({ checkStatisticsFunction: response });

    if (
      response &&
      response.responseBody &&
      response.responseStatusCode === 200
    ) {
      return JSON.parse(response.responseBody);
    } else {
      console.log("Error checkStatisticsFunction", {
        checkStatisticsFunction: response
      });
      return null;
    }
  } catch (e) {
    console.log("Error checkStatisticsFunction", {
      checkStatisticsFunction: e
    });
    return { error_code: e.code };
  }
}

export async function checkClientDataFunction(company_id) {
  const client = new Client();

  const databases = new Databases(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  try {
    const response = await databases.getDocument(
      "default",
      "client_infos",
      company_id
    );

    console.log({ checkClientDataFunction: response });

    return response;
  } catch (e) {
    console.log("Error checkClientDataFunction", {
      checkClientDataFunction: e
    });
    return { error_code: e.code };
  }
}

export async function cancelStripeSubscription(stripe_region, subscription_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      subscription_id,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-cancel-subscription",
    ExecutionMethod.POST
  );

  console.log({ cancelStripeSubscription: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error cancelStripeSubscription", {
      cancelStripeSubscription: response
    });
    return null;
  }
}

export async function cancelStripeTrial(stripe_region, subscription_id) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      stripe_region,
      subscription_id,
      env: process.env.REACT_APP_MAAIA_ENV
    }),
    false,
    "/stripe-cancel-trial",
    ExecutionMethod.POST
  );

  console.log({ cancelStripeTrial: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error cancelStripeTrial", { cancelStripeTrial: response });
    return null;
  }
}

async function getUserPrefs() {
  const account = new Account(appwriteClient);
  const promise = account.getPrefs();
  return promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      // console.log(error);
      return undefined;
    }
  );
}
/*
async function checkSession() {
  const client = new Client();

  const account = new Account(client);

  client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

  const promise = account.getSession('current');

  promise.then(
    function (response) {
      console.log('Success on session check');
      console.log(response);
      return response;
    },
    function (error) {
      console.log('error on session check', error);
      return null;
    }
  );
}
*/

async function getCompanyInfos(userId) {
  // console.log({ userId });

  const client = new Client();
  const databases = new Databases(client, "default");

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);
  const promise = databases.getDocument("default", "client_infos", userId);
  return promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      // console.log("Error on getUserCompanyInfos", error, error.code);
      return null;
    }
  );
}

async function createPwRecovery(email) {
  const client = new Client();
  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);
  const account = new Account(client);
  return account.createRecovery(email, "https://app.synibox.io/auth-resetpw");
}

async function fetchStatistics(company_id, period, start_date, end_date) {
  const client = new Client();
  const databases = new Databases(client, "default");

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const promise = databases.listDocuments("default", "6650725659de3b15cb3a", [
    Query.equal("company_id", company_id),
    Query.equal("period", period),
    Query.between("$updatedAt", start_date, end_date),
    Query.orderDesc("$updatedAt")
  ]);
  return promise.then(
    async function (response) {
      return response;
    },
    function (error) {
      console.log("Error on fetchStatistics", error, error.code);
      return null;
    }
  );
}

async function searchShopifyOrder(
  order_number,
  order_field,
  shopify_api_key,
  shopify_store_url,
  search_field
) {
  // const apiUrl = `http://ai.semecloud.tech:50000/shopify/search-order`;

  // return axios.get(apiUrl, {
  //   params: {
  //     search,
  //     order_field,
  //     shopify_api_key,
  //     shopify_store_url,
  //   },
  // });
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      order_number,
      order_field,
      shopify_api_key,
      shopify_store_url,
      search_field
    }),
    false,
    "/shopify/search-order",
    ExecutionMethod.GET
  );

  console.log({ searchShopifyOrder: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error searchShopifyOrder", { searchShopifyOrder: response });
    return undefined;
  }
}
const meta_server_url = "https://meta-app.semecloud.tech";
// const meta_server_url = "http://localhost:51000";
export async function fetchFBPagesList(access_token, account_id) {
  // const client = new Client();

  // const functions = new Functions(client);

  // client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

  // const response = await functions.createExecution(
  //   "third_party_api",
  //   JSON.stringify({
  //     access_token,
  //     account_id
  //   }),
  //   false,
  //   "/facebook-fetch-pages-list",
  //   ExecutionMethod.GET
  // );

  // console.log({ fetchFBPagesList: response });

  // if (response && response.responseBody && response.responseStatusCode === 200) {
  //   return JSON.parse(response.responseBody);
  // } else {
  //   console.log("Error fetchFBPagesList", { fetchFBPagesList: response });
  //   return null;
  // }

  try {
    const response = await axios.get(
      `${meta_server_url}/facebook-fetch-pages-list`,
      {
        params: {
          access_token,
          account_id
        }
      }
    );
    console.log({ fetchFBPagesList: response });
    return response;
  } catch (error) {
    console.log("Error fetchFBPagesList", { fetchFBPagesList: error });
    return null;
  }
}

export async function fetchWHCompteInfo(access_token, account_id) {
  try {
    const response = await axios.get(
      `${meta_server_url}/fetch-whatsapp-compte-info?account_id=${account_id}&access_token=${access_token}`
    );
    console.log({ fetchWHCompteInfo: response });
    return response;
  } catch (error) {
    console.log({ fetchWHCompteInfoError: error });
    return undefined;
  }
}

export async function fetchFBAccessToken(code) {
  // const client = new Client();

  // const functions = new Functions(client);

  // client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

  // const response = await functions.createExecution(
  //   "third_party_api",
  //   JSON.stringify({
  //     code
  //   }),
  //   false,
  //   "/facebook-fetch-access-token",
  //   ExecutionMethod.GET
  // );

  // console.log({ fetchFBAccessToken: response });

  // if (response && response.responseBody && response.responseStatusCode === 200) {
  //   return JSON.parse(response.responseBody);
  // } else {
  //   console.log("Error fetchFBAccessToken", { fetchFBAccessToken: response });
  //   return null;
  // }
  try {
    const response = await axios.get(
      `${meta_server_url}/facebook-fetch-access-token`,
      {
        params: {
          code
        }
      }
    );
    console.log({ fetchFBAccessToken: response });
    return response;
  } catch (error) {
    console.log("Error fetchFBAccessToken", { fetchFBAccessToken: error });
    return null;
  }
}
export async function fetchFBPostsList(page_id, access_token) {
  // const client = new Client();

  // const functions = new Functions(client);

  // client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

  // const response = await functions.createExecution(
  //   "third_party_api",
  //   JSON.stringify({
  //     page_id, access_token
  //   }),
  //   false,
  //   "/facebook-fetch-posts-list",
  //   ExecutionMethod.GET
  // );

  // console.log({ fetchFBPostsList: response });

  // if (response && response.responseBody && response.responseStatusCode === 200) {
  //   return JSON.parse(response.responseBody);
  // } else {
  //   console.log("Error fetchFBPostsList", { fetchFBPostsList: response });
  //   return null;
  // }

  try {
    const response = await axios.get(
      `${meta_server_url}/facebook-fetch-posts-list`,
      {
        params: {
          page_id,
          access_token
        }
      }
    );
    console.log({ fetchFBPostsList: response });
    return response;
  } catch (error) {
    console.log("Error fetchFBPostsList", { fetchFBPostsList: error });
    return null;
  }
}

export async function fetchFBSubscribeToWebhook(page_id, page_access_token) {
  try {
    const response = await axios.get(
      `${meta_server_url}/facebook-subscribe-to-webhook`,
      {
        params: {
          page_id,
          page_access_token
        }
      }
    );
    console.log({ fetchFBSubscribeToWebhook: response });
    return response;
  } catch (error) {
    console.log("Error fetchFBSubscribeToWebhook", {
      fetchFBSubscribeToWebhook: error
    });
    return null;
  }
}

export async function fetchFBDisconnectPageWebhook(
  page_id,
  page_access_token,
  company_id
) {
  try {
    const response = await axios.get(
      `${meta_server_url}/facebook-disconnect-page-webhook`,
      {
        params: {
          page_id,
          page_access_token,
          company_id
        }
      }
    );
    console.log({ fetchFBDisconnectPageWebhook: response });
    return response;
  } catch (error) {
    console.log("Error fetchFBDisconnectPageWebhook", {
      fetchFBDisconnectPageWebhook: error
    });
    return null;
  }
}

export async function fetchFBDisconnectUser(company_id, connected_pages) {
  try {
    const response = await axios.get(
      `${meta_server_url}/facebook-disconnect-user`,
      {
        params: {
          company_id,
          connected_pages
        }
      }
    );
    console.log({ fetchFBDisconnectUser: response });
    return response;
  } catch (error) {
    console.log("Error fetchFBDisconnectUser", {
      fetchFBDisconnectUser: error
    });
    return null;
  }
}

export async function getCollections(shopify_api_key, shopify_store_url) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const response = await functions.createExecution(
    "third_party_api",
    JSON.stringify({
      shopify_api_key,
      shopify_store_url
    }),
    false,
    "/shopify/collections-get",
    ExecutionMethod.GET
  );

  console.log({ getCollections: response });

  if (
    response &&
    response.responseBody &&
    response.responseStatusCode === 200
  ) {
    return JSON.parse(response.responseBody);
  } else {
    console.log("Error getCollections", { getCollections: response });
    return null;
  }
}

export const appwriteDatabase = new Databases(appwriteClient, "default");

export const retreiveActiveSubscriptionPlan = (
  activeSubscription,
  listPlans
) => {
  const plan_id = activeSubscription.subscription_items.find(
    (item) => item.item_type === "plan"
  ).item_price_id;
  const active_subscription_plan = listPlans.find(
    (plan) => plan.id === plan_id
  );
  return active_subscription_plan;
};

async function getWhoisFunctionExecution(data) {
  try {
    const apiUrl = "https://mailbox.semecloud.tech/get-whois";
    // const apiUrl = "http://localhost:55000/get-whois";

    return axios.get(apiUrl, { params: data });
  } catch (error) {
    console.log({ whoisResponse: error });
    return undefined;
  }
}

async function getWhoisFunctionExecution_old(data) {
  const client = new Client();

  const functions = new Functions(client);

  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  try {
    const str_response = await functions.createExecution(
      "function_box_manager_api_py",
      JSON.stringify(data),
      false,
      "/get-whois",
      ExecutionMethod.GET
    );

    console.log(str_response);

    if (
      str_response &&
      str_response.responseBody &&
      str_response.responseStatusCode === 200 &&
      !str_response.responseBody.includes("error")
    ) {
      return JSON.parse(str_response.responseBody);
    } else if (str_response.responseStatusCode === 500) {
      return { error: "Erreur inattendue" };
    } else {
      console.log("Error getWhoisFunctionExecution", {
        getWhoisFunctionExecution: str_response
      });
      return JSON.parse(str_response.responseBody);
    }
  } catch (error) {
    console.log("Error getWhoisFunctionExecution", error);
    return undefined;
  }
}

export function formatDate(props, theDate, short, stripe) {
  // Si theDate est au format ISO, le convertir en timestamp

  let finalDate = "";

  if (typeof theDate === "string" && theDate.includes("T")) {
    finalDate = Date.parse(theDate);
  }

  // Convertir en millisecondes si le timestamp est en secondes (moins de 13 chiffres)
  if (stripe) {
    finalDate = theDate * 1000;
  }

  // Définir les options pour le format de date
  const options = short
    ? { day: "2-digit", month: "short", year: "2-digit" }
    : { day: "2-digit", month: "long", year: "numeric" };

  // Vérifier que le langage est bien défini dans props.i18n
  const language = props?.i18n?.language || "en";

  // Format de la date
  const date = new Date(finalDate);

  return date.toLocaleDateString(
    `${language === "en" ? "us-US" : "fr-FR"}`,
    options
  );
}

export function formatDateHours(props, theDate, short, stripe, customFormat) {
  // Si theDate est au format ISO, le convertir en timestamp

  if (theDate) {
    let finalDate = "";

    if (typeof theDate === "string" && theDate.includes("T")) {
      finalDate = Date.parse(theDate);
    }

    // Convertir en millisecondes si le timestamp est en secondes (moins de 13 chiffres)
    if (stripe) {
      finalDate = theDate * 1000;
    }

    // Format de la date
    const date = new Date(finalDate);

    if (customFormat) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
      const year = String(date.getFullYear()).slice(-2);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${day}-${month}-${year} ${hours}h${minutes}`;
    }

    // Définir les options pour le format de date
    const options = short
      ? {
          day: "2-digit",
          month: "short",
          year: "2-digit",
          hour: "2-digit",
          minute: "2-digit"
        }
      : {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit"
        };

    // Vérifier que le langage est bien défini dans props.i18n
    const language = props?.i18n?.language || "en";

    return date.toLocaleDateString(
      `${language === "en" ? "us-US" : "fr-FR"}`,
      options
    );
  } else {
    return undefined;
  }
}

// export function formatDate(props, theDate, short, stripe) {
//   // Convertir en millisecondes si le timestamp est en secondes (moins de 13 chiffres)
//   if (stripe) {
//     theDate *= 1000;
//   }

//   const options = short ? { day: "2-digit", month: "short", year: "2-digit" } : { day: "2-digit", month: "long", year: "numeric" };
//   const date = new Date(theDate);

//   return date.toLocaleDateString(props.i18n.language + "-" + props.i18n.language.toUpperCase(), options);
// }

export function formatDateMillisec(millisec) {
  // Crée un nouvel objet Date à partir du timestamp en millisecondes
  const date = new Date(millisec);

  // Obtenir le jour, mois et année
  const day = String(date.getDate()).padStart(2, "0"); // Obtenir le jour et ajouter un zéro si nécessaire
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Obtenir le mois (0-11) et ajouter un zéro
  const year = date.getFullYear(); // Obtenir l'année

  // Retourner la date au format JJ-MM-YYYY
  return `${day}-${month}-${year}`;
}

export function formatWABDate(timestamp) {
  // Crée un nouvel objet Date à partir du timestamp en millisecondes
  const res = parseInt(timestamp, 10) * 1000;

  const date = new Date(res);

  const formattedDate = date.toLocaleString();

  // Retourner la date au format JJ-MM-YYYY
  return formattedDate;
}

export async function getShopifyConfigurations(
  shopify_api_key,
  shopify_store_url
) {
  const client = new Client();

  const functions = new Functions(client);
  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);
  try {
    const response = await functions.createExecution(
      "third_party_api",
      JSON.stringify({
        shopify_api_key,
        shopify_store_url
      }),
      false,
      "/shopify/configuration-get",
      ExecutionMethod.GET
    );

    // console.log({ getShopifyConfigurations: response });

    if (
      response &&
      response.responseBody &&
      response.responseStatusCode === 200
    ) {
      return JSON.parse(response.responseBody);
    }
    console.log("Error getShopifyConfigurations", {
      getShopifyConfigurations: response
    });
    return undefined;
  } catch (e) {
    console.log("Error getShopifyConfigurations", e.message);
    return undefined;
  }
}

export function formatWHDate(props, theDate, short, stripe) {
  // Si theDate est au format ISO, le convertir en timestamp

  let finalDate = "";

  // if (typeof theDate === "string" && theDate.includes("T")) {
  //   finalDate = Date.parse(theDate);
  // }

  // Convertir en millisecondes si le timestamp est en secondes (moins de 13 chiffres)
  if (stripe) {
    finalDate = parseInt(theDate) * 1000;
  } else {
    finalDate = parseInt(theDate);
  }

  // Définir les options pour le format de date
  const options = short
    ? { day: "2-digit", month: "short", year: "2-digit" }
    : { day: "2-digit", month: "long", year: "numeric" };

  // Vérifier que le langage est bien défini dans props.i18n
  const language = props?.i18n?.language || "en";

  // Format de la date
  const date = new Date(finalDate);

  return date.toLocaleDateString(
    `${language === "en" ? "en-US" : "fr-FR"}`,
    options
  );
}

export async function getAllMessagesOfConversation(conversation_id) {
  const theQuery = [
    Query.equal("conversation_id", conversation_id),
    Query.limit(100)
  ];

  try {
    const client = new Client();
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const databases = new Databases(client, "default");

    const response = await databases.listDocuments(
      "default",
      "message",
      theQuery
    );

    return response.documents;
  } catch (error) {
    console.log("Error getAllMessagesOfConversation", error.message);
    return undefined;
  }
}

export const getConversations = async (
  origin,
  filter,
  search,
  pageSize,
  old_messages
) => {
  const client = new Client();
  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const databases = new Databases(client, "default");

  const cursor =
    old_messages && old_messages.length > 0
      ? old_messages[old_messages.length - 1].$id
      : undefined;

  const queries = [
    Query.equal("company_id", localStorage.getItem("companyId")),
    Query.orderDesc("timestamp_last_message"),
    Query.limit(pageSize)
  ];

  if (
    origin === "starred" ||
    origin === "archive" ||
    origin === "draft" ||
    origin === "junk"
  ) {
    queries.push(Query.contains("tags", origin));
  } else if (origin === "sent") {
    queries.push(Query.equal("direction", "sent"));
  } else if (origin) {
    queries.push(Query.equal("origin", origin));
  }

  if (filter && filter === "treated") {
    queries.push(Query.equal("process_state", "Treated"));
  } else if (filter && filter === "untreated") {
    queries.push(Query.equal("process_state", "Untreated"));
  }
  // else if (filter && search) {
  //   queries.push(
  //     Query.or([
  //       Query.search("message_body", filter),
  //       Query.search("user_name", filter)
  //     ])
  //   );
  // }

  if (cursor) {
    queries.push(Query.cursorAfter(cursor));
  }

  try {
    let response = [];

    if (filter && search) {
      const msgResp = await databases.listDocuments("default", "message", [
        Query.equal("company", localStorage.getItem("companyId")),
        Query.orderDesc("timestamp"),
        Query.or([
          Query.search("message_body", filter),
          Query.search("user_name", filter),
          Query.equal("$id", filter)
        ])
      ]);

      if (msgResp && msgResp.total > 0) {
        let list = [];
        for (let i = 0; i < msgResp.total; i++) {
          const elt = msgResp.documents[i];
          if (elt) {
            const searchConvResp = await databases.getDocument(
              "default",
              "conversations",
              elt.conversation_id
            );

            if (searchConvResp) {
              list.push(searchConvResp);
            }
          }
        }
        // Eliminer les doublons
        response = Array.from(
          new Map(list.map((item) => [item.$id, item])).values()
        );
      }
      console.log({ responseGlobal: response });
    } else {
      const res = await databases.listDocuments(
        "default",
        "conversations",
        queries
      );
      console.log({ getConversations: res });
      response = res.documents;
    }
    return response;
  } catch (error) {
    console.log({ getConversations: error });
    return undefined;
  }
};

export const getTotalConversations = async (origin, filter, search) => {
  const client = new Client();
  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  const databases = new Databases(client, "default");

  const queries = [
    Query.equal("company_id", localStorage.getItem("companyId"))
  ];

  if (
    origin === "starred" ||
    origin === "archive" ||
    origin === "draft" ||
    origin === "junk"
  ) {
    queries.push(Query.contains("tags", origin));
  } else if (origin === "sent") {
    queries.push(Query.equal("direction", "sent"));
  } else if (origin) {
    queries.push(Query.equal("origin", origin));
  }

  if (filter && filter === "treated") {
    queries.push(Query.equal("process_state", "Treated"));
  } else if (filter && filter === "untreated") {
    queries.push(Query.equal("process_state", "Untreated"));
  } else if (filter && search) {
    queries.push(
      Query.or([
        Query.search("last_message", filter),
        Query.search("user_name", filter)
      ])
    );
  }

  try {
    const response = await databases.listDocuments(
      "default",
      "conversations",
      queries
    );
    console.log({ getTotalConversations: response });
    return response.total;
  } catch (error) {
    console.log({ getTotalConversationsError: error });
    return undefined;
  }
};

export const getMessagesApi = async (conversation_id) => {
  const client = new Client();
  const databases = new Databases(client, "default");

  try {
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const response = await databases.listDocuments("default", "message", [
      Query.equal("company", localStorage.getItem("companyId")),
      Query.equal("conversation_id", conversation_id),
      Query.orderAsc("$createdAt"),
      Query.limit(100)
    ]);
    console.log({ getMessagesApi: response });
    return response.documents;
  } catch (error) {
    console.log({ getMessagesApi: error });
    return undefined;
  }
};

export const retreatedMessageApi = async (data) => {
  console.log({ retreatedMessage: data });
  try {
    const response = await axios.post(
      `${meta_server_url}/webhook-try-again`,
      //  `https://436a-102-215-139-114.ngrok-free.app/webhook-try-again`,
      data
    );
    console.log({ retreatedMessage: response });
    if (response) {
      return response;
    } else {
      return undefined;
    }
  } catch (error) {
    console.log({ retreatedMessageError: error });
    return undefined;
  }
};

// export async function encryptPassword(data) {
//   const client = new Client();

//   const functions = new Functions(client);

//   client.setEndpoint("https://smartas-srv.semecloud.tech/v1").setProject("maaia");

//   try {
//     const str_response = await functions.createExecution(
//       "function_box_manager_api_py",
//       JSON.stringify(data),
//       false,
//       "/get-encrypt",
//       ExecutionMethod.GET
//     );

//     if (str_response && str_response.responseBody.includes("failed")) {
//       return "";
//     } else {
//       return str_response.responseBody;
//     }
//   } catch (error) {
//     console.log("Error getWhoisFunctionExecution", error);
//     return "";
//   }
// }

// export async function decryptPassword(data) {
//   const client = new Client();

//   const functions = new Functions(client);

//   client.setEndpoint("https://smartas-srv.semecloud.tech/v1").setProject("maaia");

//   try {
//     const str_response = await functions.createExecution(
//       "function_box_manager_api_py",
//       JSON.stringify(data),
//       false,
//       "/get-decrypt",
//       ExecutionMethod.GET
//     );

//     console.log({ encryptPassword: str_response });

//     if (str_response && str_response.responseBody.includes("failed")) {
//       return "";
//     } else {
//       return str_response.responseBody;
//     }
//   } catch (error) {
//     console.log("Error getWhoisFunctionExecution", error);
//     return "";
//   }
// }

export async function getMailsDatas(messageInfos, message) {
  const message_infos_data = messageInfos;

  const the_message_infos_data = await getTheMessagesInfos(
    message_infos_data,
    message
  );
  console.log({ message_infos_data });

  return the_message_infos_data;
}

export async function getTheMessagesInfos(message_infos_data, message) {
  let all_r_msg = [...(message.related_messages ?? [])];
  all_r_msg.push(message);

  for (const msg of all_r_msg) {
    let messageId = "";

    if (typeof msg.headers["message-id"] === "string") {
      messageId = msg.headers["message-id"];
    } else if (Array.isArray(msg.headers["message-id"])) {
      messageId = msg.headers["message-id"][0];
    }

    if (!message_infos_data[messageId]) {
      // console.log("Fetching info for messageId", messageId);
      // console.log("going to setMessageInfos", messageId, message_infos_data[messageId]);
      // console.log({ the_messageInfos: message_infos_data, the_id: messageId, the_check: message_infos_data[`${messageId}`] });

      console.log("Before update:", messageId, message_infos_data);
      const info = await getMessageInfos(messageId);
      console.log("Fetched info for messageId", messageId, info);
      message_infos_data[messageId] = info ?? {};
      // console.log("After update:", messageId, message_infos_data);

      // console.log("Updated message_infos_data", messageId, message_infos_data[messageId], message_infos_data);
    } else {
      // console.log("Already fetched", messageId, message_infos_data[messageId], message_infos_data);
    }
  }
  return message_infos_data;
}

async function getMessageInfos(messageID) {
  const client = new Client();
  const databases = new Databases(client, "default");
  client
    .setEndpoint(process.env.REACT_APP_DATABASEURL)
    .setProject(process.env.REACT_APP_PROJECTID);

  try {
    // console.log("Fetching message info for " + messageID);
    const response = await databases.listDocuments("default", "message", [
      Query.equal("company", localStorage.getItem("companyId")),
      Query.equal("conversation_id", messageID)
    ]);
    // console.log("response.documents.length is ", response.documents.length);
    return response.documents.length > 0 ? response.documents[0] : undefined;
  } catch (error) {
    console.log(error); // Echec
    console.log("Failed to fetch message info:", error);
    return undefined;
  }
}

export async function fetchFBGetSubscribedApps(page_id, page_access_token) {
  try {
    const response = await axios.get(
      `${meta_server_url}/facebook-get-subscribed-apps`,
      {
        params: {
          page_id,
          page_access_token
        }
      }
    );
    console.log({ fetchFBGetSubscribedApps: response });
    return response;
  } catch (error) {
    console.log("Error fetchFBGetSubscribedApps", {
      fetchFBGetSubscribedApps: error
    });
    return null;
  }
}

export {
  APIClient,
  setAuthorization,
  getLoggedinUser,
  createEmailAccount,
  updateUserPrefs,
  updateUserPrefsPromise,
  logoutUserSession,
  createEmailSession,
  createEmailSessionPromise,
  createStatisticsFunction,
  createEmployeeFunction,
  isUserAuthenticated,
  getUser,
  getUserPromise,
  createLog,
  getEmployeeLink,
  fetchMailsList,
  fetchSingleMail,
  fetchSearchedMails,
  getUserPrefs,
  getClientData,
  getCompanyInfosPromise,
  getCompanyInfos as getUserCompanyInfos,
  fetchFoldersList,
  copyMailPromise,
  moveMailPromise,
  deleteMailPromise,
  sendMailPromise,
  createBoxPromise,
  translateWithDeepLPromise,
  createFeedBack,
  shopifyOauth,
  updateCompanyTokenUsage,
  createPwRecovery,
  fetchStatistics,
  searchShopifyOrder,
  createAIResponseExecution,
  createAiTextImprovementFunctionExecution,
  createAgentFunctionExecution,
  createAllMaaiaBoxes,
  getStripeSubscriptionPromise,
  getWhoisFunctionExecution,
  createAllMaaiaBoxesDirect,
  sendMailDirect,
  updateAgentFunctionExecution,
  launchServer
};
