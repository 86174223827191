import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../withRouter";

// users
import { getClientData } from "../../../helpers/api_helper";
import UserOutlined from "../../../assets/images/icons/UserOutlined";
import LockOutlined from "../../../assets/images/icons/LockOutlined";
import UsersOutlined from "../../../assets/images/icons/UsersOutlined";
import FileTextOutlined from "../../../assets/images/icons/FileTextOutlined";
import RefreshCcwOutlined from "../../../assets/images/icons/RefreshCcwOutlined";
import PowerOutlined from "../../../assets/images/icons/PowerOutlined";
import { emptyMailBox } from "../../../store/actions";
import CustomToast from "../customToast/CustomToast";

const ProfileMenu = (props) => {
  // console.log({ props });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [mail, setMail] = useState(undefined);
  const [boxDropdown, setBoxDropdown] = useState(false);
  const [company_info, setCompanyInfo] = useState(undefined);
  const { client_data } = useSelector((state) => {
    return {
      client_data: state.GeneralStateRedux.client_data
    };
  });
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);

  const getAvatar = () => {
    if (props.user_infos) {
      if (
        props.user_infos.prefs &&
        props.user_infos.prefs["first_name"] &&
        props.user_infos.prefs["first_name"] !== ""
      ) {
        return `${props.user_infos.prefs["first_name"]}${props.user_infos.prefs["last_name"]}`
          .charAt(0)
          .toUpperCase();
      } else {
        return props.user_infos.email.charAt(0).toUpperCase();
      }
    }
  };
  const getFullName = () => {
    let full_name = "";
    if (props.user_infos) {
      console.log({ user_infos: props.user_infos });
      if (
        props.user_infos.prefs &&
        props.user_infos.prefs["first_name"] &&
        props.user_infos.prefs["first_name"] !== ""
      ) {
        full_name = props.user_infos.prefs["first_name"];
      }
      if (
        props.user_infos.prefs &&
        props.user_infos.prefs["last_name"] &&
        props.user_infos.prefs["last_name"] !== ""
      ) {
        full_name = full_name + " " + props.user_infos.prefs["last_name"];
      }
      if (full_name === "") {
        full_name = "";
      }
    } else {
      full_name = "";
    }
    return full_name;
  };

  const handleClick = (val, mod) => {
    // Remplacez cela par la valeur que vous souhaitez passer
    navigate(`/settings`, {
      replace: true,
      state: { tab: val, modal: mod ?? "" }
    });
  };

  useEffect(() => {
    handleUserCompanyInfo();
  }, [props.success, props.user_infos, navigate]);

  const handleUserCompanyInfo = async () => {
    const userId = localStorage.getItem("companyId");
    // console.log({ userId });
    if (userId) {
      const companyInfo = await getClientData();
      if (companyInfo && companyInfo.company && companyInfo.company !== "") {
        console.log({ company_user_infos: companyInfo });
        setCompanyInfo(companyInfo);
        setCompanyName(companyInfo.company);
      } else {
        setCompanyName(props.t("Projet"));
      }
    }
  };

  function setSelectedMail(mail) {
    localStorage.setItem("selected_mail_box", mail.email);
    dispatch(emptyMailBox());
    window.location.reload();
  }

  const handleCopy = (text) => {
    console.log(text);
    navigator.clipboard.writeText(text).then(() => {
      setShowNotification(true);

      // Masque la notification après 2 secondes
      setTimeout(() => {
        setShowNotification(false);
      }, 2000);
    });
  };

  const toggleToast = () => {
    console.log("setToast before: " + toast);
    setToast(true);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="user-dropdown d-inline-block"
      >
        <DropdownToggle
          className="p-0 bg-transparent border-0"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <img className="header-profile-user" src={user1} alt="Header Avatar" /> */}
          {/* <div
            className="d-flex align-items-center justify-content-center"
            style={{
              fontSize: '120%',
              backgroundColor: 'grey',
              color: 'white',
              padding: '0',
              borderRadius: '13.511px',
              textAlign: 'center',
              width: '50.667px',
              height: '50.667px',
            }}>
            {(props.user_infos
              ? props.user_infos.prefs && props.user_infos.prefs['first_name'] && props.user_infos.prefs['first_name'] !== "" ? props.user_infos.prefs['first_name']
                ? `${props.user_infos.prefs['first_name']} ${props.user_infos.prefs['last_name']}`
                : 'Admin'
              : ''
            )
              .split(' ')
              .map((part) => part.charAt(0).toUpperCase())
              .join('')}
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "20px"
            }}
          >
            <div
              className="col-auto d-flex align-items-center justify-content-center font-primary"
              style={{
                fontSize: "130%",
                fontWeight: "bold",
                backgroundColor: "#6C67FF",
                color: "white",
                padding: "0",
                borderRadius: "50%",
                textAlign: "center",
                width: "40px",
                height: "40px",
                flexShrink: "0"
              }}
            >
              {getAvatar()}
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-end dropdown-menu-md page-header-user-dropdown_menu font-primary"
          style={{ width: "245px" }}
        >
          <div className="d-flex" style={{ marginLeft: "25px" }}>
            <div className="col-auto p-0 d-flex flex-column" style={{}}>
              <p
                className="mb-1 font-size-13 font-primary"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "170px",
                  color: "black"
                }}
              >
                {getFullName()}
              </p>
              <span
                className="font-size-12 font-primary"
                style={{
                  maxWidth: "200px"
                }}
              >
                {client_data &&
                client_data.mailBox &&
                client_data.mailBox.length > 0 ? (
                  <Dropdown
                    isOpen={boxDropdown}
                    toggle={() => setBoxDropdown(!boxDropdown)}
                  >
                    <DropdownToggle
                      tag="button"
                      className="form-control py-1"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "190px",
                        textTransform: "lowercase",
                        color: "#563bff"
                      }}
                    >
                      {localStorage.getItem("selected_mail_box")
                        ? localStorage.getItem("selected_mail_box")
                        : client_data.mailBox[0].email}
                      <i className="ms-2 mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-lg-end">
                      {client_data &&
                        client_data.mailBox.map((mail, index) => (
                          <DropdownItem
                            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary font-size-12"
                            onClick={() => {
                              setSelectedMail(mail);
                            }}
                          >
                            {mail.email}
                          </DropdownItem>
                        ))}
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  props.user && props.user_infos.email
                )}
              </span>
            </div>
          </div>

          <DropdownItem disabled className="pb-0 font-primary">
            {props.t("Account")}
          </DropdownItem>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("profile");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-user-line align-middle me-2" /> */}
            <UserOutlined height="20" width="20" className="me-2" />
            {props.t("Profile")}
          </DropdownItem>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("profile", "password");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-lock-password-line align-middle me-2" /> */}
            <LockOutlined height="20" width="20" className="me-2" />
            {props.t("Mot de passe")}
          </DropdownItem>
          <div className="mt-2"></div>
          <DropdownItem disabled className="pb-0 font-primary">
            {props.t("Company")}
          </DropdownItem>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("users");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-group-line align-middle me-2" /> */}
            <UsersOutlined height="20" width="20" className="me-2" />
            {props.t("Users")}
          </DropdownItem>
          <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("billing");
            }}
            style={{ color: "#091b3d" }}
          >
            {/* <i className="ri-file-text-line align-middle me-2" /> */}
            <FileTextOutlined height="20" width="20" className="me-2" />
            {props.t("Billing")}
          </DropdownItem>
          <div className="mt-2"></div>
          {/* <DropdownItem
            className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
            tag="button"
            onClick={() => {
              handleClick("");
            }}
            style={{ color: "#091b3d" }}
          >
            <RefreshCcwOutlined
              height="20"
              width="20"
              className="me-2"
            />
            {props.t("Switch company")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="#">
            <i className="ri-wallet-2-line align-middle me-2" />
            {props.t('My Wallet')}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end mt-1">11</span>
            <i className="ri-settings-2-line align-middle me-2" />
            {props.t('Settings')}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="ri-lock-unlock-line align-middle me-2" />
            {props.t('Lock screen')}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <DropdownItem disabled className="pb-0 font-primary">
            {props.t("ID Synibox")}
          </DropdownItem>
          {client_data && (
            <div
              className="dropdown-item-py-1 dropdown-item-mb-1 font-primary"
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                overflowWrap: "break-word",
                display: "inline-flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
                padding: "5px 10px",
                borderRadius: "12px",
                color: "gray",
                backgroundColor: "#f0f0f0",
                border: "1px solid #d1d1d1",
                fontSize: "14px",
                fontWeight: "500",
                margin: "5px 20px 0px 20px"
              }}
              onClick={() => handleCopy(client_data.$id)}
            >
              {`${client_data.$id}`}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
              </svg>
            </div>
          )}

          {showNotification && (
            <div
              style={{
                position: "absolute",
                top: "200px",
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "black",
                color: "white",
                padding: "5px 10px",
                borderRadius: "10px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
              }}
            >
              {props.t("Copié")}
            </div>
          )}

          <div className="mt-3"></div>

          <Link
            to="/logout"
            className="dropdown-item dropdown-item-py-1 dropdown-item-mb-1 text-danger"
          >
            {/* <i className="ri-shut-down-line align-middle me-2" /> */}
            <PowerOutlined height="20" width="20" className="me-2" />
            <span style={{ fontSize: "14px" }}>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <div
        className="position-fixed top-50 end-0 p-3"
        // style={{ zIndex: "9999999" }}
      >
        <CustomToast
          isOpen={toast}
          toggle={toggleToast}
          message={toastMessage}
        />
      </div>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
