import React, { useState, useEffect } from "react";
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
  LinkAuthenticationElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  createStripeCheckoutSession,
  createStripePaymentEntent,
  stripe_payment_return_url,
  prestashop_payment_return_url,
  createStripeCustomer,
  synibox_dashboard_url,
  getStripeSubscription,
  createStripeSubscripion as createStripeSubscription,
  applyStripeDiscountCode,
  updateStripeSubscripion,
  retrieveStripeUpComingInvoice,
  getStripeProduct,
  SMARTAS,
  createStripeSetupIntent,
  updateStripeCustomer,
  getClientByEmail,
  checkSubcriptionUpdateType,
  createLog,
  createEmailAccount,
  createEmailSessionPromise,
  updateUserPrefsPromise,
  createStatisticsFunction,
  logoutUserSession,
  createClientInfo,
  getUserCompanyInfos,
  klaviyoSubscribeEmail,
  klaviyoUpdateProfile,
  formatDate,
  formatDateMillisec,
  getEmailSession
} from "../../helpers/api_helper";
import logo_maaia from "../../assets/images/icons-infos-purple.png";
import {
  Col,
  CardBody,
  Row,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Toast,
  ToastHeader,
  ToastBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
  Modal,
  InputGroup,
  InputGroupText,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
  Alert,
  Label,
  FormFeedback
} from "reactstrap";
import "./PaymentForm.scss";
import InfoMono from "../../assets/images/icons/InfoMono";
import PlusOutlined from "../../assets/images/icons/PlusOutlined";
import ArrowLeftOutlined from "../../assets/images/icons/ArrowLeftOutlined";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import { Client, Account, Databases, ID, Query } from "appwrite";
import { withTranslation } from "react-i18next";
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";
import Cookies from "js-cookie";
import { useMixpanel } from "../../Hooks/useMixpanel";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { logoutUser } from "../../store/actions";
import { useSelector } from "react-redux";

/*
const PaymentForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState({
    country: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (!elements) return;

    const paymentElement = elements.getElement("address");

    const handleChange = (event) => {
      if (event.complete) {
        console.log("Payment Element is complete.");
      }
    };

    if (paymentElement) {
      paymentElement.on("onChange", handleChange);
    }

    return () => {
      if (paymentElement) {
        paymentElement.off("onChange", handleChange);
      }
    };
  }, [elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://your-website.com/order/complete",
      },
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
      console.log("Payment confirmed successfully");
      console.log("Country selected:", formData.country);
    }
  };

  const handleChange = (event) => {
    console.log({ change_event: event });

    if (event.complete) {
    }
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      style={{
        border: "1px solid #F6F9FC",
        borderRadius: "3px",
        padding: "20px",
        margin: "20px 0",
        boxShadow: "0 30px 50px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3)",
      }}>
      <LinkAuthenticationElement id="link-authentication-element" />
      <PaymentElement id="payment-element" />
      <AddressElement options={{ mode: "billing" }} onChange={handleChange} />
      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        style={{
          backgroundColor: "#635BFF",
          borderRadius: "3px",
          color: "white",
          border: "0",
          padding: "12px 16px",
          marginTop: "16px",
          fontWeight: "600",
          cursor: "pointer",
          transition: "all 0.2s ease",
          display: "block",
        }}>
        <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}</span>
      </button>
      {message && (
        <div id="payment-message" style={{ margin: "10px 0" }}>
          {message}
        </div>
      )}
    </form>
  );
};
*/

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  // const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState(null);
  // const [country, setCountry] = useState(null);
  const [country, setCountry] = useState(null);
  const [decompte, setDecompte] = useState(15);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (decompte === 0) {
      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
  }, [decompte]);

  useEffect(() => {
    if (redirect) {
      let interval;
      interval = setInterval(() => {
        setDecompte((prevDecompte) => prevDecompte - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [redirect]);

  const navigate = useNavigate();

  const { mixPanelTrack } = useMixpanel();

  async function updateClientInfos(data) {
    const client = new Client();

    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "client_infos",
      props.company_id,
      JSON.stringify(data)
    );

    return promise.then(
      async function (response) {
        console.log("updateCompanyInfos success", response); // Success
        return response;
      },
      function (error) {
        console.log("updateCompanyInfos error", error); // Failure
        return null;
      }
    );
  }
  const handleError = (error) => {
    setIsLoading(false);
    setErrorMessage(error.message);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: stripe_payment_return_url
      },
      redirect: "if_required"
    });

    if (error) {
      handleError(error);
      return;
    }

    await updateClientInfos({ stripe_region: country });

    // Créer le log
    const company_id = props.company_id;
    var user_id = JSON.parse(localStorage.getItem("authUser"))?.userId;

    const updates = {
      properties: {
        plan: SMARTAS.PLAN_NAME[props.plan_item_id],
        plan_activation_date: formatDateMillisec(Date.now()),
        trial_period: false,
        on_pause: false,
        stripe_region: country === "US" ? "US" : "WORLD",
        checkout_country: country
      }
    };
    klaviyoUpdateProfile(company_id, updates);

    let logData = {
      eventCreatedAt: Date.now(),
      author_id: user_id || "Guest",
      plan: SMARTAS.PLAN_NAME[props.plan_item_id],
      company_id: company_id
    };
    let logdata = {
      event: "subscription_created",
      author_id: user_id,
      company_id: company_id,
      log_data: JSON.stringify(logData)
    };
    createLog(logdata, mixPanelTrack);
    setIsLoading(false);
    setRedirect(true);

    if (error) {
      setErrorMessage(error.message);
    } else {
      console.log("Payment confirmed successfully");
    }
  };
  console.log({ the_passed_region: props.region });

  // const defaultAdd = props.region === "US" ? { name: props.customer_fullName, address: { country: "US" } } : { name: props.customer_fullName };
  const defaultAdd =
    props.region === "US"
      ? { name: props.customer_fullName, address: { country: "US" } }
      : {
          name: props.customer_fullName,
          address: { country: props.region || "FR" }
        };

  const handleAddressChange = (event) => {
    let the_country;
    console.log("Address changed:", event);
    if (event.elementType === "address" && event.elementMode === "billing") {
      the_country = event.value.address.country;
      const fullName = event.value.name;
      console.log("Address changed: country is", the_country);
      console.log("Name is", event.value.name);
      props.setCustomerFullName(fullName);
    }

    //   if (the_country && the_country === "US") {
    // if (the_country !== props.region) {
    if (
      (the_country && the_country === "US" && props.region !== "US") ||
      (props.region === "US" && the_country && the_country !== "US")
    ) {
      // if (the_country !== props.region ) {
      console.log("Need refresh");
      props.refreshStripePaymentIntent(the_country);
      //  }
    } else {
      console.log("No need refresh", the_country);
    }
    setCountry(the_country);

    // Gérer les changements d'adresse ici
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="payment-form d-flex"
      style={{ height: "100%" }}
    >
      <div className="my-auto w-100 py-4">
        {redirect && (
          <Alert className="text-center" color="success">
            {`${props.t("Opération réussie !")} ${props.t(
              "Vous serez redirigé dans"
            )} ${decompte}sec...`}
          </Alert>
        )}
        <div className="form-group">
          <AddressElement
            options={{
              mode: "billing",
              // fields: {
              //   phone: "never",
              // },
              // validation: {
              //   phone: {
              //     required: "never",
              //   },
              // },
              defaultValues: defaultAdd
            }}
            onChange={handleAddressChange}
          />
        </div>

        <div className="form-group">
          <PaymentElement
            options={{
              layout: {
                type: "accordion",
                defaultCollapsed: false,
                radios: false,
                spacedAccordionItems: false
              }
            }}
          />
        </div>
        {errorMessage &&
          errorMessage !== props.t("Ce champ est incomplet.") &&
          !redirect && (
            <Alert color="danger" className="font-primary font-size-12 mb-3">
              {props.t(errorMessage)}
            </Alert>
          )}
        <button
          type="submit"
          disabled={isLoading || !stripe || !elements || props.isRefreshing}
          className="r_button"
          id="submit"
          // style={{
          //   backgroundColor: "#635BFF",
          //   borderRadius: "3px",
          //   color: "white",
          //   border: "0",
          //   padding: "12px 16px",
          //   marginTop: "16px",
          //   fontWeight: "600",
          //   cursor: "pointer",
          //   transition: "all 0.2s ease",
          //   display: "block",
          // }}
        >
          <span id="button-text">
            {isLoading || props.isRefreshing ? (
              <span
                className="spinner-border spinner-border-sm me-2 font-primary"
                role="status"
                aria-hidden="true"
              />
            ) : (
              props.t("Pay now")
            )}
          </span>
        </button>
      </div>

      {message && (
        <div id="payment-message" style={{ margin: "10px 0" }}>
          {message}
        </div>
      )}
    </form>
  );
};

const PaymentTrialForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [country, setCountry] = useState(null);
  const [tidCookie, setFPTidCookie] = useState(null);
  const [decompte, setDecompte] = useState(15);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (decompte === 0) {
      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
  }, [decompte]);

  useEffect(() => {
    if (redirect) {
      let interval;
      interval = setInterval(() => {
        setDecompte((prevDecompte) => prevDecompte - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [redirect]);

  const { mixPanelTrack } = useMixpanel();

  async function updateClientInfos(data) {
    const client = new Client();

    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "client_infos",
      props.company_id,
      JSON.stringify(data)
    );

    return promise.then(
      async function (response) {
        console.log("updateCompanyInfos success", response); // Success
        return response;
      },
      function (error) {
        console.log("updateCompanyInfos error", error); // Failure
        return null;
      }
    );
  }

  const navigate = useNavigate();
  useEffect(() => {
    // const fpromref = Cookies.get("_fprom_ref");
    const fpromtid = Cookies.get("_fprom_tid");
    setFPTidCookie(fpromtid);
  }, []);

  const handleError = (error) => {
    setIsLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    const res = await createStripeSetupIntent(
      props.region,
      props.stripeCustomerID
    );

    if (!res) {
      setErrorMessage("system_error");
      setIsLoading(false);
      return;
    }
    const { client_secret: clientSecret } = res;

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: stripe_payment_return_url
      },
      redirect: "if_required"
    });

    if (error) {
      handleError(error);
    } else {
      console.log("Create Intent Successfully");

      // Récupérer l'ID de la méthode de paiement enregistrée
      const paymentMethodId = setupIntent.payment_method; // ID de la méthode de paiement enregistrée (ex : pm_xxx)
      console.log("Payment Method ID:", paymentMethodId);

      const res = await updateStripeCustomer(
        props.region,
        props.stripeCustomerID,
        {
          invoice_settings: {
            default_payment_method: paymentMethodId
          }
        }
      );

      if (!res) {
        setErrorMessage("system_error");
        setIsLoading(false);
        return;
      }

      const items = [
        {
          price: props.plan_item_id,
          quantity: 1
        }
      ];
      const metadata = {
        company_id: props.company_id,
        user_id: props.user_id,
        email: props.validation_1.values.email,
        name: props.customer_fullName,
        stripe_region: props.region,
        is_synibox_main_plan: true,
        trial: true,
        fp_tid: tidCookie
      };
      const response = await createStripeSubscription(
        props.region,
        stripe_payment_return_url,
        items,
        props.stripeCustomerID,
        metadata,
        props.coupon_id,
        3,
        clientSecret
      );
      if (response) {
        console.log("Subscription created successfully", response);

        await updateClientInfos({ stripe_region: country });

        // Créer le log
        const company_id = props.company_id || props.user_id;

        const updates = {
          properties: {
            plan: SMARTAS.PLAN_NAME[props.plan_item_id],
            plan_activation_date: formatDateMillisec(Date.now()),
            trial_period: true,
            on_pause: false,
            stripe_region: country
          }
        };
        klaviyoUpdateProfile(company_id, updates);

        var user_id = props.user_id;
        let logData = {
          eventCreatedAt: Date.now(),
          author_id: user_id || "Guest",
          company_id: company_id,
          plan: SMARTAS.PLAN_NAME[props.plan_item_id]
        };
        let logdata = {
          event: "subscription_trial_created",
          author_id: user_id,
          company_id: company_id,
          log_data: JSON.stringify(logData)
        };
        createLog(logdata, mixPanelTrack);
        setRedirect(true);
      } else {
        setErrorMessage("system_error");
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
    }
  };

  console.log({ the_passed_region: props.region });

  const defaultAdd =
    props.region === "US"
      ? { name: props.customer_fullName, address: { country: "US" } }
      : {
          name: props.customer_fullName,
          address: { country: props.region || "FR" }
        };

  const handleAddressChange = (event) => {
    let the_country;
    console.log("Address changed:", event);
    if (event.elementType === "address" && event.elementMode === "billing") {
      the_country = event.value.address.country;
      const fullName = event.value.name;
      console.log("Address changed: country is", the_country);
      console.log("Name is", event.value.name);
      props.setCustomerFullName(fullName);
    }

    if (
      (the_country && the_country === "US" && props.region !== "US") ||
      (props.region === "US" && the_country && the_country !== "US")
    ) {
      console.log("Need refresh");
      props.refreshStripePaymentIntent(the_country);
    } else {
      console.log("No need refresh", the_country);
    }
    setCountry(the_country);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="payment-form d-flex content_col_width"
      style={{ height: "100%" }}
    >
      <div className="my-auto w-100 py-4">
        <div className="form-group">
          <AddressElement
            options={{
              mode: "billing",
              defaultValues: defaultAdd
            }}
            onChange={handleAddressChange}
          />
        </div>
        <div className="form-group">
          <PaymentElement
            options={{
              layout: {
                type: "accordion",
                defaultCollapsed: false,
                radios: false,
                spacedAccordionItems: false
              }
            }}
          />
        </div>
        {redirect && (
          <Alert className="text-center" color="success">
            {`${props.t("Opération réussie !")} ${props.t(
              "Vous serez redirigé dans"
            )} ${decompte}sec...`}
          </Alert>
        )}
        {errorMessage &&
          errorMessage !== props.t("Ce champ est incomplet.") &&
          !redirect && (
            <Alert color="danger" className="font-primary font-size-12 mb-3">
              {props.t(errorMessage)}
            </Alert>
          )}
        <button
          type="submit"
          disabled={isLoading || !stripe || !elements || props.isRefreshing}
          className="r_button"
          id="submit"
        >
          <span id="button-text">
            {isLoading || props.isRefreshing ? (
              <span
                className="spinner-border spinner-border-sm me-2 font-primary"
                role="status"
                aria-hidden="true"
              />
            ) : (
              props.t("Valider")
            )}
          </span>
        </button>
      </div>
      {message && (
        <div id="payment-message" style={{ margin: "10px 0" }}>
          {message}
        </div>
      )}
    </form>
  );
};

const StripeCheckout = (props) => {
  const [clientSecret, setClientSecret] = useState(undefined);
  // const [stripePromise, setLoadStripe] = useState(undefined);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [planDetails, setPlanDetails] = useState(null);
  const [subscriptionIntent, setSubscriptionIntent] = useState(null);
  const [stripeCustomerID, setStripeCustomerID] = useState(undefined);
  const [existingUserWithPlan, setExistingUserWithPlan] = useState(false);
  const [customer_email, setCustomerEmail] = useState(null);
  const [customer_fullName, setCustomerFullName] = useState(null);
  const [customerIsLoading, setCustomerIsLoading] = useState(null);
  const [client_data, setCompanyInfos] = useState(undefined);
  const [requireName, setRequireName] = useState(false);

  const [couponCode, setCouponCode] = useState("");
  const [coupon, setCoupon] = useState(undefined);
  const [couponRequestMessage, setCouponRequestMessage] = useState("");
  const [couponRequestLoading, setCouponRequestLoading] = useState(false);
  const [tidCookie, setFPTidCookie] = useState(null);

  const [showUpdateUI, setShowUpdateUI] = useState(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [user_id, set_user_id] = useState(undefined);

  const validation_1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Veuillez entrer votre Email")
        .email("Veuillez entrer un email valide")
    }),
    onSubmit: (values) => {
      console.log({ values });
      handleCheckCustomer(values.email);
    }
  });

  const validation_2 = useFormik({
    enableReinitialize: true,

    initialValues: {
      // full_name: "",
      first_name: "",
      last_name: "",
      password: ""
    },
    validationSchema: Yup.object({
      // full_name: Yup.string().required("Veuillez entrer votre nom complet"),
      first_name: Yup.string().required("Veuillez entrer votre prénom"),
      last_name: Yup.string().required("Veuillez entrer votre nom de famille"),
      password: Yup.string()
        .min(8, "Veuillez entrer un mot de passe d'au moins 8 charactères")
        .required("Veuillez entrer votre mot de passe")
    }),
    onSubmit: (values) => {
      console.log({ values });
      setCustomerIsLoading(true);
      registerUser(
        validation_1.values.email,
        validation_2.values.password,
        validation_2.values.first_name,
        validation_2.values.last_name
      ).then((values) => {
        handleCreateCustomer(
          validation_1.values.email,
          `${validation_2.values.first_name} ${validation_2.values.last_name}`,
          undefined,
          values
        );
      });
    }
  });

  const registerUser = async (email, password, first_name, last_name) => {
    try {
      const response = await createEmailAccount(
        email,
        password,
        `${first_name} ${last_name}`
      );
      klaviyoSubscribeEmail(response.$id, email, first_name, last_name);
      set_user_id(response.$id);

      const session = await createEmailSessionPromise(email, password);
      if (session) {
        const prefs_response = await updateUserPrefsPromise({
          owner: true,
          first_name,
          last_name
        });
        console.log("prefs_response is", prefs_response);

        let customer_data = {
          company: "",
          stripe_region: session.countryCode.toUpperCase(),
          direct_plan: undefined
        };

        const client_infos = await createClientInfo(
          response.$id,
          customer_data
        );
        console.log({ client_infos });

        await logoutUserSession();

        return {
          company_id: response.$id,
          region: session.countryCode.toUpperCase()
        };
      }
    } catch (error) {
      console.error(error.response.status, error.response.data);
    }
  };

  async function updateClientInfos(company_id, data) {
    const client = new Client();

    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "client_infos",
      company_id,
      JSON.stringify(data)
    );

    return promise.then(
      async function (response) {
        console.log("updateCompanyInfos success", response); // Success
        return response;
      },
      function (error) {
        console.log("updateCompanyInfos error", error); // Failure
        return null;
      }
    );
  }

  const svgs = {
    eye_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
      </svg>
    ),
    eye_slash_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
        />
      </svg>
    )
  };

  const togglePasswordVisibility = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };

  const handlePromoCodeSubmit = async (e) => {
    e.preventDefault();
    setCouponRequestLoading(true);

    try {
      const response = await applyStripeDiscountCode(region, couponCode);

      if (response.valid) {
        setCoupon(response);
        setCouponRequestMessage(
          `${props.t("Code promo appliqué ! Réduction")} : ${
            response.percent_off ? response.percent_off : response.amount_off
          }${response.percent_off ? "%" : "€"}`
        );
        fetchClientSecret(stripeCustomerID, client_data, response);
      } else {
        setCouponRequestMessage(props.t("Code promo invalide"));
      }
    } catch (error) {
      setCouponRequestMessage(
        props.t("Erreur lors de la validation du code promo")
      );
    }

    setCouponRequestLoading(false);
  };

  const handlePromoCodeTrialSubmit = async (e) => {
    e.preventDefault();
    setCouponRequestLoading(true);
    setPageLoading(true);
    try {
      const response = await applyStripeDiscountCode(region, couponCode);

      if (response.valid) {
        setCoupon(response);
        setCouponRequestMessage(
          `${props.t("Code promo appliqué ! Réduction")} : ${
            response.percent_off ? response.percent_off : response.amount_off
          }${response.percent_off ? "%" : "€"}`
        );
      } else {
        setCouponRequestMessage(props.t("Code promo invalide"));
      }
    } catch (error) {
      setCouponRequestMessage(
        props.t("Erreur lors de la validation du code promo")
      );
    }

    setPageLoading(false);
    setCouponRequestLoading(false);
  };

  //   const onLoadStripe = (val) => {
  //     setLoadStripe(val);
  //   };
  const navigate = useNavigate();
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  let region = urlParams.get("region") || undefined;
  const plan_id = urlParams.get("p");

  const getPriceId = (plan_id, region) => {
    let id;
    switch (plan_id) {
      case "starter":
        id = region === "US" ? SMARTAS.STARTER_US : SMARTAS.STARTER_WORLD;
        break;
      case "growth":
        id = region === "US" ? SMARTAS.GROWTH_US : SMARTAS.GROWTH_WORLD;
        break;
      case "business":
        id = region === "US" ? SMARTAS.BUSINESS_US : SMARTAS.BUSINESS_WORLD;
        break;
      case "pro":
        id = region === "US" ? SMARTAS.PRO_US : SMARTAS.PRO_WORLD;
        break;
      default:
        break;
    }
    return id;
  };

  let stripePromise;
  let plan_item_id;
  // if (state && state.region === "US") {
  if (region && region === "US") {
    console.log("Load strip for US");

    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_USA);
    plan_item_id = getPriceId(plan_id, region);
  } else {
    console.log("Load strip for World");
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK_WORLD);
    plan_item_id = getPriceId(plan_id, region);
  }

  const handleEmailChange = (email) => {
    console.log("Email changed: email is", email);
    setCustomerEmail(email);
  };

  const refreshStripePaymentIntent = async (region) => {
    console.log("start refreshStripePaymentIntent");

    if (region) {
      window.location.href = `${synibox_dashboard_url}/checkout?region=${region}&p=${plan_id}`;
    } else {
      window.location.href = `${synibox_dashboard_url}/checkout?p=${plan_id}`;
    }
  };

  const handleCreateCustomer = async (email, name, clientData, values) => {
    setCustomerIsLoading(true);
    console.log("Started creating customer");

    let customer_data;
    if (values && values.region) {
      region = values.region;

      if (region === "US") {
        customer_data = await createStripeCustomer(
          "US",
          email,
          name,
          tidCookie
        );
        console.log({ customer_data, region });
      } else {
        customer_data = await createStripeCustomer(
          undefined,
          email,
          name,
          tidCookie
        );
        console.log({ customer_data, region });
      }
    }

    if (customer_data) {
      const client_infos = await updateClientInfos(values.company_id, {
        stripe_customer_id: customer_data.customer.id
      });
      console.log({ client_infos });

      setStripeCustomerID(customer_data.customer.id);
      setCustomerFullName(name);
      fetchClientSecret(
        customer_data.customer.id,
        clientData ? clientData : client_data
      );
      setCustomerIsLoading(false);
      // refreshStripePaymentIntent(values.region);
      return customer_data.customer.id;
    }

    setCustomerIsLoading(false);
  };

  async function getCurrentSession() {
    try {
      const session = await getEmailSession();
      console.log("Session actuelle :", session);
      return session;
    } catch (error) {
      console.error("Erreur lors de la récupération de la session :", error);
      return null;
    }
  }

  const handleCreateDirectCustomer = async (email, name, client_data) => {
    setPageLoading(true);
    console.log("Started creating customer");

    const session = await getCurrentSession();
    if (!session) {
      setPageLoading(false);
      return;
    }
    let customer_data;

    region = session.countryCode.toUpperCase();

    if (region === "US") {
      customer_data = await createStripeCustomer("US", email, name, tidCookie);
      console.log({ customer_data, region });
    } else {
      customer_data = await createStripeCustomer(
        undefined,
        email,
        name,
        tidCookie
      );
      console.log({ customer_data, region });
    }

    if (customer_data) {
      const client_infos = await updateClientInfos(client_data.$id, {
        stripe_customer_id: customer_data.customer.id,
        stripe_region: region
      });
      console.log({ client_infos });

      setStripeCustomerID(customer_data.customer.id);
      setCustomerFullName(name);
      fetchClientSecret(customer_data.customer.id, client_data);
    }

    setPageLoading(false);
  };

  const handleCheckCustomer = async (email) => {
    setCustomerIsLoading(true);
    console.log("Started searching customer");

    const response = await getClientByEmail(email);

    if (!response) {
      setCustomerIsLoading(false);
      return false;
    }

    if (response.result.total > 0) {
      const user = response.result.users[0];
      console.log({ user });

      if (user["prefs"].owner === "true" || user["prefs"].owner === true) {
        const client_data = await getUserCompanyInfos(user.$id);
        console.log({ client_data });

        if (
          client_data.stripe_customer_id &&
          !client_data.plan_id &&
          !client_data.has_consumed_trial
        ) {
          if (
            client_data.stripe_region &&
            client_data.stripe_region === "US" &&
            region !== "US"
          ) {
            console.log("Need refresh");
            refreshStripePaymentIntent(client_data.stripe_region);
          } else {
            console.log("No need refresh", client_data.stripe_region);
          }
          setCustomerFullName(user.name);
          setStripeCustomerID(client_data.stripe_customer_id);
          set_user_id(user.$id);
        } else {
          console.log("okkkk 3");

          setExistingUserWithPlan(true);
          // navigate(`/settings`, {
          //   replace: true,
          //   state: { tab: "billing", modal: "" }
          // });
        }
      }
    } else {
      setCustomerIsLoading(false);
      setRequireName(true);
    }

    // else if (response) {
    //   const client_data = response.documents[0];

    //   if (client_data) {
    //     console.log({ client_data });
    //     setCompanyInfos(client_data);

    //     const stripe_customer_id = client_data.stripe_customer_id;
    //     console.log({ stripe_customer_id });

    //     if (stripe_customer_id) {
    //       setStripeCustomerID(stripe_customer_id);

    //       const subscription_plan = await getStripeSubscription(client_data.plan_id, client_data.stripe_region, true, true);
    //       console.log({ subscription_plan });
    //       if (subscription_plan && subscription_plan.trialing) {
    //         window.location.href = "/dashboard";
    //       }

    //       if (subscription_plan && plan_item_id !== subscription_plan.price_id) {
    //         setShowUpdateUI(subscription_plan);
    //       } else if (subscription_plan && plan_item_id === subscription_plan.price_id) {
    //         window.location.href = "/dashboard";
    //       } else {
    //         if (client_data.has_consumed_trial) {
    //           await fetchClientSecret(stripe_customer_id, client_data);
    //           region = client_data.stripe_region;
    //         }
    //       }

    //       setCustomerIsLoading(false);
    //     } else {
    //       setCustomerIsLoading(false);
    //       setRequireName(true);
    //     }
    //   } else {
    //     setCustomerIsLoading(false);
    //     setRequireName(true);
    //   }
    // }
  };

  const fetchClientSecret = async (
    the_stripe_customer_id,
    the_client_data,
    coupon
  ) => {
    console.log({ coupon });

    setPageLoading(true);

    const response = await createStripeSubscription(
      region,
      stripe_payment_return_url,
      [
        {
          price: plan_item_id,
          quantity: 1
        }
      ],
      the_stripe_customer_id,
      {
        company_id: the_client_data ? the_client_data.$id : undefined,
        trial:
          the_client_data && the_client_data.direct_plan ? true : undefined,
        email: customer_email,
        name: customer_fullName,
        stripe_region: region,
        is_synibox_main_plan: true
      },
      coupon ? coupon.id : undefined
    );

    setPageLoading(false);

    if (response) {
      const subscription = response.subscription;
      console.log({ the_subscription: subscription.latest_invoice.lines.data });
      const plan = subscription.latest_invoice.lines.data.find(
        (line) => line.price.id === plan_item_id
      );
      console.log({ plan });
      setSubscriptionIntent(subscription);

      setPlanDetails(plan);

      setClientSecret(response.clientSecret);
    }
  };

  useEffect(() => {
    initCustomer();
  }, []);

  useEffect(() => {
    // const fpromref = Cookies.get("_fprom_ref");
    const fpromtid = Cookies.get("_fprom_tid");
    setFPTidCookie(fpromtid);
  }, []);

  const initCustomer = async () => {
    setCustomerIsLoading(true);
    setPageLoading(true);
    const user_string = localStorage.getItem("user");
    const user = user_string ? JSON.parse(user_string) : undefined;

    if (
      user_string &&
      user &&
      user.prefs &&
      user.prefs["owner"] &&
      (user.prefs["owner"] === "true" || user.prefs["owner"] === true)
    ) {
      const the_client_data = await getClientData();

      if (!the_client_data) {
        console.log("No company infos");

        return;
      }

      setCompanyInfos(the_client_data);

      console.log({ synibox_user_name: user });
      handleEmailChange(user.email);
      setCustomerFullName(
        [user.prefs["first_name"], user.prefs["last_name"]]
          .filter(Boolean)
          .join(" ")
      );
      const stripe_customer_id = the_client_data.stripe_customer_id;
      if (stripe_customer_id) {
        console.log({ stripe_customer_id });

        setStripeCustomerID(stripe_customer_id);

        const subscription_plan = await getStripeSubscription(
          the_client_data.plan_id,
          the_client_data.stripe_region,
          true,
          true
        );
        console.log({ subscription_plan });

        if (subscription_plan && plan_item_id !== subscription_plan.price_id) {
          setShowUpdateUI(subscription_plan);
        } else if (
          subscription_plan &&
          plan_item_id === subscription_plan.price_id
        ) {
          console.log("okkkk 4");

          navigate(`/settings`, {
            replace: true,
            state: { tab: "billing", modal: "" }
          });
        } else {
          if (
            the_client_data.has_consumed_trial ||
            the_client_data.direct_plan
          ) {
            await fetchClientSecret(stripe_customer_id, the_client_data);
            region = the_client_data.stripe_region;
          }
        }
      } else if (the_client_data.direct_plan) {
        await handleCreateDirectCustomer(
          user.email,
          [user.prefs["first_name"], user.prefs["last_name"]]
            .filter(Boolean)
            .join(" "),
          the_client_data
        );
      }
    }
    setPageLoading(false);
    setCustomerIsLoading(false);
  };

  const onCompanyInfoChanged = (val) => {
    setCompanyInfos(val);
  };

  const getClientData = async function () {
    const client = new Client();
    const databases = new Databases(client, "default");

    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    try {
      const response = await databases.getDocument(
        "default",
        "client_infos",
        localStorage.getItem("companyId")
      );
      console.log("getClientData", response);
      onCompanyInfoChanged(response);
      return response;
    } catch (error) {
      console.log(error); // Failure
      return null;
    }
  };

  const options = {
    paymentMethodOrder: ["apple_pay", "google_pay", "card", "klarna"],
    appearance: {
      theme: "stripe"
    },
    clientSecret,
    layout: {
      type: "accordion",
      defaultCollapsed: false
    },
    locale: props.i18n.language
  };

  const optionsTrial = {
    paymentMethodOrder: ["apple_pay", "google_pay", "card", "klarna"],
    mode: "setup",
    currency: "usd",
    appearance: {
      theme: "stripe"
    },
    layout: {
      type: "accordion",
      defaultCollapsed: false
    },
    locale: props.i18n.language,
    payment_method_configuration:
      region === "US"
        ? process.env.REACT_APP_STRIPE_PAYMENT_CONFIG_ID_USA
        : process.env.REACT_APP_STRIPE_PAYMENT_CONFIG_ID_WORLD
  };

  return (
    <div
      className="content_wrap_style d-flex align-items-center"
      style={{
        height: "100vh",
        backgroundColor: "white"
      }}
    >
      {showUpdateUI ? (
        <UpgradeDowngrageUI
          plan_id={plan_id}
          region={
            client_data && client_data.stripe_region
              ? client_data.stripe_region
              : region
          }
          the_client_data={client_data}
          subscription_plan={showUpdateUI}
          plan_item_id={plan_item_id}
          preview_items={[
            // {
            //   id: showUpdateUI.sub_item_id,
            //   deleted: true
            // },
            {
              id: showUpdateUI.sub_item_id,
              price: plan_item_id
              // quantity: 1,
            }
          ]}
          items={[
            {
              id: showUpdateUI.sub_item_id,
              price: plan_item_id,
              quantity: 1
            }
          ]}
        />
      ) : (
        client_data &&
        (client_data.has_consumed_trial || client_data.direct_plan) && (
          <>
            <Col
              className=""
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                paddingRight: "70px",
                overflow: "auto"
              }}
            >
              <div
                className="content_col_width d-flex ms-auto"
                style={{
                  flexDirection: "column",
                  width: "500px",
                  height: "100%"
                }}
              >
                <div className="my-auto content_width-m">
                  <div className="d-flex align-items-center gap-1 mb-3">
                    <img src={logo_maaia} alt="logo_maaia" height={30} />
                    <p
                      className="font-size-16 mb-0 ms-1"
                      style={{ fontWeight: "500" }}
                    >
                      Synibox
                    </p>
                  </div>

                  <div
                    className="d-flex mb-3"
                    style={{ flexDirection: "column" }}
                  >
                    <p
                      className="font-size-16 mb-0"
                      style={{ fontWeight: "500" }}
                    >
                      Synibox
                    </p>

                    {planDetails && ProductCard(planDetails, true)}

                    <div>
                      <div className="mt-3">
                        {subscriptionIntent && (
                          <div
                            className="d-flex align-items-center justify-content-between pb-3"
                            style={{
                              borderBottom: "1px solid rgba(15, 15, 15, 0.1)"
                            }}
                          >
                            <div className="product_name">
                              {props.t("Subtotal")}
                            </div>
                            <span className="line_item_price">
                              {" "}
                              {subscriptionIntent.currency === "usd"
                                ? "$"
                                : "€"}
                              {subscriptionIntent.latest_invoice.subtotal / 100}
                            </span>
                          </div>
                        )}
                      </div>

                      {subscriptionIntent && (
                        <div>
                          <div>
                            <input
                              className="w-100 mt-3"
                              type="text"
                              value={couponCode}
                              onChange={(e) => setCouponCode(e.target.value)}
                              placeholder={props.t("Code promo")}
                            />
                            <button
                              className="l_button py-2 px-3 mt-2"
                              onClick={handlePromoCodeSubmit}
                              disabled={
                                couponRequestLoading || coupon || !couponCode
                              }
                              style={{
                                cursor: couponCode ? "pointer" : "not-allowed"
                              }}
                            >
                              {couponRequestLoading && (
                                <span
                                  className="spinner-border spinner-border-sm me-2 font-primary"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              {props.t(
                                coupon ? "Appliqué" : "Appliquer le code"
                              )}
                            </button>
                          </div>

                          {couponRequestMessage && (
                            <div className="font-size-12">
                              {couponRequestMessage}
                            </div>
                          )}
                        </div>
                      )}

                      {subscriptionIntent && (
                        <div className="mt-3">
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ fontWeight: "600" }}
                          >
                            <div className="product_name">
                              {props.t("Total to pay today")}
                            </div>
                            <span className="line_item_price">
                              {subscriptionIntent.currency === "usd"
                                ? "$"
                                : "€"}
                              {subscriptionIntent.latest_invoice.total / 100}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ alignSelf: "end" }}>
                <LanguageDropdown hasArrow />
              </div>
            </Col>
            <Col
              className="my-auto"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                boxShadow: "-12px 0px 15px -4px rgba(128, 128, 128, 0.15)",
                overflow: "auto"
              }}
            >
              <div
                className="content_col_width"
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {/* {!stripeCustomerID && (
                  <div
                    className="form-group form-group_1"
                    style={{
                      width: "400px"
                    }}>
                    <label htmlFor="email">{props.t("E-mail")}</label>
                    <input
                      className="w-100"
                      id="email"
                      type="email"
                      value={customer_email}
                      onChange={(e) => handleEmailChange(e.target.value)}
                      placeholder={props.t("Enter your e-mail address")}
                    />
                    <button
                      type="submit"
                      disabled={!customer_email}
                      className="r_button mt-3"
                      onClick={(e) => handleCreateDirectCustomer(customer_email, customer_fullName)}>
                      <span id="button-text">
                        {customerIsLoading || props.isRefreshing ? (
                          <span className="spinner-border spinner-border-sm me-2 font-primary" role="status" aria-hidden="true" />
                        ) : (
                          props.t("Continuer")
                        )}
                      </span>
                    </button>
                  </div>
                )} */}

                {pageLoading ? (
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <span
                      className="spinner-border spinner-border-sm me-2 font-primary text-center"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  clientSecret && (
                    <Elements stripe={stripePromise} options={options}>
                      {clientSecret && (
                        <PaymentForm
                          clientSecret={clientSecret}
                          company_id={client_data.$id}
                          plan_item_id={plan_item_id}
                          refreshStripePaymentIntent={
                            refreshStripePaymentIntent
                          }
                          handleCreateCustomer={handleCreateCustomer}
                          isRefreshing={isRefreshing}
                          region={
                            client_data && client_data.stripe_region
                              ? client_data.stripe_region
                              : region
                          }
                          planDetails={planDetails}
                          setCustomerFullName={setCustomerFullName}
                          customer_fullName={customer_fullName}
                          t={props.t}
                        />
                      )}
                    </Elements>
                  )
                )}
              </div>
            </Col>
          </>
        )
      )}
      {!stripeCustomerID &&
        !showUpdateUI &&
        !pageLoading &&
        (!client_data || (client_data && !client_data.direct_plan)) &&
        !existingUserWithPlan && (
          <>
            <Col
              className="my-auto"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                overflow: "auto"
              }}
            >
              <div
                className="content_col_width"
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {!stripeCustomerID && (
                  <div
                    className="form-group form-group_1"
                    style={{
                      width: "400px"
                    }}
                  >
                    <div className="d-flex align-items-center gap-1 mb-3">
                      <img src={logo_maaia} alt="logo_maaia" height={30} />
                      <p
                        className="font-size-16 mb-0 ms-1"
                        style={{ fontWeight: "500" }}
                      >
                        Synibox
                      </p>
                    </div>
                    {requireName && (
                      <>
                        <div className="mb-3 gap-3 d-flex flex-wrap flex-sm-nowrap">
                          <div className="w-100">
                            <Label className="form-label">
                              {props.t("Prénom")}
                            </Label>
                            <Input
                              name="first_name"
                              className=""
                              placeholder={props.t("Prénom")}
                              type="text"
                              onChange={validation_2.handleChange}
                              onBlur={validation_2.handleBlur}
                              value={validation_2.values.first_name}
                              invalid={
                                validation_2.touched.first_name &&
                                validation_2.errors.first_name
                                  ? true
                                  : false
                              }
                              style={{
                                padding: "10px"
                              }}
                            />
                            {validation_2.touched.first_name &&
                            validation_2.errors.first_name ? (
                              <FormFeedback type="invalid">
                                <div>
                                  {props.t(validation_2.errors.first_name)}
                                </div>
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="w-100">
                            <Label className="form-label">
                              {props.t("Nom de famille")}
                            </Label>
                            <Input
                              name="last_name"
                              className=""
                              placeholder={props.t("Nom de famille")}
                              type="text"
                              onChange={validation_2.handleChange}
                              onBlur={validation_2.handleBlur}
                              value={validation_2.values.last_name}
                              invalid={
                                validation_2.touched.last_name &&
                                validation_2.errors.last_name
                                  ? true
                                  : false
                              }
                              style={{
                                padding: "10px"
                              }}
                            />
                            {validation_2.touched.last_name &&
                            validation_2.errors.last_name ? (
                              <FormFeedback type="invalid">
                                <div>
                                  {props.t(validation_2.errors.last_name)}
                                </div>
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-2">
                          <Label className="form-label">
                            {props.t("password")}
                          </Label>
                          <InputGroup>
                            <Input
                              name="password"
                              value={validation_2.values.password}
                              type={isPasswordHidden ? "password" : "text"}
                              placeholder={props.t("Entrer Mot de passe")}
                              onChange={validation_2.handleChange}
                              onBlur={validation_2.handleBlur}
                              invalid={
                                validation_2.touched.password &&
                                validation_2.errors.password
                                  ? true
                                  : false
                              }
                              style={{
                                padding: "10px"
                              }}
                            />
                            <InputGroupText
                              style={{
                                backgroundColor: "transparent",
                                padding: "0",
                                borderTopRightRadius: "0.25rem",
                                borderBottomRightRadius: "0.25rem",
                                boxShadow: "none",
                                borderColor:
                                  validation_2.touched.password &&
                                  validation_2.errors.password &&
                                  "#ff3d60"
                              }}
                            >
                              <button
                                className="btn h-100"
                                type="button"
                                onClick={togglePasswordVisibility}
                                style={{
                                  color:
                                    validation_2.touched.password &&
                                    validation_2.errors.password &&
                                    "#ff3d60"
                                }}
                              >
                                {isPasswordHidden
                                  ? svgs.eye_icon
                                  : svgs.eye_slash_icon}
                              </button>
                            </InputGroupText>
                            {validation_2.touched.password &&
                            validation_2.errors.password ? (
                              <FormFeedback type="invalid">
                                <div>
                                  {" "}
                                  {props.t(validation_2.errors.password)}{" "}
                                </div>
                              </FormFeedback>
                            ) : null}
                          </InputGroup>
                        </div>
                      </>
                    )}

                    {!requireName && (
                      <>
                        {/* <label htmlFor="email">{props.t("E-mail")}</label>
                      <input
                        className="w-100"
                        disabled={requireName}
                        id="email"
                        type="email"
                        value={customer_email}
                        onChange={(e) => handleEmailChange(e.target.value)}
                        placeholder={props.t("Entrer votre e-mail")}
                      /> */}
                        <Label className="form-label">
                          {props.t("E-mail")}
                        </Label>
                        <Input
                          name="email"
                          className=""
                          placeholder={props.t("Entrer votre e-mail")}
                          type="text"
                          onChange={validation_1.handleChange}
                          onBlur={validation_1.handleBlur}
                          value={validation_1.values.email}
                          invalid={
                            validation_1.touched.email &&
                            validation_1.errors.email
                              ? true
                              : false
                          }
                          style={{
                            padding: "10px"
                          }}
                        />
                        {validation_1.touched.email &&
                        validation_1.errors.email ? (
                          <FormFeedback type="invalid">
                            <div>{props.t(validation_1.errors.email)}</div>
                          </FormFeedback>
                        ) : null}
                      </>
                    )}

                    <button
                      type="button"
                      // disabled={!customer_email}
                      className="r_button mt-3"
                      onClick={(e) =>
                        !requireName
                          ? validation_1.handleSubmit()
                          : validation_2.handleSubmit()
                      }
                    >
                      <span id="button-text">
                        {customerIsLoading || props.isRefreshing ? (
                          <span
                            className="spinner-border spinner-border-sm me-2 font-primary"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          props.t("Continuer")
                        )}
                      </span>
                    </button>
                  </div>
                )}

                {pageLoading && (
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <span
                      className="spinner-border spinner-border-sm me-2 font-primary text-center"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <div style={{ alignSelf: "end" }}>
                <LanguageDropdown hasArrow />
              </div>
            </Col>
          </>
        )}
      {existingUserWithPlan && (
        <>
          <Col
            className="my-auto"
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              overflow: "auto"
            }}
          >
            <div
              className="content_col_width"
              style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                className="form-group form-group_1"
                style={{
                  width: "400px"
                }}
              >
                <div className="d-flex align-items-center gap-1 mb-4 w-100">
                  <img src={logo_maaia} alt="logo_maaia" height={30} />
                  <p
                    className="font-size-16 mb-0 ms-1"
                    style={{ fontWeight: "500" }}
                  >
                    Synibox
                  </p>
                </div>
                <p className="font-size-16 mb-0 ms-1 text-center">
                  {props.t(
                    "Il semble que vous possédiez déjà un compte. Veuillez vous connecter pour procéder à la mise à jour de votre abonnement."
                  )}
                </p>
                <button
                  type="button"
                  className="r_button mt-3"
                  onClick={() => {
                    navigate(`/settings`, {
                      replace: true,
                      state: { tab: "billing", modal: "" }
                    });
                  }}
                >
                  <span id="button-text">{props.t("Connexion")}</span>
                </button>
              </div>
            </div>
            <div style={{ alignSelf: "end" }}>
              <LanguageDropdown hasArrow />
            </div>
          </Col>
        </>
      )}
      {stripeCustomerID &&
        ((client_data &&
          !client_data.has_consumed_trial &&
          !client_data.direct_plan) ||
          !client_data) && (
          <>
            <Col
              className=""
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                paddingRight: "70px",
                overflow: "auto"
              }}
            >
              <div
                className="content_col_width d-flex ms-auto"
                style={{
                  flexDirection: "column",
                  width: "500px",
                  height: "100%"
                }}
              >
                <div className="my-auto content_width-m">
                  <div className="d-flex align-items-center gap-1 mb-3">
                    <img src={logo_maaia} alt="logo_maaia" height={30} />
                    <p
                      className="font-size-16 mb-0 ms-1"
                      style={{ fontWeight: "500" }}
                    >
                      Synibox
                    </p>
                  </div>

                  <div
                    className="d-flex mb-3"
                    style={{ flexDirection: "column" }}
                  >
                    <p
                      className="font-size-16 mb-0"
                      style={{ fontWeight: "500" }}
                    >
                      Synibox
                    </p>

                    {ProductCard(
                      undefined,
                      true,
                      true,
                      plan_id.toUpperCase(),
                      region
                    )}
                    <div>
                      <div className="mt-3">
                        <div
                          className="d-flex align-items-center justify-content-between pb-3"
                          style={{
                            borderBottom: "1px solid rgba(15, 15, 15, 0.1)"
                          }}
                        >
                          <div className="product_name">
                            {props.t("Subtotal")}
                          </div>
                          <span className="line_item_price">
                            {region && region === "US" ? "$" : "€"}
                            00.00
                          </span>
                        </div>
                      </div>

                      <div>
                        <div>
                          <input
                            className="w-100 mt-3"
                            type="text"
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            placeholder={props.t("Code promo")}
                          />
                          <button
                            className="l_button py-2 px-3 mt-2"
                            onClick={handlePromoCodeTrialSubmit}
                            disabled={
                              couponRequestLoading || coupon || !couponCode
                            }
                            style={{
                              cursor: couponCode ? "pointer" : "not-allowed"
                            }}
                          >
                            {couponRequestLoading && (
                              <span
                                className="spinner-border spinner-border-sm me-2 font-primary"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            {props.t(coupon ? "Appliqué" : "Appliquer le code")}
                          </button>
                        </div>

                        {couponRequestMessage && (
                          <div className="font-size-12">
                            {couponRequestMessage}
                          </div>
                        )}
                      </div>

                      <div className="mt-3">
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ fontWeight: "600" }}
                        >
                          <div className="product_name">
                            {props.t("Total to pay today")}
                          </div>
                          <span className="line_item_price">
                            {region && region === "US" ? "$" : "€"}
                            00.00
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ alignSelf: "end" }}>
                <LanguageDropdown hasArrow />
              </div>
            </Col>
            <Col
              className=""
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                boxShadow: "-12px 0px 15px -4px rgba(128, 128, 128, 0.15)",
                overflow: "auto"
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                {pageLoading ? (
                  <div
                    style={{
                      width: "100%",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <span
                      className="spinner-border spinner-border-sm me-2 font-primary text-center"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <Elements stripe={stripePromise} options={optionsTrial}>
                    <PaymentTrialForm
                      refreshStripePaymentIntent={refreshStripePaymentIntent}
                      isRefreshing={isRefreshing}
                      stripeCustomerID={stripeCustomerID}
                      region={
                        client_data && client_data.stripe_region
                          ? client_data.stripe_region
                          : region
                      }
                      setCustomerFullName={setCustomerFullName}
                      validation_1={validation_1}
                      validation_2={validation_2}
                      customer_email={validation_1.values.email}
                      customer_fullName={
                        `${validation_2.values.first_name} ${validation_2.values.last_name}` ||
                        customer_fullName
                      }
                      user_id={user_id}
                      company_id={client_data ? client_data.$id : undefined}
                      plan_item_id={plan_item_id}
                      t={props.t}
                      coupon_id={coupon ? coupon.id : undefined}
                    />
                  </Elements>
                )}
              </div>
            </Col>
          </>
        )}
    </div>
  );

  function ProductCard(
    plan,
    is_plan,
    trial = false,
    description = null,
    region = undefined
  ) {
    return (
      <div className={is_plan ? "addon_card" : ""}>
        <div className="my-2 w-100">
          <div className="d-flex">
            <div
              className="d-flex w-100"
              style={{
                flexDirection: "column"
              }}
            >
              <div className="d-flex justify-content-between w-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div className="product_name">
                    {description
                      ? props.t("Trial period for ") + description
                      : plan.description
                          .split(" ")
                          .map((word) => props.t(word))
                          .join(" ")}
                  </div>
                  {!trial && (
                    <div>
                      <select className="qty_select">
                        {plan.plan.interval === "month" ? (
                          <option value="Monthly">{props.t("Monthly")}</option>
                        ) : (
                          <option value="Yearly">{props.t("Yearly")}</option>
                        )}
                      </select>
                    </div>
                  )}
                </div>
                <span className="line_item_price" style={{ fontWeight: "600" }}>
                  {trial
                    ? region === "US"
                      ? "$"
                      : "€"
                    : plan.plan.currency === "usd"
                    ? "$"
                    : "€"}
                  {trial ? "00.00" : plan.amount / 100}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export const UpgradeDowngrageUI = withTranslation()((props) => {
  let navigate = useNavigate();
  const {
    region,
    the_client_data,
    subscription_plan,
    items,
    preview_items,
    plan_item_id
  } = props;
  const {
    consumed_email_count,
    number_of_credits,
    userPlan,
    subscriptionIncludedCredits,
    next_billed_at
  } = useSelector((state) => {
    return {
      number_of_credits: state.subscription.subscriptionCredits,
      userPlan: state.subscription.userPlan,
      subscriptionIncludedCredits:
        state.subscription.subscriptionIncludedCredits,
      next_billed_at:
        state.subscription.subscription?.current_period_end * 1000,
      consumed_email_count: state.GeneralStateRedux.consumed_email_count
    };
  });
  const [invoicePreview, setInvoicePreview] = useState(undefined);
  const [planDetails, setPlanDetails] = useState(undefined);
  const [decompte, setDecompte] = useState(15);
  const [redirect, setRedirect] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [coupon, setCoupon] = useState(undefined);
  const [couponRequestMessage, setCouponRequestMessage] = useState("");
  const [couponRequestLoading, setCouponRequestLoading] = useState(false);

  useEffect(() => {
    if (decompte === 0) {
      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
  }, [decompte]);

  useEffect(() => {
    if (redirect) {
      let interval;
      interval = setInterval(() => {
        setDecompte((prevDecompte) => prevDecompte - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [redirect]);

  const { mixPanelTrack } = useMixpanel();

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const getStripeUpComingInvoice = async (
    region,
    stripe_customer_id,
    subscription_plan_id,
    items,
    coupon
  ) => {
    setPageLoading(true);
    const response = await retrieveStripeUpComingInvoice(
      region,
      stripe_customer_id,
      subscription_plan_id,
      items,
      "none",
      coupon
    );

    console.log({ retrieveStripeUpComingInvoiceResponse: response });

    if (response) {
      const invoice = response.invoice;
      console.log({ the_subscription: invoice.lines.data });

      const is_subscription_trialing =
        invoice.subscription.status === "trialing";

      const plan_details = is_subscription_trialing
        ? [{ ...invoice.lines.data[0], amount: 0 }]
        : invoice.lines.data.filter((line) => !line.proration);
      const totalAmount = is_subscription_trialing
        ? 0
        : plan_details.reduce((total, line) => total + line.amount, 0);

      console.log({ invoice });
      console.log({ plan_details });

      setInvoicePreview({
        ...invoice,
        subtotal: totalAmount,
        total: totalAmount
      });
      setPlanDetails(plan_details);
    } else {
      console.log("okkkk 6");

      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
    setPageLoading(false);
  };

  const handleUpdateSubscriptipon = async (coupon) => {
    const metadata = {
      downgrade:
        checkSubcriptionUpdateType(plan_item_id, subscription_plan.price_id) ===
        "downgrade",
      number_of_languages: SMARTAS.LANGUAGE_SUPPORT[plan_item_id],
      credit_carried_over:
        consumed_email_count >= subscriptionIncludedCredits
          ? 0
          : subscriptionIncludedCredits - consumed_email_count,
      billing_cycle_anchor:
        checkSubcriptionUpdateType(plan_item_id, subscription_plan.price_id) ===
          "downgrade" && "unchanged",
      email_accounts: SMARTAS.EMAIL_ACCOUNTS[plan_item_id],
      cancel_at_period_end: false
    };
    console.log({ metadata });

    setLoading(true);
    const response = await updateStripeSubscripion(
      region,
      subscription_plan.id,
      items,
      metadata,
      "none",
      coupon
    );

    console.log({ updateStripeSubscripion: response });
    if (response) {
      // Créer le log
      const company_id = the_client_data.$id;
      var user_id = JSON.parse(localStorage.getItem("authUser")).userId;

      const updates = {
        properties: {
          plan: SMARTAS.PLAN_NAME[plan_item_id],
          plan_activation_date: formatDateMillisec(Date.now()),
          on_pause: false
        }
      };
      klaviyoUpdateProfile(company_id, updates);

      let logData = {
        eventCreatedAt: Date.now(),
        author_id: user_id,
        new_plan: SMARTAS.PLAN_NAME[plan_item_id],
        old_plan: SMARTAS.PLAN_NAME[subscription_plan.price_id],
        company_id: company_id
      };
      let logdata = {
        event: "subscription_updated",
        author_id: user_id,
        company_id: company_id,
        log_data: JSON.stringify(logData)
      };
      createLog(logdata, mixPanelTrack);
      setLoading(false);
      setRedirect(true);
    }
  };

  // function formatDate(millisec, short = false) {
  //   const options = short ? { day: "2-digit", month: "short", year: "2-digit" } : { day: "2-digit", month: "long", year: "numeric" };
  //   const date = new Date(millisec);
  //   // return date.toLocaleDateString(props.i18n.language + "-" + props.i18n.language.toUpperCase(), options);
  //   return "";
  // }
  useEffect(() => {
    console.log({
      consumed_email_count,
      number_of_credits,
      userPlan,
      subscription_plan,
      next_billed_at
    });
    if (
      userPlan === "FREE" ||
      checkSubcriptionUpdateType(plan_item_id, subscription_plan.price_id) ===
        "downgrade"
    ) {
      console.log("okkkk 7");

      navigate(`/settings`, {
        replace: true,
        state: { tab: "billing", modal: "" }
      });
    }
  }, []);

  useEffect(() => {
    getStripeUpComingInvoice(
      region,
      the_client_data.stripe_customer_id,
      subscription_plan.id,
      preview_items
    );

    // const interval = setInterval(() => {
    //   getStripeUpComingInvoice(region, the_client_data.stripe_customer_id, subscription_plan.id, preview_items);
    // }, 120000); // 120000 ms = 2 minutes

    // // Nettoyage de l'intervalle à la destruction du composant
    // return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Col
        className="my-auto"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          overflow: "auto"
        }}
      >
        <div
          className="d-flex px-3 pt-5 content_width"
          style={{
            flexDirection: "column",
            width: "600px",
            height: "100%",
            overflow: "auto"
          }}
        >
          <div className="my-auto">
            {redirect && (
              <Alert className="text-center" color="success">
                {`${props.t("Opération réussie !")} ${props.t(
                  "Vous serez redirigé dans"
                )} ${decompte}sec...`}
              </Alert>
            )}
            <div
              className={`d-flex align-items-center gap-1 mb-3 ${
                invoicePreview ? "" : "justify-content-center"
              }`}
            >
              <img alt="logo_maaia" src={logo_maaia} height={30} />
              <p
                className="font-size-16 mb-0 ms-1"
                style={{ fontWeight: "500" }}
              >
                Synibox
              </p>
            </div>

            <div
              className={`mb-3 font-size-16 d-flex ${
                invoicePreview ? "" : "justify-content-center"
              }`}
            >
              {props.t("Switching from current plan to")}
              <span className="ms-1" style={{ textTransform: "uppercase" }}>
                {props.plan_id}
              </span>
            </div>

            {!pageLoading ? (
              <>
                <div
                  className="d-flex mb-3"
                  style={{ flexDirection: "column" }}
                >
                  {checkSubcriptionUpdateType(
                    plan_item_id,
                    subscription_plan.price_id
                  ) === "downgrade" ? (
                    <p
                      className="mb-3 font-size-16"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {props.t("Votre nouveau plan prendra effet le")}{" "}
                      {formatDate(props, next_billed_at, false, true)}
                    </p>
                  ) : (
                    <p
                      className="mb-3 font-size-16"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      {props.t("Totals des crédits non consommés")}:{" "}
                      {number_of_credits && number_of_credits > 0
                        ? consumed_email_count >= number_of_credits
                          ? "0"
                          : number_of_credits - consumed_email_count
                        : "0"}
                      <br />
                      {props.t(
                        "Ces crédits vous seront reportés sur votre nouveau plan."
                      )}
                    </p>
                  )}

                  {planDetails &&
                    planDetails.map((detail, idx) => (
                      <div className="pb-3" key={idx}>
                        {ProductCard(detail, true)}
                      </div>
                    ))}
                  <div>
                    <div className="mt-3">
                      {invoicePreview && (
                        <div
                          className="d-flex align-items-center justify-content-between pb-3"
                          style={{
                            borderBottom: "1px solid rgba(15, 15, 15, 0.1)"
                          }}
                        >
                          <div className="product_name">
                            {props.t("Subtotal")}
                          </div>
                          <span className="line_item_price">
                            {" "}
                            {invoicePreview.currency === "usd" ? "$" : "€"}
                            {invoicePreview.subtotal / 100}
                          </span>
                        </div>
                      )}
                    </div>

                    {invoicePreview && (
                      <div className="mt-3">
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ fontWeight: "600" }}
                        >
                          <div className="product_name">
                            {props.t("Total to pay today")}
                          </div>
                          <span className="line_item_price">
                            {invoicePreview.currency === "usd" ? "$" : "€"}
                            {invoicePreview.total / 100}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className="r_button py-2 px-2 font-size-14 mt-2"
                  onClick={() => {
                    handleUpdateSubscriptipon(coupon);
                  }}
                >
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2 font-primary"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    props.t("Confirmer")
                  )}
                </button>
              </>
            ) : (
              <div
                className="mt-4 font-size-16 gap-1"
                style={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <span
                  className="spinner-border spinner-border-md me-2 font-primary text-center"
                  role="status"
                  aria-hidden="true"
                />
                {props.t("Chargement") + "..."}
              </div>
            )}
          </div>
        </div>
        <div style={{ alignSelf: "end" }}>
          <LanguageDropdown hasArrow />
        </div>
      </Col>
    </>
  );
  function ProductCard(plan, is_plan) {
    return (
      <div className={is_plan ? "addon_card" : ""}>
        <div className="my-2 w-100">
          <div className="d-flex">
            <div
              className="d-flex w-100"
              style={{
                flexDirection: "column"
              }}
            >
              <div className="d-flex justify-content-between w-100">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <div className="product_name">
                    {plan.description
                      .split(" ")
                      .map((word) => props.t(word))
                      .join(" ")}
                  </div>
                  <div>
                    <select className="qty_select">
                      {plan.plan.interval === "month" ? (
                        <option value="Monthly">{props.t("Monthly")}</option>
                      ) : (
                        <option value="Yearly">{props.t("Yearly")}</option>
                      )}
                    </select>
                  </div>
                </div>
                <span className="line_item_price" style={{ fontWeight: "600" }}>
                  {plan.plan.currency === "usd" ? "$" : "€"}
                  {plan.amount / 100}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export default withTranslation()(StripeCheckout);
