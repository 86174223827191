import React from "react";

function PlusOutlined({
  width,
  height,
  className,
  style,
  strokeWidth = "1.5"
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      style={style}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H12M19 12H15M12 12V5M12 12V19"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PlusOutlined;
