import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  CardBody,
  Row,
  Container,
  FormGroup,
  Input,
  Label,
  Spinner,
  InputGroupText,
  InputGroup,
  FormFeedback,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from "reactstrap";
import "react-rangeslider/lib/index.css";

import { Client, Databases, ID } from "appwrite";

//import '../../assets/css/icons.min.css';
import "../../assets/css/app.min.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Formik validation
import { withTranslation } from "react-i18next";
import {
  createLog,
  createAllMaaiaBoxes,
  getMailboxErrorMessage,
  getWhoisFunctionExecution,
  createAllMaaiaBoxesDirect,
  startAgent,
  stopAgent
  // encryptPassword,
  //  decryptPassword
} from "../../helpers/api_helper";
import CustomToast from "../../components/Common/customToast/CustomToast";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useMixpanel } from "../../Hooks/useMixpanel";
import EmailBoxCard from "./EmailBoxCard";
import { emptyMailBox, fetchClientInfos } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";
import decryptPasswordLocal from "../../helpers/decrypt_helper";
import encryptPasswordLocal from "../../helpers/encrypt_helper";

// const CLIENT_ID = "505437851076-gumpc4op5af3rq22g8bieipo4ma9ut9q.apps.googleusercontent.com";
// const SCOPES = "https://www.googleapis.com/auth/gmail.readonly";

const SettingEmailConfig = (props) => {
  const [auto_response_level, setAutoResponseLevel] = useState(5);

  const dispatch = useDispatch();

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [selectedServer, setSelectedServer] = useState("custom");
  const [serverDropdown, setServerDropdown] = useState(false);
  const [host, setHost] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [port, setPort] = useState("");
  const [smtp_host, setSMTPHost] = useState("");
  const [smtp_port, setSMTPPort] = useState("");
  const [tls_mode, setTlsMode] = useState();

  const [loading, setLoading] = useState(false);
  // const [client_data, setCompanyInfos] = useState(undefined);
  const [site_name, setSiteName] = useState("");
  const [site_url, setSiteUrl] = useState("");
  const [shopify_store_url, setShopifyStoreUrl] = useState("");
  const [shopify_api_key, setShopifyStoreApiKey] = useState("");
  const [signature, setSignature] = useState("");
  const [site_infos_more, setSiteInfosMore] = useState("");
  const [contactReasonValues, setContactReasonValues] = useState({});
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const togglePasswordVisibility = () => {
    setIsPasswordHidden(!isPasswordHidden);
  };
  const [mailboxError, setMailboxError] = useState(undefined);
  const [serverValues, setServerValues] = useState(true);
  const [selectedMailBox, setSelectedMailBox] = useState(undefined);
  const [deleteBox, setDeleteBox] = useState(false);
  const [box, setBox] = useState(undefined);

  const { mixPanelTrack } = useMixpanel();

  const [deleteAgentLoading, setDeleteAgentLoading] = useState({});
  const { client_data } = useSelector((state) => {
    return {
      client_data: state.GeneralStateRedux.client_data
    };
  });

  //

  // const toggleContactReason = (reason) => {
  //   if (contactReasonValues[reason.contact_reasons]) {
  //     const updatedValues = { ...contactReasonValues };
  //     delete updatedValues[reason.contact_reasons];
  //     setContactReasonValues(updatedValues);
  //   } else {
  //     const updatedValues = { ...contactReasonValues };
  //     updatedValues[reason.contact_reasons] = reason.contact_reasons;
  //     setContactReasonValues(updatedValues);
  //   }
  // };

  const publicDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "yahoo.fr",
    "aol.com",
    "icloud.com",
    "protonmail.com",
    "zoho.com",
    "yandex.com",
    "mail.com",
    "gmx.com",
    "fastmail.com",
    "tutanota.com",
    "mail.ru",
    "hushmail.com",
    "airmail.net",
    "lycos.com",
    "netcourrier.com",
    "zimbra.com",
    "rediffmail.com",
    "mailinator.com"
  ];

  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
      host: "",
      port: "",
      smtp_host: "",
      smtp_port: "",
      tls_mode: false
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(props.t("Veuillez entrer votre Email"))
        .email(props.t("Veuillez entrer un email valide"))
        .test(
          "is-professional-email",
          props.t("Seuls les emails professionnels sont autorisés"),
          (value) => {
            if (!value) return false;
            const domain = value.split("@")[1]; // Obtenir le domaine de l'email
            return !publicDomains.includes(domain); // Vérifier que le domaine n'est pas dans la liste des domaines publics
          }
        ),
      password: Yup.string().required(
        props.t("Veuillez entrer votre mot de passe")
      ),
      host:
        serverValues || selectedMailBox === undefined
          ? Yup.string()
          : Yup.string().required("Veuillez entrer le serveur IMAP"),
      port:
        serverValues || selectedMailBox === undefined
          ? Yup.string().matches(
              /^\d+$/,
              props.t("Ce champ ne doit contenir que des chiffres")
            )
          : Yup.string()
              .matches(
                /^\d+$/,
                props.t("Ce champ ne doit contenir que des chiffres")
              )
              .required("Veuillez entrer le port du serveur IMAP"),
      smtp_host:
        serverValues || selectedMailBox === undefined
          ? Yup.string().matches(
              /^(?=.{1,255}$)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$|^(?:\d{1,3}\.){3}\d{1,3}$/,
              "Veuillez entrer un hôte SMTP valide"
            )
          : Yup.string()
              .matches(
                /^(?=.{1,255}$)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$|^(?:\d{1,3}\.){3}\d{1,3}$/,
                "Veuillez entrer un hôte SMTP valide"
              )
              .required("Veuillez entrer le serveur SMTP"),
      smtp_port:
        serverValues || selectedMailBox === undefined
          ? Yup.string().matches(
              /^\d+$/,
              props.t("Ce champ ne doit contenir que des chiffres")
            )
          : Yup.string()
              .matches(
                /^\d+$/,
                props.t("Ce champ ne doit contenir que des chiffres")
              )
              .required("Veuillez entrer le port du serveur SMTP"),
      tls_mode: Yup.boolean()
    }),
    onSubmit: (values) => {
      updateConfig(values);
    }
  });

  const onSelectedServerChanged = (val) => {
    setSelectedServer(val);
  };

  const onLoadingChanged = (val) => {
    setLoading(val);
  };

  const onSetServerValues = (val) => {
    setServerValues(val);
  };

  const onSetMailboxError = (val) => {
    setMailboxError(val);
  };
  // const timer = setTimeout(() => { }, 20000);

  // async function createCompanyInfos(data) {
  //   onLoadingChanged(true);

  //   const client = new Client();

  //   const databases = new Databases(client, 'default');

  //   client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

  //   const promise = databases.createDocument('default', 'client_infos', localStorage.getItem('companyId'), data);

  //   promise.then(
  //     async function (response) {
  //       console.log(response); // Success
  //       await launchServer(40000, 1);
  //     },
  //     function (error) {
  //       onLoadingChanged(false);
  //       setToastMessage('Echec : ' + error.message);
  //       toggleToast();

  //       console.log('createCompanyInfos', error); // Failure
  //     }
  //   );
  // }

  async function updateCompanyInfos(data) {
    onLoadingChanged(true);

    const client = new Client();

    const databases = new Databases(client, "default");

    console.log("updateCompanyInfos", JSON.stringify(data));
    console.log("new data to save", data);
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "client_infos",
      localStorage.getItem("companyId"),
      JSON.stringify(data)
    );

    return promise.then(
      async function (response) {
        onLoadingChanged(false);
        setToastMessage({
          title: props.t("Succès"),
          body: props.t("Opération effectuée"),
          key: "success"
        });
        toggleToast();
        return response;
      },
      function (error) {
        onLoadingChanged(false);
        setToastMessage({
          title: props.t("Echec"),
          body: error.message,
          key: "danger"
        });
        toggleToast();

        console.log({ saveError: error }); // Failure
        return null;
      }
    );
  }

  // async function addMailBox(data) {
  //   const client = new Client();

  //   const databases = new Databases(client, "default");

  //   console.log("addMailBoxyInfos", JSON.stringify(data));
  //   console.log("new data to add", data);
  //   client.setEndpoint(process.env.REACT_APP_DATABASEURL).setProject(process.env.REACT_APP_PROJECTID);

  //   const promise = databases.createDocument("default", "mail_box", ID.unique(), JSON.stringify(data));

  //   return promise.then(
  //     async function (response) {
  //       return response;
  //     },
  //     function (error) {
  //       onLoadingChanged(false);
  //       setToastMessage({ title: props.t("Echec"), body: error.message, key: "danger" });
  //       toggleToast();

  //       console.log(error); // Failure
  //       return null;
  //     }
  //   );
  // }

  async function removeMailBox(data) {
    const client = new Client();

    const databases = new Databases(client, "default");

    console.log("removeMailBoxyInfos", JSON.stringify(data));
    console.log("new data to remove", data);
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.deleteDocument("default", "mail_box", data.$id);

    return promise.then(
      async function (response) {
        return response;
      },
      function (error) {
        onLoadingChanged(false);
        setToastMessage({
          title: props.t("Echec"),
          body: error.message,
          key: "danger"
        });
        toggleToast();

        console.log(error); // Failure
        return null;
      }
    );
  }

  async function updateMailBox(data) {
    const client = new Client();

    const databases = new Databases(client, "default");

    console.log({ updateMailBoxyInfos: data, id: selectedMailBox.$id });
    console.log("new data to update", data);
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);

    const promise = databases.updateDocument(
      "default",
      "mail_box",
      selectedMailBox.$id,
      data
    );

    return promise.then(
      async function (response) {
        console.log("updateMailBoxResponse", response);
        return response;
      },
      function (error) {
        onLoadingChanged(false);
        setToastMessage({
          title: props.t("Echec"),
          body: error.message,
          key: "danger"
        });
        toggleToast();

        console.log(error); // Failure
        return null;
      }
    );
  }

  const initData = async function () {
    if (client_data) {
      // setCompanyInfos(response);
      setSiteName(client_data.company ?? "");
      setSiteUrl(client_data.company_website_url ?? "");
      setSiteInfosMore(client_data.more_info ?? "");
      setShopifyStoreUrl(client_data.shopify_store_url ?? "");
      setShopifyStoreApiKey(client_data.shopify_api_key ?? "");
      setSignature(client_data.signature ?? "");

      // if (response.email) {
      //   if (response.email.includes("@gmail")) {
      //     onSelectedServerChanged("gmail");
      //   } else if (response.email.includes("@outllook")) {
      //     onSelectedServerChanged("outllook");
      //   } else if (response.host.includes("ovh")) {
      //     onSelectedServerChanged("ovh");
      //   } else if (response.host.includes("infomaniak")) {
      //     onSelectedServerChanged("infomaniak");
      //   } else if (response.host.includes("hostinger")) {
      //     onSelectedServerChanged("hostinger");
      //   } else if (response.host.includes("secureserver")) {
      //     onSelectedServerChanged("secureserver");
      //   } else {
      //     onSelectedServerChanged("custom");
      //   }
      // }

      validation.setValues({
        email:
          client_data.mailBox && client_data.mailBox.length > 0
            ? client_data.mailBox[0].email
            : client_data.email ?? "",
        password: decryptPasswordLocal(
          client_data.mailBox && client_data.mailBox.length > 0
            ? client_data.mailBox[0].password
            : ""
        ),
        // password: response.mailBox && response.mailBox.length > 0 ? response.mailBox[0].password : "",
        port:
          client_data.mailBox && client_data.mailBox.length > 0
            ? client_data.mailBox[0].port
            : client_data.port ?? "",
        host:
          client_data.mailBox && client_data.mailBox.length > 0
            ? client_data.mailBox[0].host
            : client_data.host ?? "",
        smtp_host:
          client_data.mailBox && client_data.mailBox.length > 0
            ? client_data.mailBox[0].smtp_host
            : client_data.smtp_host ?? "",
        smtp_port:
          client_data.mailBox && client_data.mailBox.length > 0
            ? client_data.mailBox[0].smtp_port
            : client_data.smtp_port ?? "",
        tls_mode:
          client_data.mailBox && client_data.mailBox.length > 0
            ? client_data.mailBox[0].tls
            : client_data.tls
      });

      setAutoResponseLevel(client_data.auto_response_level ?? 0);
      const reasons = client_data.contact_reasons;
      console.log("reasons", reasons);
      const reasonMap = {};
      for (const reason of reasons) {
        reasonMap[reason] = reason;
      }
      setContactReasonValues(reasonMap);
      console.log("reasonsMap", reasonMap);
    }
  };

  /*
  const checkUserPrefs = async function () {
    const client = new Client();
 
    const account = new Account(client);
 
    client
      .setEndpoint(process.env.REACT_APP_DATABASEURL)
      .setProject(process.env.REACT_APP_PROJECTID);
 
 
    const promise = account.getPrefs();
 
    promise.then(function (response) {
      console.log(response);
 
      setEmail(response["email"] ?? "");
      setPassword(response["password"] ?? "");
      setPort(response["port"] ?? "");
      setHost(response["host"] ?? "");
      setSMTPHost(response["smtp_host"] ?? "");
      setSMTPPort(response["smtp_port"] ?? "");
      setTlsMode(response["tls"] ?? "");
      
      setAutoResponseLevel(response["auto_response_level"] ?? 0);
 
      return response;
    }, function (error) {
      console.log(error); // Failure
      return null;
    });
  }
*/

  /*
    async function saveUserPrefs(data) {
      setLoading(true);
  
      const client = new Client();
  
      const account = new Account(client);
  
      client
        .setEndpoint(process.env.REACT_APP_DATABASEURL)
        .setProject(process.env.REACT_APP_PROJECTID);
  
  
      const promise = account.updatePrefs(data);
  
      promise.then(function (response) {
  
        console.log(response); // Success
      }, function (error) {
  
        console.log(error); // Failure
      });
    }
    */

  async function updateConfig(values) {
    // if (
    //   client_data.email === values.email &&
    //   client_data.password === values.password &&
    //   client_data.host === values.host &&
    //   client_data.port === values.port &&
    //   client_data.smtp_host === values.smtp_host &&
    //   client_data.smtp_port === values.smtp_port &&
    //   client_data.tls === values.tls_mode
    // ) {
    //   return;
    // }

    onLoadingChanged(true);
    onSetMailboxError(undefined);
    console.log({ form_values: values });

    const companyId = localStorage.getItem("companyId");

    if (selectedMailBox === null) {
      for (let i = 0; i < client_data.mailBox.length; i++) {
        if (values.email === client_data.mailBox[i].email) {
          onLoadingChanged(false);
          onSetMailboxError("busy_sav_email");
          return;
        }
      }
    }

    if (companyId) {
      /// get whois infos
      let w_host = null,
        w_port = null,
        w_smtp_host = null,
        w_smtp_port = null;
      console.log({
        serverValues: serverValues,
        smtp_host: values.smtp_host,
        smtp_port: values.smtp_port
      });
      if (
        serverValues === true &&
        (values.host === "" || values.host === undefined)
      ) {
        // call whois
        let whoisResponse;
        try {
          whoisResponse = await getWhoisFunctionExecution({
            email: values.email
          });
        } catch (error) {
          console.log({ whoisResponseError: error });

          whoisResponse = undefined;
        }

        console.log({ whoisResponse: whoisResponse });
        // if network error setError("Erreur inattendue"); and stop process
        if (
          whoisResponse === undefined ||
          whoisResponse.error ||
          whoisResponse.whois.error
        ) {
          onLoadingChanged(false);
          onSetMailboxError(whoisResponse?.error ?? "Erreur inattendue");
          return;
        } else {
          const data = whoisResponse.whois;

          // if no smtp/imap show full form
          if (data.imap == null || data.smtp == null) {
            onLoadingChanged(false);
            // show full form
            onSetServerValues(false);
            onSetMailboxError("whois error");
            return;
          }

          // if smtp/imap get_boxes of each smtp/imap, valdation.setValues and continue process
          let configFound = false;
          let authError = false;
          for (let i = 0; i < data.imap.length; i++) {
            let boxResponse;
            console.log({ whoisInfos: data[i] });
            try {
              boxResponse = await createAllMaaiaBoxesDirect({
                host: data.imap[i].server,
                email: values.email,
                // key: encryptPassword(values.password)
                key: values.password
              });
            } catch (error) {
              boxResponse = undefined;
            }

            if (boxResponse && !boxResponse.detail) {
              w_host = data.imap[i].server;
              w_port = data.imap[i].port.toString();
              w_smtp_host = data.smtp[i].server;
              w_smtp_port = data.smtp[i].port.toString();
              configFound = true;
              break;
            } else if (
              boxResponse &&
              boxResponse.detail &&
              (boxResponse.detail.includes("AUTHENTICATIONFAILED") ||
                (boxResponse.detail.includes("error") &&
                  boxResponse.detail.includes("connecting")))
            ) {
              authError = true;
              break;
            } else {
              authError = undefined;
              break;
            }
          }
          if (!configFound) {
            onSetMailboxError(
              authError === true ? "AUTHENTICATIONFAILED" : "Erreur inattendu"
            );
            onSetServerValues(
              authError === true || authError === undefined ? true : false
            );
            onLoadingChanged(false);
            return;
          }
        }
      } else {
        let boxesCreated;
        try {
          console.log({ whoisInfos: values.host });
          boxesCreated = await createAllMaaiaBoxesDirect({
            host: values.host,
            email: values.email,
            //  key: encryptPassword(values.password)
            key: values.password
          });
        } catch (error) {
          boxesCreated = undefined;
        }

        console.log({ boxesCreated });
        if (boxesCreated && !boxesCreated.detail) {
          onSetMailboxError(undefined);
        } else if (
          boxesCreated &&
          boxesCreated.detail &&
          (boxesCreated.detail.includes("AUTHENTICATIONFAILED") ||
            (boxesCreated.detail.includes("error") &&
              boxesCreated.detail.includes("connecting")))
        ) {
          onLoadingChanged(false);
          onSetMailboxError("AUTHENTICATIONFAILED");
          return;
        } else {
          onLoadingChanged(false);
          onSetMailboxError(
            boxesCreated ? boxesCreated.detail : "Erreur inattendu"
          );
          return;
        }
      }

      // let mailResponse = undefined;
      let oldMailBoxs = client_data.mailBox;
      if (selectedMailBox === null || selectedMailBox === undefined) {
        // mailResponse = await addMailBox({
        //   email: values.email,
        //   password: values.password,
        //   imap_host: w_host ?? values.host,
        //   imap_port: parseInt(w_port) ?? values.port,
        //   smtp_host: w_smtp_host ?? values.smtp_host,
        //   smtp_port: parseInt(w_smtp_port) ?? values.smtp_port,
        //   tls: values.tls_mode === null || values.tls_mode === "" ? false : values.tls_mode ?? false,
        // });
        // if (!mailResponse) {
        //   onLoadingChanged(false);
        //   onSetMailboxError("Erreur inattendue");

        //   return;
        // }

        // const encryptPass = await encryptPassword({ password: values.password });
        // if (encryptPass === undefined || encryptPass === "") {
        //   onLoadingChanged(false);
        //   onSetMailboxError("Erreur inattendue");
        //   return;
        // }
        oldMailBoxs.push({
          email: values.email,
          password: encryptPasswordLocal(values.password),
          // password: values.password,
          imap_host: w_host ?? values.host,
          imap_port: w_port != null ? parseInt(w_port) : parseInt(values.port),
          smtp_host: w_smtp_host ?? values.smtp_host,
          smtp_port:
            w_smtp_port != null
              ? parseInt(w_smtp_port)
              : parseInt(values.smtp_port),
          tls:
            values.tls_mode === null || values.tls_mode === ""
              ? false
              : values.tls_mode ?? false
        });
      }

      if (selectedMailBox && selectedMailBox !== null) {
        let updatedItems = oldMailBoxs.map((item) =>
          item.$id === selectedMailBox.$id
            ? {
                ...item,
                $id: selectedMailBox.$id,
                password: encryptPasswordLocal(values.password),
                imap_host: w_host ?? values.host,
                imap_port:
                  w_port != null ? parseInt(w_port) : parseInt(values.port),
                smtp_host: w_smtp_host ?? values.smtp_host,
                smtp_port:
                  w_smtp_port != null
                    ? parseInt(w_smtp_port)
                    : parseInt(values.smtp_port),
                tls:
                  values.tls_mode === null || values.tls_mode === ""
                    ? false
                    : values.tls_mode ?? false
              }
            : item
        );
        oldMailBoxs = updatedItems;
      }

      const updateData = {
        //  email: values.email,
        //  password: values.password,
        //  host: w_host ?? values.host,
        //  port: w_port ?? values.port,
        //  smtp_host: w_smtp_host ?? values.smtp_host,
        //  smtp_port: w_smtp_port ?? values.smtp_port,
        //  tls: values.tls_mode === null || values.tls_mode === "" ? false : true,
        //  mail_config: true,
        mailBox: oldMailBoxs
      };
      console.log({ updateCompanyData: updateData });
      const updateResponse = await updateCompanyInfos(updateData);
      console.log({ updateCompanyData: updateResponse });
      if (!updateResponse) {
        onLoadingChanged(false);
        onSetMailboxError("Erreur inattendue");
        dispatch(fetchClientInfos());
        return;
      }

      if (updateResponse.mailBox && updateResponse.mailBox.length === 1) {
        localStorage.setItem(
          "selected_mail_box",
          updateResponse.mailBox[0].email
        );
      }
      dispatch(emptyMailBox());
      dispatch(fetchClientInfos());
      // Créer le log
      var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
      let logData = {
        eventCreatedAt: updateResponse.$createdAt,
        author_id: user_id,
        company_id: client_data.$id
      };
      let logdata = {
        event: "email_config_updated",
        author_id: user_id,
        company_id: client_data.$id,
        log_data: JSON.stringify(logData)
      };
      createLog(logdata, mixPanelTrack);

      setSelectedMailBox(undefined);
      onSetServerValues(true);
      onSetMailboxError(undefined);
      initData();

      const agent =
        client_data.agents.length > 0
          ? JSON.parse(client_data.agents[0])
          : undefined;
      if (agent) {
        await startAgent(agent.id);
      }
    } else {
      console.log("no company id");
    }
  }

  const deleteMailBox = async () => {
    onLoadingChanged(true);

    let deleteResponse;
    try {
      deleteResponse = await removeMailBox(box);
      const agent =
        client_data.agents.length > 0
          ? JSON.parse(client_data.agents[0])
          : undefined;
      if (client_data.mailBox.length <= 1 && agent) {
        await stopAgent(agent.id);
      }
    } catch (error) {
      deleteResponse = undefined;
    }

    if (!deleteResponse) {
      onLoadingChanged(false);

      return;
    }

    // Créer le log
    var user_id = JSON.parse(localStorage.getItem("authUser")).userId;
    let logData = {
      eventCreatedAt: deleteResponse.$createdAt,
      author_id: user_id,
      company_id: client_data.$id
    };
    let logdata = {
      event: "email_box_deleted",
      author_id: user_id,
      company_id: client_data.$id,
      log_data: JSON.stringify(logData)
    };
    createLog(logdata, mixPanelTrack);

    setSelectedMailBox(undefined);
    onSetServerValues(true);
    toggleBox(undefined);
    dispatch(fetchClientInfos());
    dispatch(emptyMailBox());
    const getDataResponse = client_data;
    if (box.email === localStorage.getItem("selected_mail_box")) {
      console.log(
        "XXX Box to delete is the selected one",
        box.email,
        localStorage.getItem("selected_mail_box")
      );

      if (getDataResponse) {
        if (getDataResponse.mailBox && getDataResponse.mailBox.length > 0) {
          localStorage.setItem(
            "selected_mail_box",
            getDataResponse.mailBox[0].email
          );
          console.log("XXX new box", localStorage.getItem("selected_mail_box"));
        } else {
          localStorage.removeItem("selected_mail_box");
          console.log(
            "XXX new box 2",
            localStorage.getItem("selected_mail_box")
          );
        }
      } else {
        localStorage.removeItem("selected_mail_box");
        console.log("XXX new box 3", localStorage.getItem("selected_mail_box"));
      }
    } else {
      console.log(
        "XXX Box to delete is not the selected one",
        box.email,
        localStorage.getItem("selected_mail_box")
      );
    }

    onLoadingChanged(false);
    dispatch(fetchClientInfos());
    dispatch(emptyMailBox());
    const response = client_data;
    if (box.email === localStorage.getItem("selected_mail_box")) {
      if (response) {
        if (response.mailBox && response.mailBox.length > 0) {
          localStorage.setItem("selected_mail_box", response.mailBox[0].email);
        } else {
          localStorage.removeItem("selected_mail_box");
        }
      } else {
        localStorage.removeItem("selected_mail_box");
      }
    }
  };

  // const requiredValidations = () => {
  //   if (
  //     email &&
  //     email !== null &&
  //     email.length > 5 &&
  //     host &&
  //     host !== null &&
  //     host !== "" &&
  //     port &&
  //     port !== null &&
  //     port !== "" &&
  //     site_name &&
  //     site_name !== null &&
  //     site_name !== "" &&
  //     site_url &&
  //     site_url !== null &&
  //     site_url !== "" &&
  //     shopify_store_url &&
  //     shopify_store_url !== null &&
  //     shopify_store_url !== "" &&
  //     shopify_api_key &&
  //     shopify_api_key !== null &&
  //     shopify_api_key !== "" &&
  //     site_infos_more &&
  //     site_infos_more !== null &&
  //     site_infos_more !== ""
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // async function launchServer(lport, count) {
  //   const companyId = localStorage.getItem('companyId');

  //   axios
  //     .post(
  //       'https://mailer-ai.semecloud.tech/launch-agent',
  //       // "http://localhost:30000/launch-agent",
  //       {
  //         id: companyId,
  //         company_id: companyId,
  //       }
  //     )
  //     .then(async (response) => {
  //       console.log(`Server launched on port ${lport}. Launch response is`, response);

  //       // client_data.server_active = true;

  //       await updateCompanyInfos({ server_active: true });
  //       goToInboxPage();
  //     })
  //     .catch(async (error) => {
  //       console.log(`Failed starting Agent ${lport}: `, error);
  //     });
  // }

  function autoConfigServerInfos(server) {
    onSelectedServerChanged(server);
    if (server === "gmail") {
      validation.setFieldValue("host", "imap.gmail.com");
      validation.setFieldValue("port", "993");
      validation.setFieldValue("smtp_host", "");
      validation.setFieldValue("smtp_port", "");
    } else if (server === "outlook") {
      validation.setFieldValue("host", "outlook.office365.com");
      validation.setFieldValue("port", "993");
      validation.setFieldValue("smtp_host", "");
      validation.setFieldValue("smtp_port", "");
    } else if (server === "godaddy") {
      validation.setFieldValue("host", "imap.secureserver.net");
      validation.setFieldValue("port", "993");
      validation.setFieldValue("smtp_host", "smtpout.secureserver.net");
      validation.setFieldValue("smtp_port", "");
      if (validation.values.tls_mode) {
        validation.setFieldValue("smtp_port", "465");
      } else {
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_port", "80");
      }
    } else if (server === "hostinger") {
      validation.setFieldValue("host", "imap.hostinger.com");
      validation.setFieldValue("smtp_port", "");
      if (validation.values.tls_mode) {
        validation.setValues(
          { ...validation.values, port: "993", smtp_port: "465" },
          false
        );
      } else {
        validation.setValues(
          { ...validation.values, port: "", smtp_port: "25" },
          false
        );
      }
    } else if (server === "infomaniak") {
      validation.setValues(
        {
          ...validation.values,
          host: "mail.infomaniak.com",
          port: "993",
          smtp_host: "mail.infomaniak.com",
          smtp_port: "465"
        },
        false
      );
      validation.setFieldValue("host", "mail.infomaniak.com");
      validation.setFieldValue("port", "993");
      validation.setFieldValue("smtp_host", "mail.infomaniak.com");
      validation.setFieldValue("smtp_port", "465");
      if (validation.values.tls_mode) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "465");
      } else {
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_port", "25");
      }
    } else if (server === "ovh") {
      validation.setFieldValue("host", "ssl0.ovh.net");
      validation.setFieldValue("smtp_host", "ssl0.ovh.net");
      if (validation.values.tls_mode) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "465");
      } else {
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_port", "");
      }
    } else if (server === "bluehost") {
      validation.setFieldValue("host", "imap.oxcs.bluehost.com");
      validation.setFieldValue("smtp_host", "imap.oxcs.bluehost.com");
      if (validation.values.tls_mode) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "587");
      } else {
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_host", "");
      }
    } else if (server === "hostpapa") {
      validation.setFieldValue("host", "mail.papamail.net");
      validation.setFieldValue("smtp_host", "mail.papamail.net");
      if (validation.values.tls_mode) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "465");
      } else {
        validation.setValues(
          { ...validation.values, port: "143", smtp_port: "587" },
          false
        );
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_port", "587");
      }
    } else {
      // validation.setFieldValue("host", "");
      // validation.setFieldValue("port", "");
      // validation.setFieldValue("smtp_host", "");
      // validation.setFieldValue("smtp_port", "");
    }
  }

  const onTlsModeChanged = (val) => {
    validation.setFieldValue("tls_mode", val);

    if (selectedServer === "gmail") {
    } else if (selectedServer === "outlook") {
    } else if (selectedServer === "godaddy") {
      if (val) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "465");
      } else {
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_port", "80");
      }
    } else if (selectedServer === "hostinger") {
      if (val) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "465");
      } else {
        validation.setFieldValue("port", "");
        validation.setFieldValue("smtp_port", "25");
      }
    } else if (selectedServer === "infomaniak") {
      if (val) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "465");
      } else {
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_port", "25");
      }
    } else if (selectedServer === "hostpapa") {
      if (val) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "465");
      } else {
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_port", "587");
      }
    } else if (selectedServer === "bluehost") {
      if (val) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "587");
      } else {
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_port", "");
      }
    } else if (selectedServer === "ovh") {
      if (val) {
        validation.setFieldValue("port", "993");
        validation.setFieldValue("smtp_port", "465");
      } else {
        validation.setFieldValue("port", "143");
        validation.setFieldValue("smtp_port", "");
      }
    } else {
    }
  };

  const toggleToast = () => {
    console.log("setToast before: " + toast);
    setToast(true);
    console.log("setToast after: " + toast);

    setTimeout(() => {
      setToast(false);
    }, 3000);
  };

  // function goToInboxPage() {
  //   navigate("/mailbox", {
  //     replace: false,
  //   });
  // }
  useEffect(() => {
    initData();
    // loadGmailEmailsIfConnected();
    // return () => clearTimeout(timer);
  }, []);

  const svgs = {
    check_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="15"
        viewBox="0 0 21 15"
        fill="none"
      >
        <path
          d="M20.9949 2.0168C20.8615 2.5003 20.5279 2.84638 20.1842 3.18738C16.4077 6.92815 12.6313 10.674 8.86001 14.4199C8.44439 14.8321 7.96721 15.051 7.37201 14.9899C6.96153 14.9492 6.61775 14.7609 6.33041 14.4759C4.39089 12.5571 2.45136 10.6435 0.52723 8.71456C-0.334782 7.84934 -0.103886 6.5159 0.958235 5.99677C1.62014 5.67105 2.36927 5.81355 2.94394 6.38357C4.16512 7.59487 5.38631 8.80617 6.60749 10.0175C6.88456 10.2923 7.16164 10.562 7.43358 10.8369C7.53107 10.9387 7.60291 10.9387 7.7004 10.8369C7.9826 10.5468 8.2802 10.2618 8.56754 9.97166C11.7077 6.85689 14.853 3.73704 17.9932 0.622277C18.4088 0.210029 18.8758 -0.0444449 19.4863 0.00644996C20.1483 0.0624343 20.7383 0.520488 20.9333 1.15158C20.9538 1.20757 20.9795 1.26355 21 1.31954C20.9949 1.55365 20.9949 1.78268 20.9949 2.0168Z"
          fill="white"
        />
      </svg>
    ),
    eye_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
      </svg>
    ),
    eye_slash_icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className=""
        style={{ width: "24px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
        />
      </svg>
    )
  };

  /// for update or add mailbox
  async function setCurrentMailBox(data) {
    setSelectedMailBox(data);
    if (data !== undefined && data !== null) {
      // const decryptPass = await decryptPassword({ password: data.password });
      validation.setValues({
        email: data.email ?? "",
        password: decryptPasswordLocal(data.password ?? ""),
        // password: data.password ?? "",
        port: data.imap_port ?? "",
        host: data.imap_host ?? "",
        smtp_host: data.smtp_host ?? "",
        smtp_port: data.smtp_port ?? "",
        tls_mode: data.tls
      });
    }
    if (data === null) {
      validation.setFieldValue("email", "");
      validation.setFieldValue("password", "");
      validation.setFieldValue("host", "");
      validation.setFieldValue("port", "");
      validation.setFieldValue("smtp_host", "");
      validation.setFieldValue("smtp_port", "");
    }
  }

  function toggleBox(data) {
    setDeleteBox(!deleteBox);
    setBox(data);
  }

  return (
    <React.Fragment>
      {client_data ? (
        <div className="">
          <Container
            fluid={true}
            style={{ maxWidth: "100%", paddingLeft: "8%", paddingRight: "8%" }}
            className="email"
          >
            <Col
              lg="12"
              className="d-flex"
              style={{
                flexDirection: "column",
                height: "60vh"
              }}
            >
              <div
                className="d-flex align-items-center flex-wrap"
                style={{ marginBottom: "18px" }}
              >
                <span className="font-primary">
                  <div
                    className="stepTitle font-primary"
                    style={{ fontSize: "18px" }}
                  >
                    {props.t("Email service client")}
                  </div>
                </span>
              </div>

              {client_data.agents && client_data.agents.length > 0 ? (
                <Card
                  style={{ backgroundColor: "transparent", boxShadow: "none" }}
                >
                  <CardBody style={{ padding: "0" }}>
                    <Row className="align-items-center justify-content-between flex-row">
                      <div className="col-auto">
                        {client_data === undefined ||
                        client_data.mailBox.length === 0 ? null : (
                          <Row>
                            {client_data.mailBox.map((mail, index) => (
                              <EmailBoxCard
                                t={props.t}
                                index={index}
                                mail={mail}
                                company_id={client_data.$id}
                                setSelectedMailBox={setCurrentMailBox}
                                loading={loading}
                                toggleBox={toggleBox}
                              />
                            ))}
                          </Row>
                        )}
                      </div>
                      <div
                        className="d-flex align-items-center font-primary"
                        style={{ width: "fit-content" }}
                      >
                        <div>
                          <button
                            type="button"
                            className="simple-text view-button font-primary"
                            style={{
                              backgroundColor: "#563bff",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              if (selectedMailBox !== null) {
                                setCurrentMailBox(null);
                              }
                            }}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            {props.t("Ajouter une boîte mail")}
                          </button>
                        </div>
                      </div>
                    </Row>

                    {/* <Row className="mt-3">
                  <Col xl={12}>
                    <Col>
                      {client_data === undefined || client_data.mailBox.length === 0 ? null : (
                        <Row>
                          {client_data.mailBox.map((mail, index) => (
                            <EmailBoxCard
                              t={props.t}
                              index={index}
                              mail={mail}
                              company_id={client_data.$id}
                              setSelectedMailBox={setCurrentMailBox}
                              loading={loading}
                              toggleBox={toggleBox}
                            />
                          ))}
                        </Row>
                      )}
                    </Col>
                  </Col>
                </Row> */}

                    {client_data === undefined ||
                    client_data.mailBox.length === 0 ||
                    selectedMailBox ||
                    selectedMailBox === null ? (
                      <Row>
                        <Col lg={12}>
                          <Card
                            className="mail-config_card"
                            style={{ borderRadius: "15px" }}
                          >
                            <CardBody
                              className="mail-config_body"
                              style={{
                                borderRadius: "15px",
                                background: "#FFF",
                                padding: "59px 39px 60px 39px"
                              }}
                            >
                              <h4
                                className="card-title font-primary"
                                style={{ fontSize: "24px" }}
                              >
                                {props.t("Configuration de votre email")}
                              </h4>
                              <p
                                className="card-subtitle mb-3"
                                style={{ fontSize: "14px" }}
                              >
                                {props.t(
                                  "Saisissez les informations nécessaires pour connecter votre adresse email dédiée au service client"
                                )}
                              </p>
                              {/* <Col lg={12}>
                          <Row className="" style={{ marginBottom: "40px", marginTop: "45px" }}>
                            <Col>
                              <div
                                style={{
                                  marginTop: "20px",
                                  padding: "20px 20px",
                                  borderRadius: "15px",
                                  background: "#F7F8FB",
                                }}
                                onClick={() => autoConfigServerInfos("gmail")}
                                className={`emailServer_div ${selectedServer === "gmail" && "emailServer_div-selected"}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === "gmail" ? "2px dashed #FFA412" : "2px dashed #D1D1D1",
                                    position: "relative",
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === "gmail" ? "flex" : "none",
                                      position: "absolute",
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "9px",
                                      background: "#05B714",
                                      transform: "translate(40%, -40%)",
                                      right: "0px",
                                      top: "0px",
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="">
                                    <div className="my-3">
                                      <img
                                        src={gmail_icon}
                                        className=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary" style={{ fontSize: "14px" }}>
                                      Gmail
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: "20px",
                                  padding: "20px 20px",
                                  borderRadius: "15px",
                                  background: selectedServer === "outlook" ? "#FFFAF1" : "#F7F8FB",
                                }}
                                onClick={() => autoConfigServerInfos("outlook")}
                                className={`emailServer_div ${selectedServer === "outlook" && "emailServer_div-selected"}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === "outlook" ? "2px dashed #FFA412" : "2px dashed #D1D1D1",
                                    position: "relative",
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === "outlook" ? "flex" : "none",
                                      position: "absolute",
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "9px",
                                      background: "#05B714",
                                      transform: "translate(40%, -40%)",
                                      right: "0px",
                                      top: "0px",
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={outlook_icon}
                                        className=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary" style={{ fontSize: "14px" }}>
                                      Outlook
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: "20px",
                                  padding: "20px 20px",
                                  borderRadius: "15px",
                                  background: selectedServer === "ovh" ? "#FFFAF1" : "#F7F8FB",
                                }}
                                onClick={() => autoConfigServerInfos("ovh")}
                                className={`emailServer_div ${selectedServer === "ovh" && "emailServer_div-selected"}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === "ovh" ? "2px dashed #FFA412" : "2px dashed #D1D1D1",
                                    position: "relative",
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === "ovh" ? "flex" : "none",
                                      position: "absolute",
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "9px",
                                      background: "#05B714",
                                      transform: "translate(40%, -40%)",
                                      right: "0px",
                                      top: "0px",
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={ovh_icon}
                                        className=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary" style={{ fontSize: "14px" }}>
                                      OVH
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: "20px",
                                  padding: "20px 20px",
                                  borderRadius: "15px",
                                  background: selectedServer === "hostinger" ? "#FFFAF1" : "#F7F8FB",
                                }}
                                onClick={() => autoConfigServerInfos("hostinger")}
                                className={`emailServer_div ${selectedServer === "hostinger" && "emailServer_div-selected"}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === "hostinger" ? "2px dashed #FFA412" : "2px dashed #D1D1D1",
                                    position: "relative",
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === "hostinger" ? "flex" : "none",
                                      position: "absolute",
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "9px",
                                      background: "#05B714",
                                      transform: "translate(40%, -40%)",
                                      right: "0px",
                                      top: "0px",
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={hostinger_icon}
                                        className=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary" style={{ fontSize: "14px" }}>
                                      Hostinger
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: "20px",
                                  padding: "20px 20px",
                                  borderRadius: "15px",
                                  background: selectedServer === "godaddy" ? "#FFFAF1" : "#F7F8FB",
                                }}
                                onClick={() => autoConfigServerInfos("godaddy")}
                                className={`emailServer_div ${selectedServer === "godaddy" && "emailServer_div-selected"}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === "godaddy" ? "2px dashed #FFA412" : "2px dashed #D1D1D1",
                                    position: "relative",
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === "godaddy" ? "flex" : "none",
                                      position: "absolute",
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "9px",
                                      background: "#05B714",
                                      transform: "translate(40%, -40%)",
                                      right: "0px",
                                      top: "0px",
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={godaddy_icon}
                                        className=""
                                        style={{
                                          width: "60px",
                                          height: "30px",
                                          objectFit: "cover",
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary" style={{ fontSize: "14px" }}>
                                      GoDaddy
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div
                                style={{
                                  marginTop: "20px",
                                  padding: "20px 20px",
                                  borderRadius: "15px",
                                  background: selectedServer === "infomaniak" ? "#FFFAF1" : "#F7F8FB",
                                }}
                                onClick={() => autoConfigServerInfos("infomaniak")}
                                className={`emailServer_div ${selectedServer === "infomaniak" && "emailServer_div-selected"}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === "infomaniak" ? "2px dashed #FFA412" : "2px dashed #D1D1D1",
                                    position: "relative",
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === "infomaniak" ? "flex" : "none",
                                      position: "absolute",
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "9px",
                                      background: "#05B714",
                                      transform: "translate(40%, -40%)",
                                      right: "0px",
                                      top: "0px",
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={informaniak_icon}
                                        className=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary" style={{ fontSize: "14px" }}>
                                      Infomaniak
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: "20px",
                                  padding: "20px 20px",
                                  borderRadius: "15px",
                                  background: selectedServer === "hostpapa" ? "#FFFAF1" : "#F7F8FB",
                                }}
                                onClick={() => autoConfigServerInfos("hostpapa")}
                                className={`emailServer_div ${selectedServer === "hostpapa" && "emailServer_div-selected"}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === "hostpapa" ? "2px dashed #FFA412" : "2px dashed #D1D1D1",
                                    position: "relative",
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === "hostpapa" ? "flex" : "none",
                                      position: "absolute",
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "9px",
                                      background: "#05B714",
                                      transform: "translate(40%, -40%)",
                                      right: "0px",
                                      top: "0px",
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={hostpapa_icon}
                                        className=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary" style={{ fontSize: "14px" }}>
                                      Hostpapa
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: "20px",
                                  padding: "20px 20px",
                                  borderRadius: "15px",
                                  background: selectedServer === "bluehost" ? "#FFFAF1" : "#F7F8FB",
                                }}
                                onClick={() => autoConfigServerInfos("bluehost")}
                                className={`emailServer_div ${selectedServer === "bluehost" && "emailServer_div-selected"}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === "bluehost" ? "2px dashed #FFA412" : "2px dashed #D1D1D1",
                                    position: "relative",
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === "bluehost" ? "flex" : "none",
                                      position: "absolute",
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "9px",
                                      background: "#05B714",
                                      transform: "translate(40%, -40%)",
                                      right: "0px",
                                      top: "0px",
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={bluehost_icon}
                                        className=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary" style={{ fontSize: "14px" }}>
                                      Bluehost
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: "20px",
                                  padding: "20px 20px",
                                  borderRadius: "15px",
                                  background: selectedServer === "custom" ? "#FFFAF1" : "#F7F8FB",
                                }}
                                onClick={() => autoConfigServerInfos("custom")}
                                className={`emailServer_div ${selectedServer === "custom" && "emailServer_div-selected"}`}>
                                <div
                                  className={`p-3 text-center rounded `}
                                  style={{
                                    border: selectedServer === "custom" ? "2px dashed #FFA412" : "2px dashed #D1D1D1",
                                    position: "relative",
                                  }}>
                                  <div
                                    className="align-items-center justify-content-center"
                                    style={{
                                      display: selectedServer === "custom" ? "flex" : "none",
                                      position: "absolute",
                                      width: "35px",
                                      height: "35px",
                                      borderRadius: "9px",
                                      background: "#05B714",
                                      transform: "translate(40%, -40%)",
                                      right: "0px",
                                      top: "0px",
                                    }}>
                                    {svgs.check_icon}
                                  </div>
                                  <Link to="#">
                                    <div className="my-3">
                                      <img
                                        src={mail_custom_icon}
                                        className=""
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        alt=""></img>
                                    </div>
                                    <h5 className="font-size-15 mb-3 font-primary" style={{ fontSize: "14px" }}>
                                      {props.t("Autres")}
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col> */}

                              {/* EMAIL PROVIDER DROPDOWN */}
                              {/* <div className="mb-4 d-flex gap-3 align-items-center">
                          <label className="form-label font-size-14">Votre Hébergeur mail :</label>
                          <Dropdown isOpen={serverDropdown} toggle={() => setServerDropdown(!serverDropdown)}>
                            <DropdownToggle tag="button" className="form-control d-flex align-items-center" style={{ textTransform: "capitalize" }}>
                              {selectedServer === "custom" ? props.t("Autres") : selectedServer}
                              <i className="ms-2 mdi mdi-chevron-down font-size-22" />
                            </DropdownToggle>
                            <DropdownMenu style={{ height: "230px", overflow: "auto" }}>
                              <DropdownItem onClick={() => autoConfigServerInfos("gmail")}>Gmail</DropdownItem>
                              <DropdownItem onClick={() => autoConfigServerInfos("outlook")}>Outlook</DropdownItem>
                              <DropdownItem onClick={() => autoConfigServerInfos("ovh")}>OVH</DropdownItem>
                              <DropdownItem onClick={() => autoConfigServerInfos("hostinger")}>Hostinger</DropdownItem>
                              <DropdownItem onClick={() => autoConfigServerInfos("godaddy")}>GoDaddy</DropdownItem>
                              <DropdownItem onClick={() => autoConfigServerInfos("infomaniak")}>Infomaniak</DropdownItem>
                              <DropdownItem onClick={() => autoConfigServerInfos("hostpapa")}>Hostpapa</DropdownItem>
                              <DropdownItem onClick={() => autoConfigServerInfos("bluehost")}>Bluehost</DropdownItem>
                              <DropdownItem onClick={() => autoConfigServerInfos("custom")}>{props.t("Autres")}</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div> */}

                              <div>
                                <div>
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup className="mb-3">
                                        <Label
                                          htmlFor="basicpill-email-input42"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {props.t("E-mail")}
                                        </Label>
                                        <Input
                                          type="email"
                                          className="form-control"
                                          id="basicpill-email-input42"
                                          name="email"
                                          placeholder={props.t(
                                            "Entrer l'adresse e-mail"
                                          )}
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values &&
                                            validation.values.email
                                          }
                                          invalid={validation.errors.email}
                                          disabled={
                                            selectedMailBox ? true : false
                                          }
                                        />
                                        {validation.errors.email ? (
                                          <FormFeedback type="invalid">
                                            <div>{validation.errors.email}</div>
                                          </FormFeedback>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                      <FormGroup className="mb-3">
                                        <Label
                                          htmlFor="basicpill-lastname-input22"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {props.t("Mot de passe")}
                                        </Label>
                                        <InputGroup>
                                          <Input
                                            className="form-control"
                                            id="basicpill-lastname-input22"
                                            name="password"
                                            placeholder={props.t(
                                              "Entrer le mot de passe de votre boîte e-mail"
                                            )}
                                            value={validation.values.password}
                                            type={
                                              isPasswordHidden
                                                ? "password"
                                                : "text"
                                            }
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            invalid={validation.errors.password}
                                          />
                                          <InputGroupText
                                            style={{
                                              backgroundColor: "transparent",
                                              border: "none",
                                              padding: "0"
                                            }}
                                          >
                                            <button
                                              className="btn h-100 form-control"
                                              type="button"
                                              onClick={togglePasswordVisibility}
                                              style={{
                                                borderTopLeftRadius: "0",
                                                borderBottomLeftRadius: "0"
                                              }}
                                            >
                                              {isPasswordHidden
                                                ? svgs.eye_icon
                                                : svgs.eye_slash_icon}
                                            </button>
                                          </InputGroupText>
                                          {validation.errors.password ? (
                                            <FormFeedback type="invalid">
                                              <div>
                                                {" "}
                                                {
                                                  validation.errors.password
                                                }{" "}
                                              </div>
                                            </FormFeedback>
                                          ) : null}
                                        </InputGroup>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  {serverValues === false || selectedMailBox ? (
                                    <Row
                                      style={{
                                        display:
                                          selectedServer === "custom"
                                            ? "flex"
                                            : "none"
                                      }}
                                    >
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label
                                            htmlFor="basicpill-lastname-input22"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {props.t(
                                              "Serveur de messagerie (IMAP)"
                                            )}
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-lastname-input22"
                                            name="host"
                                            placeholder={props.t(
                                              "Entrer le serveur de messagerie de votre boîte e-mail"
                                            )}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values &&
                                              validation.values.host
                                            }
                                            invalid={validation.errors.host}
                                          />
                                          {validation.errors.host ? (
                                            <FormFeedback type="invalid">
                                              <div>
                                                {validation.errors.host}
                                              </div>
                                            </FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label
                                            htmlFor="basicpill-phoneno-input32"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {props.t(
                                              "Port du serveur de messagerie (IMAP)"
                                            )}
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-phoneno-input32"
                                            name="port"
                                            placeholder={
                                              props.t(
                                                "Entrer le port du serveur de messagerie"
                                              ) + "..."
                                            }
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values &&
                                              validation.values.port
                                            }
                                            invalid={validation.errors.port}
                                          />
                                          {validation.errors.port ? (
                                            <FormFeedback type="invalid">
                                              <div>
                                                {validation.errors.port}
                                              </div>
                                            </FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  ) : null}
                                  {serverValues === false || selectedMailBox ? (
                                    <Row
                                      style={{
                                        display:
                                          selectedServer === "custom"
                                            ? "flex"
                                            : "none"
                                      }}
                                    >
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label
                                            htmlFor="basicpill-lastname-input22"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {props.t("Serveur d'envoie (SMTP)")}
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-lastname-input22"
                                            name="smtp_host"
                                            placeholder={props.t(
                                              "Entrer le serveur de messagerie de votre boîte e-mail"
                                            )}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values &&
                                              validation.values.smtp_host
                                            }
                                            invalid={
                                              validation.touched.smtp_host &&
                                              validation.errors.smtp_host
                                            }
                                          />
                                          {validation.errors.smtp_host ? (
                                            <FormFeedback type="invalid">
                                              <div>
                                                {validation.errors.smtp_host}
                                              </div>
                                            </FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                      <Col lg="6">
                                        <FormGroup className="mb-3">
                                          <Label
                                            htmlFor="basicpill-phoneno-input32"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {props.t(
                                              "Port du serveur d'envoie (SMTP)"
                                            )}
                                          </Label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            id="basicpill-phoneno-input32"
                                            name="smtp_port"
                                            placeholder={props.t(
                                              "Entrer le port du serveur d'envoi"
                                            )}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values &&
                                              validation.values.smtp_port
                                            }
                                            invalid={
                                              validation.touched.smtp_port &&
                                              validation.errors.smtp_port
                                            }
                                          />
                                          {validation.errors.smtp_port ? (
                                            <FormFeedback type="invalid">
                                              <div>
                                                {validation.errors.smtp_port}
                                              </div>
                                            </FormFeedback>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  ) : null}
                                  <Row>
                                    <Col lg="12">
                                      {serverValues === false ||
                                      selectedMailBox ? (
                                        <FormGroup className="mb-3">
                                          <div className="form-check d-flex align-items-center gap-2">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              id={"checkbox-tls"}
                                              name="tls_mode"
                                              onChange={(e) => {
                                                onTlsModeChanged(
                                                  e.target.checked
                                                );
                                              }}
                                              onBlur={validation.handleBlur}
                                              defaultChecked={
                                                validation.values.tls_mode
                                              }
                                              value={
                                                validation.values &&
                                                validation.values.tls_mode
                                              }
                                              invalid={
                                                validation.touched.tls_mode &&
                                                validation.errors.tls_mode
                                              }
                                            />
                                            <Label
                                              id="checkbox-tls"
                                              className="form-check-label"
                                              style={{ fontSize: "14px" }}
                                            >
                                              {props.t("Mode TLS ?")}
                                            </Label>
                                          </div>
                                        </FormGroup>
                                      ) : null}
                                      {mailboxError ? (
                                        <Alert
                                          color="danger"
                                          className="text-center"
                                        >
                                          {props.t(
                                            getMailboxErrorMessage(mailboxError)
                                          )}
                                        </Alert>
                                      ) : null}
                                    </Col>
                                  </Row>

                                  <div>
                                    <Row
                                      className="d-flex align-items-end"
                                      style={{
                                        textAlign: "end",
                                        marginTop: "8px"
                                      }}
                                    >
                                      <Col lg="8"></Col>
                                      <Col lg="2">
                                        {client_data !== undefined &&
                                          client_data.mailBox.length > 0 && (
                                            <div>
                                              <button
                                                className="view-button"
                                                style={{
                                                  color: "black",
                                                  marginTop: "10px",
                                                  marginLeft: "auto"
                                                }}
                                                onClick={() => {
                                                  setCurrentMailBox(undefined);
                                                }}
                                              >
                                                {props.t("Fermer")}
                                              </button>
                                            </div>
                                          )}
                                      </Col>
                                      <Col lg="2">
                                        <button
                                          id="add_mailbox_button"
                                          className="view-button"
                                          type="button"
                                          disabled={
                                            client_data &&
                                            client_data.agents &&
                                            client_data.agents.length > 0
                                              ? false
                                              : true
                                          }
                                          cursor={
                                            client_data &&
                                            client_data.agents &&
                                            client_data.agents.length > 0
                                              ? "pointer"
                                              : "not-allowed"
                                          }
                                          style={{
                                            marginTop: "10px",
                                            marginLeft: "auto",
                                            backgroundColor:
                                              client_data &&
                                              client_data.agents &&
                                              client_data.agents.length > 0
                                                ? "#563bff"
                                                : "grey"
                                          }}
                                          onClick={
                                            client_data &&
                                            client_data.agents &&
                                            client_data.agents.length > 0
                                              ? () => {
                                                  validation.handleSubmit();
                                                }
                                              : null
                                          }
                                        >
                                          {loading ? (
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <Spinner
                                                className="me-1"
                                                style={{
                                                  height: "15px",
                                                  width: "15px",
                                                  fontSize: "14px"
                                                }}
                                                animation="border"
                                              />
                                              {selectedMailBox === null ||
                                              selectedMailBox === undefined
                                                ? props.t("Ajout")
                                                : selectedMailBox &&
                                                  selectedMailBox !== null
                                                ? props.t("Modification")
                                                : props.t("Sauvegarde")}
                                              ...
                                            </div>
                                          ) : selectedMailBox === null ||
                                            selectedMailBox === undefined ? (
                                            props.t("Ajouter")
                                          ) : selectedMailBox &&
                                            selectedMailBox !== null ? (
                                            props.t("Modifier")
                                          ) : (
                                            props.t("Enregistrer")
                                          )}
                                        </button>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    ) : null}
                  </CardBody>
                </Card>
              ) : (
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    flex: "1"
                  }}
                >
                  <p className="font-primary">
                    {props.t(
                      "Vous devez configurer votre agent Synibox avant de pouvoir connecter votre boîte e-mail"
                    )}
                  </p>
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "fit-content", fontSize: "14px" }}
                  >
                    <button
                      className="view-button d-flex align-items-center ressouceAdd-button"
                      onClick={() => {
                        props.toggleVerticalIcon("5");
                        props.setMaaiaPageRender(true);
                      }}
                      style={{ border: "none", backgroundColor: "#563BFF" }}
                    >
                      {props.t("Configurer votre agent")}
                    </button>
                  </div>
                </div>
              )}

              <Modal
                isOpen={deleteBox}
                toggle={() => toggleBox(undefined)}
                centered
                className="user-modal"
              >
                <ModalBody className="p-3">
                  <h4 className="font-primary">
                    {props.t("Suppression de boîte email")}
                  </h4>
                  <p className="text-muted">
                    {props.t(
                      "Voulez-vous vraiment supprimer cette boîte email"
                    ) +
                      ": " +
                      box?.email +
                      " ?"}
                  </p>
                </ModalBody>
                <ModalFooter>
                  <div
                    className="hstack gap-2 justify-content-end"
                    style={{ width: "100%" }}
                  >
                    <button
                      type="button"
                      disabled={loading}
                      className="view-button"
                      style={{ color: "black", width: "100%" }}
                      onClick={() => toggleBox(undefined)}
                    >
                      {props.t("Non")}
                    </button>
                    <button
                      type="button"
                      style={{ backgroundColor: "#563bff", width: "100%" }}
                      className="view-button center font-primary"
                      id="edit-btn"
                      onClick={() => deleteMailBox()}
                    >
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm me-2 font-primary"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        props.t("Oui")
                      )}
                    </button>
                  </div>
                </ModalFooter>
              </Modal>

              {/* <Card>
              <CardBody>
                <h4 className="card-title mb-4" style={{ paddingLeft: "10px" }}>Niveau de contrôle</h4>
                <div>
                  <Form>
                    <Row>
                      <Col md={12}>
                        <div className="p-3">
                          <h5 className="font-size-14 mb-3 mt-0">
                            Ce paramètre définie le niveau de contrôle que vous laissez à notre système d'automation
                          </h5>

                          <Slider
                                    value={auto_response_level}
                                    min={1}
                                    max={10}
                                    labels={labels}
                                    orientation="horizontal"
                                    onChange={value => {
                                      setAutoResponseLevel(value);
                                    }}
                                  />
                          <div className="d-flex flex-wrap">
                            {StaticData.contact_reasons_array.map((reason, index) => (
                              <div key={index} className="form-check form-switch mb-3" style={{ flex: "0 0 50%", maxWidth: "50%" }}>
                                Contenu de la première div
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={`customSwitch${index}`}
                                  defaultChecked={contactReasonValues[reason.contact_reasons]}
                                  onClick={() => toggleContactReason(reason)}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`customSwitch${index}`}
                                >
                                  {reason.french_text}
                                  <div style={{ fontWeight: "lighter" }}>
                                    {reason.explanation}
                                  </div>
                                </label>
                              </div>
                            ))}
                          </div>

                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card> */}

              {/* <Card>
              <CardBody>
                <h4 className="card-title mb-4" style={{ paddingLeft: "10px" }}>Confirmation</h4>
                <div className="row justify-content-center">
                  <Col lg="12">
                    <div className="text-center">
                      <div className="mb-4">
                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                      </div>
                      <div>
                        <h5>Résumé</h5><br></br>
                        <p className="text-muted">
                          Email: {email} <br></br>
                          Mot de passe: **************<br></br>
                          Serveur de messagerie: {host}<br></br>
                          Port {port}<br></br>
                          Nom du site: {site_name}<br></br>
                          URL du site: {site_url}<br></br>
                          URL de la boutique shopify: {shopify_store_url}<br></br>
                          Clé API SHOPIFY: {shopify_api_key}<br></br><br></br>
                          <span className="text-decoration-underline">Signature:</span> <br></br>{signature}<br></br><br></br>
                          <span className="text-decoration-underline">Informations sur l'entreprise:</span> <br></br><br></br>{site_infos_more}<br></br>
                        </p>
                        <button
                          type="button"
                          className={`btn btn-primary waves-effect waves-light ${requiredValidations() === false ? 'disabled' : ""}`} onClick={() => {
                            validateConfig();
                          }}
                        >
                          {loading ? <div style={{ textAlign: "center" }}><Spinner animation="border" /> </div> : "CONFIRMER"}
                        </button>
                      </div>
                    </div>
                  </Col>
                </div>
              </CardBody>
            </Card> */}
            </Col>
            {/* Old */}

            <div
              className="position-fixed bottom-0 end-0 p-3"
              style={{ zIndex: "9999999" }}
            >
              <CustomToast
                isOpen={toast}
                toggle={toggleToast}
                message={toastMessage}
              />
            </div>
          </Container>
          {/* {client_data &&
          client_data.agents &&
          client_data.agents.length > 0 ? (
            <div></div>
          ) : (
            <UncontrolledTooltip
              innerClassName="font-primary"
              placement="right"
              target="add_mailbox_button"
            >
              {props.t(
                "Vous devez configurer votre agent Synibox avant de pouvoir connecter votre boîte e-mail"
              )}
            </UncontrolledTooltip>
          )} */}
        </div>
      ) : (
        <div style={{ textAlign: "center", fontSize: "14px" }}>
          <Spinner
            className="me-1"
            style={{ height: "15px", width: "15px" }}
            animation="border"
          />
          {props.t("Chargement") + "..."}
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(SettingEmailConfig);
