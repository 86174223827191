import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Toast,
  ToastHeader,
  ToastBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
  Spinner,
  CardTitle,
  InputGroup,
  Input,
  InputGroupText,
  Tooltip,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";
import { withTranslation } from "react-i18next";
import ManualInputRessources from "./ManualInputRessources";
import classnames from "classnames";
import CmsRessources from "./CmsRessources";
import FileRessources from "./FileRessources";
import SolarShop2Outline from "../../assets/images/icons/SolarShop2Outline";
import MajesticonsFolderPlusLine from "../../assets/images/icons/MajesticonsFolderPlusLine";
import IonEarthSharp from "../../assets/images/icons/IonEarthSharp";
import UrlRessources from "./UrlRessources";
import MaterialSymbolsLightKeyboardOutline from "../../assets/images/icons/MaterialSymbolsLightKeyboardOutline";

const RessourcesPage = (props) => {
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1");

  const toggleVerticalIcon = (tab) => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab);
    }
  };
  return (
    <React.Fragment>
      <div className="help-page">
        <Row>
          <Col
            xs="12"
            xxl={9}
            xl={11}
            className="mx-auto"
            style={{
              marginTop: "10px"
            }}
          >
            <div className="ps-0 pe-0 page-title-box d-sm-flex align-items-center justify-content-between">
              <div
                className="d-flex flex-column"
                style={{ width: "fit-content" }}
              >
                <h4
                  className="page-title font-primary"
                  style={{ fontSize: "14px" }}
                >
                  {props.t("Centre de ressources")}
                </h4>
                <p
                  className="mb-0 mt-2 simple-text font-primary"
                  style={{
                    color: "#666969",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px"
                  }}
                >
                  {props.t("Centre de ressources subtitle")}
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mx-auto col-xxl-9 col-xl-11">
          <Col className="mb-4">
            <Card
              className="mx-auto"
              style={{
                display: "flex",
                backgroundColor: "transparent",
                boxShadow: "none",
                alignContent: "center",
                marginBottom: "0px",
                justifyContent: "center",
                width: "fit-content"
              }}
            >
              <CardBody style={{ padding: "0px", fontWeight: "bold" }}>
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "none",
                        whiteSpace: "nowrap"
                      }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "1"
                      })}
                      onClick={() => {
                        toggleVerticalIcon("1");
                      }}
                    >
                      <span className="d-block d-sm-none"></span>
                      <span className="d-none d-sm-block">
                        <MaterialSymbolsLightKeyboardOutline
                          className="me-1"
                          width="1.3rem"
                          height="auto"
                        />
                        {props.t("Remplissage manuel")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "none",
                        whiteSpace: "nowrap"
                      }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "2"
                      })}
                      onClick={() => {
                        toggleVerticalIcon("2");
                      }}
                    >
                      <span className="d-block d-sm-none"></span>
                      <span className="d-none d-sm-block">
                        <SolarShop2Outline
                          className="me-1"
                          width="1rem"
                          height="auto"
                        />
                        {props.t("CMS")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "none",
                        whiteSpace: "nowrap"
                      }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "3"
                      })}
                      onClick={() => {
                        toggleVerticalIcon("3");
                      }}
                    >
                      <span className="d-block d-sm-none"></span>
                      <span className="d-none d-sm-block">
                        <MajesticonsFolderPlusLine
                          className="me-1"
                          width="1rem"
                          height="auto"
                        />
                        {props.t("Fichiers")}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        border: "none",
                        whiteSpace: "nowrap"
                      }}
                      className={classnames({
                        active: verticalActiveTabWithIcon === "4"
                      })}
                      onClick={() => {
                        toggleVerticalIcon("4");
                      }}
                    >
                      <span className="d-block d-sm-none"></span>
                      <span className="d-none d-sm-block">
                        <IonEarthSharp
                          className="me-1"
                          width="1rem"
                          height="auto"
                        />
                        {props.t("Urls de site web")}
                      </span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>
          </Col>
          <TabContent
            activeTab={verticalActiveTabWithIcon}
            className="text-muted mt-4 mt-md-0"
          >
            <TabPane tabId="1">
              <ManualInputRessources />
            </TabPane>
            <TabPane tabId="2">
              <CmsRessources />
            </TabPane>
            <TabPane tabId="3">
              <FileRessources />
            </TabPane>
            <TabPane tabId="4">
              <UrlRessources />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(RessourcesPage);
